// import React, { useState, useEffect } from 'react';
// import Select from 'react-select';
// import './AppointmentForm.css';
// import Modal from 'react-modal';
// import { VINOOTNEW } from "../Helper/Helper";
// import axios from 'axios';

// Modal.setAppElement('#root'); // Set for accessibility

// const AppointmentForm = ({ isOpen, onClose, onSave, appointmentToEdit }) => {
//     const [appointmentId, setAppointmentId] = useState('');
//     const [appointmentDate, setAppointmentDate] = useState('');
//     const [doctorId, setDoctorId] = useState('');
//     const [patientId, setPatientId] = useState('');
//     const [patientName, setPatientName] = useState('');
//     const [mobileNumber, setMobileNumber] = useState('');
//     const [timeSlot, setTimeSlot] = useState('');
//     const [treatmentTypes, setTreatmentTypes] = useState([]);
//     const [doctors, setDoctors] = useState([]);
//     const [timeSlots, setTimeSlots] = useState([]);
//     const [bookedSlots, setBookedSlots] = useState([]);
//     const [patientSuggestions, setPatientSuggestions] = useState([]);
//     const [treatmentOptions, setTreatmentOptions] = useState([]); // active treatments to show in dropdown

//     useEffect(() => {
//         if (isOpen) {
//             if (appointmentToEdit) {
//                 // Pre-fill form with appointment details when editing
//                 setAppointmentId(appointmentToEdit.appointmentId);
//                 setAppointmentDate(new Date(appointmentToEdit.appointmentDate).toISOString().split('T')[0]);
//                 setDoctorId(appointmentToEdit.doctorId);
//                 setPatientId(appointmentToEdit.patientId);
//                 setPatientName(appointmentToEdit.patientName);
//                 setMobileNumber(appointmentToEdit.mobileNumber);
//                 setTimeSlot(appointmentToEdit.timeSlot);
//                 setTreatmentTypes(appointmentToEdit.treatmentTypes.map(treatmentId => (
//                     treatmentOptions.find(option => option.plan_id === treatmentId)
//                 )));
//             } else {
//                 resetForm();
//             }
//             fetchDoctors();
//         } else {
//             resetForm();
//         }
//     }, [isOpen, appointmentToEdit,treatmentOptions]);

//     useEffect(() => {
//         if (doctorId && appointmentDate) {
//             fetchBookedSlots(doctorId, appointmentDate);
//         }
//     }, [doctorId, appointmentDate]);

//     useEffect(() => {
//         generateTimeSlots();
//         fetchPlans();
//     }, []);

    
        

        

//         const generateAppointmentId = () => {
//             const branchCode = localStorage.getItem('franchiseID');
//             const prefix = `APPT-${branchCode}-`;
//             const date = new Date().toISOString().split('T')[0].replace(/-/g, ''); // Format: YYYYMMDD
//             const sequenceKey = `appointmentSequence_${branchCode}_${date}`;
        
//             // Retrieve the current sequence number for the branch and date
//             const sequence = parseInt(localStorage.getItem(sequenceKey) || '1', 10);
        
//             // Generate the unique appointment ID
//             const uniqueId = `${prefix}${date}-${sequence.toString().padStart(4, '0')}`; // Format: APPT-BRANCH-YYYYMMDD-0001
        
//             // Update the sequence number in localStorage
//             localStorage.setItem(sequenceKey, sequence + 1);
        
//             return uniqueId;
//         };
    

//     const fetchDoctors = async () => {
//         try {
//             const response = await axios.get(`${VINOOTNEW}/api/doctors`);
//             setDoctors(response.data);
//         } catch (error) {
//             console.error('Error fetching doctors:', error);
//         }
//     };

//     const fetchBookedSlots = async (doctorId, date) => {
//         const formattedDate = new Date(date).toISOString().split('T')[0];
//         try {
//             const response = await axios.get(`${VINOOTNEW}/api/bookedslotappointments`, {
//                 params: { doctorId, appointmentDate: formattedDate }
//             });
//             setBookedSlots(response.data.bookedTimeSlots);
//         } catch (error) {
//             console.error('Error fetching booked slots:', error);
//         }
//     };

//     const generateTimeSlots = () => {
//         const slots = [];
//         for (let hour = 9; hour <= 21; hour++) {
//             slots.push(`${hour}:00`);
//             slots.push(`${hour}:30`);
//         }
//         setTimeSlots(slots);
//     };

//     const fetchPlans = async () => {
//         try {
//             const response = await axios.get(`${VINOOTNEW}/api/treatment-plan`);
//             setTreatmentOptions(response.data);
//         } catch (error) {
//             console.error("Error fetching plans:", error);
//         }
//     };

//     const handlePatientSearch = async (query) => {
//         try {
//             if (query.length < 3) {
//                 setPatientSuggestions([]);
//                 return;
//             }
//             const response = await axios.get(`${VINOOTNEW}/api/searchPatients`, {
//                 params: { q: query }
//             });
//             setPatientSuggestions(response.data);
//         } catch (error) {
//             console.error('Error searching patients:', error);
//         }
//     };

//     const handlePatientSelect = (patient) => {
//         setPatientId(patient.patient_id);
//         setPatientName(patient.patient_name);
//         setMobileNumber(patient.mobile_number);
//         setPatientSuggestions([]);
//     };

//     const handleSave = () => {
//         if (!appointmentDate || !doctorId || !timeSlot) {
//             alert('Please select a date, doctor, and time slot.');
//             return;
//         }

//         const newAppointmentId = appointmentToEdit ? appointmentId : generateAppointmentId();
//         const appointmentData = {
//             appointmentId: newAppointmentId,
//             appointmentDate,
//             doctorId,
//             patientId,
//             patientName,
//             mobileNumber,
//             timeSlot,
//             treatmentTypes: treatmentTypes.map(treatment => treatment.plan_id),
//             franchiseName: localStorage.getItem('franchiseName'),
//             franchiseID: localStorage.getItem('franchiseID'),
//             createdBy: appointmentToEdit ? appointmentToEdit.createdBy : localStorage.getItem('userId'),
//             modifiedBy: localStorage.getItem('userId'),
//             modifiedAt: new Date().toISOString()
//         };
//         onSave(appointmentData);
//         handleCancel();
//     };

//     const handleCancel = () => {
//         resetForm();
//         onClose();
//     };

//     const resetForm = () => {
//         setAppointmentId('');
//         setAppointmentDate('');
//         setDoctorId('');
//         setPatientId('');
//         setPatientName('');
//         setMobileNumber('');
//         setTimeSlot('');
//         setTreatmentTypes([]);
//     };

//     const handleDoctorChange = (e) => {
//         const newDoctorId = e.target.value;
//         setDoctorId(newDoctorId);
//         setTimeSlot(''); // Clear the selected time slot
//         if (newDoctorId && appointmentDate) {
//             fetchBookedSlots(newDoctorId, appointmentDate); // Fetch booked slots for the selected doctor and date
//         }
//     };

   


    
    

//     const handleDateChange = (e) => {
//         const newDate = e.target.value;
//         setAppointmentDate(newDate);
//         setTimeSlot(''); // Clear the selected time slot
//         if (doctorId && newDate) {
//             fetchBookedSlots(doctorId, newDate); // Fetch booked slots for the new date
//         }
//     };

//     const handleTreatmentChange = (options) => {
//         setTreatmentTypes(options);
//     };

   

    
//     return (
//         <Modal isOpen={isOpen} onRequestClose={handleCancel} className="apop">
//             <h2>{appointmentToEdit ? 'Edit Appointment' : 'Add Appointment'}</h2>
//             <form className='ap-form'>
//             <input
//                     type="text"
//                     placeholder="Search Patient"
//                     onChange={(e) => handlePatientSearch(e.target.value)}
//                 />
//                 {patientSuggestions.length > 0 && (
//                     <ul className="suggestions">
//                         {patientSuggestions.map(patient => (
//                             <li key={patient.patient_id} onClick={() => handlePatientSelect(patient)}>
//                                 {patient.patient_name} - {patient.mobileNumber}
//                             </li>
//                         ))}
//                     </ul>
//                 )}
//                 <input type="text" value={patientName} readOnly />
//                 <input type="text" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
//                 {/* <input type="text" value={appointmentId} readOnly /> */}
//                 <input type="date" value={appointmentDate} onChange={handleDateChange} />
//                 <select value={doctorId} onChange={handleDoctorChange}>
//                     <option value="">Select Doctor</option>
//                     {doctors.map(doctor => (
//                         <option key={doctor.userId} value={doctor.userId}>{doctor.fullname}</option>
//                     ))}
//                 </select>


//                 <select value={timeSlot} onChange={(e) => setTimeSlot(e.target.value)} disabled={!doctorId || !appointmentDate}>
//                     <option value="">Select Time Slot</option>
//                     {timeSlots.map(slot => (
//                         <option key={slot} value={slot} disabled={bookedSlots.includes(slot)}>
//                             {slot} {bookedSlots.includes(slot) && '(Booked)'}
//                         </option>
//                     ))}
//                 </select>






                
//                 <div>
//                     <h3>Select Treatments</h3>
//                     <Select 
//                         options={treatmentOptions} 
//                         value={treatmentTypes}
//                         getOptionLabel={(option) => option.plan_name} 
//                         getOptionValue={(option) => option.plan_id} 
//                         onChange={handleTreatmentChange}
//                         isMulti 
//                         placeholder="Select treatments..." 
//                         closeMenuOnSelect={false}
//                     />
//                 </div>
//                 <div className="form-actions">
//                     <button type="button" onClick={handleSave}>
//                         {appointmentToEdit ? 'Update Appointment' : 'Add Appointment'}
//                     </button>
//                     <button type="button" onClick={handleCancel}>
//                         Cancel
//                     </button>
//                 </div>
//             </form>
//         </Modal>


//     );
// };

// export default AppointmentForm;





// import React, { useState, useEffect } from 'react';
// import Select from 'react-select';
// import Modal from 'react-modal';
// import { VINOOTNEW } from "../Helper/Helper";
// import axios from 'axios';
// import './AppointmentForm.css';
// import { Link} from "react-router-dom";

// Modal.setAppElement('#root'); // Set for accessibility

// const AppointmentForm = ({ isOpen, onClose, onSave, appointmentToEdit }) => {
//     const [appointmentId, setAppointmentId] = useState('');
//     const [appointmentDate, setAppointmentDate] = useState('');
//     const [doctorId, setDoctorId] = useState('');
//     const [patientId, setPatientId] = useState('');
//     const [patientName, setPatientName] = useState('');
//     const [mobileNumber, setMobileNumber] = useState('');
//     const [timeSlot, setTimeSlot] = useState('');
//     const [treatmentTypes, setTreatmentTypes] = useState([]);
//     const [doctors, setDoctors] = useState([]);
//     const [timeSlots, setTimeSlots] = useState([]);
//     const [bookedSlots, setBookedSlots] = useState([]);
//     const [patientSuggestions, setPatientSuggestions] = useState([]);
//     const [treatmentOptions, setTreatmentOptions] = useState([]);
//     const [doctorOptions, setDoctorOptions] = useState([]);
//     const [timeSlotOptions, setTimeSlotOptions] = useState([]);

//     useEffect(() => {
//         if (isOpen) {
//             if (appointmentToEdit) {
//                 // Pre-fill form with appointment details when editing
//                 setAppointmentId(appointmentToEdit.appointmentId);
//                 setAppointmentDate(new Date(appointmentToEdit.appointmentDate).toISOString().split('T')[0]);
//                 setDoctorId(appointmentToEdit.doctorId);
//                 setPatientId(appointmentToEdit.patientId);
//                 setPatientName(appointmentToEdit.patientName);
//                 setMobileNumber(appointmentToEdit.mobileNumber);
//                 setTimeSlot(appointmentToEdit.timeSlot);
//                 setTreatmentTypes(appointmentToEdit.treatmentTypes.map(treatmentId => (
//                     treatmentOptions.find(option => option.plan_id === treatmentId)
//                 )));
//             } else {
//                 resetForm();
//             }
//             fetchDoctors();
//         } else {
//             resetForm();
//         }
//     }, [isOpen, appointmentToEdit, treatmentOptions]);

//     useEffect(() => {
//         if (doctorId && appointmentDate) {
//             fetchBookedSlots(doctorId, appointmentDate);
//         }
//     }, [doctorId, appointmentDate]);

//     useEffect(() => {
//         generateTimeSlots();
//         fetchPlans();
//     }, []);

//     useEffect(() => {
//         setDoctorOptions(doctors.map(doctor => ({
//             value: doctor.userId,
//             label: doctor.fullname
//         })));
//     }, [doctors]);

//     useEffect(() => {
//         setTimeSlotOptions(timeSlots.map(slot => ({
//             value: slot,
//             label: slot,
//             isDisabled: bookedSlots.includes(slot)
//         })));
//     }, [timeSlots, bookedSlots]);

//     const generateAppointmentId = () => {
//         const branchCode = localStorage.getItem('franchiseID');
//         const prefix = `APPT-${branchCode}-`;
//         const date = new Date().toISOString().split('T')[0].replace(/-/g, ''); // Format: YYYYMMDD
//         const sequenceKey = `appointmentSequence_${branchCode}_${date}`;
    
//         // Retrieve the current sequence number for the branch and date
//         const sequence = parseInt(localStorage.getItem(sequenceKey) || '1', 10);
    
//         // Generate the unique appointment ID
//         const uniqueId = `${prefix}${date}-${sequence.toString().padStart(4, '0')}`; // Format: APPT-BRANCH-YYYYMMDD-0001
    
//         // Update the sequence number in localStorage
//         localStorage.setItem(sequenceKey, sequence + 1);
    
//         return uniqueId;
//     };

//     const fetchDoctors = async () => {
//         try {
//             const response = await axios.get(`${VINOOTNEW}/api/doctors`);
//             setDoctors(response.data);
//         } catch (error) {
//             console.error('Error fetching doctors:', error);
//         }
//     };

//     const fetchBookedSlots = async (doctorId, date) => {
//         const formattedDate = new Date(date).toISOString().split('T')[0];
//         try {
//             const response = await axios.get(`${VINOOTNEW}/api/bookedslotappointments`, {
//                 params: { doctorId, appointmentDate: formattedDate }
//             });
//             setBookedSlots(response.data.bookedTimeSlots);
//         } catch (error) {
//             console.error('Error fetching booked slots:', error);
//         }
//     };

//     const generateTimeSlots = () => {
//         const slots = [];
//         for (let hour = 9; hour <= 21; hour++) {
//             slots.push(`${hour}:00`);
//             slots.push(`${hour}:30`);
//         }
//         setTimeSlots(slots);
//     };

//     const fetchPlans = async () => {
//         try {
//             const response = await axios.get(`${VINOOTNEW}/api/treatment-plan`);
//             setTreatmentOptions(response.data);
//         } catch (error) {
//             console.error("Error fetching plans:", error);
//         }
//     };

//     const handlePatientSearch = async (query) => {
//         try {
//             if (query.length < 3) {
//                 setPatientSuggestions([]);
//                 return;
//             }
//             const response = await axios.get(`${VINOOTNEW}/api/searchPatients`, {
//                 params: { q: query }
//             });
//             setPatientSuggestions(response.data);
//         } catch (error) {
//             console.error('Error searching patients:', error);
//         }
//     };

//     const handlePatientSelect = (patient) => {
//         setPatientId(patient.patient_id);
//         setPatientName(patient.patient_name);
//         setMobileNumber(patient.mobile_number);
//         setPatientSuggestions([]);
//     };

//     const handleSave = () => {
//         if (!appointmentDate || !doctorId || !timeSlot) {
//             alert('Please select a date, doctor, and time slot.');
//             return;
//         }

//         const newAppointmentId = appointmentToEdit ? appointmentId : generateAppointmentId();
//         const appointmentData = {
//             appointmentId: newAppointmentId,
//             appointmentDate,
//             doctorId,
//             patientId,
//             patientName,
//             mobileNumber,
//             timeSlot,
//             treatmentTypes: treatmentTypes.map(treatment => treatment.plan_id),
//             franchiseName: localStorage.getItem('franchiseName'),
//             franchiseID: localStorage.getItem('franchiseID'),
//             createdBy: appointmentToEdit ? appointmentToEdit.createdBy : localStorage.getItem('userId'),
//             modifiedBy: localStorage.getItem('userId'),
//             modifiedAt: new Date().toISOString()
//         };
//         onSave(appointmentData);
//         handleCancel();
//     };

//     const handleCancel = () => {
//         resetForm();
//         onClose();
//     };

//     const resetForm = () => {
//         setAppointmentId('');
//         setAppointmentDate('');
//         setDoctorId('');
//         setPatientId('');
//         setPatientName('');
//         setMobileNumber('');
//         setTimeSlot('');
//         setTreatmentTypes([]);
//     };

//     const handleDoctorChange = (selectedOption) => {
//         const newDoctorId = selectedOption ? selectedOption.value : '';
//         setDoctorId(newDoctorId);
//         setTimeSlot(''); // Clear the selected time slot
//         if (newDoctorId && appointmentDate) {
//             fetchBookedSlots(newDoctorId, appointmentDate); // Fetch booked slots for the selected doctor and date
//         }
//     };

//     const handleDateChange = (e) => {
//         const newDate = e.target.value;
//         setAppointmentDate(newDate);
//         setTimeSlot(''); // Clear the selected time slot
//         if (doctorId && newDate) {
//             fetchBookedSlots(doctorId, newDate); // Fetch booked slots for the new date
//         }
//     };

//     const handleTimeSlotChange = (selectedOption) => {
//         setTimeSlot(selectedOption ? selectedOption.value : '');
//     };

//     const handleTreatmentChange = (selectedOptions) => {
//         setTreatmentTypes(selectedOptions || []);
//     };

//     return (
//         <Modal isOpen={isOpen} onRequestClose={handleCancel} className="apop" overlayClassName="modal-overlay">
//             <h2>{appointmentToEdit ? 'Edit Appointment' : 'Add Appointment'}</h2>
//             <form className='ap-form'>
//                 <input
//                     type="text"
//                     placeholder="Search Patient"
//                     onChange={(e) => handlePatientSearch(e.target.value)}
//                 />
                
//                 {patientSuggestions.length > 0 && (
//                     <ul className="suggestions">
//                         {patientSuggestions.map(patient => (
//                             <li key={patient.patient_id} onClick={() => handlePatientSelect(patient)}>
//                                 {patient.patient_name} - {patient.mobile_number}
//                             </li>
//                         ))}
//                     </ul>
//                 )}
//                 <input type="text" value={patientName} readOnly />
//                 <input type="text" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
//                 {/* <input type="text" value={appointmentId} readOnly /> */}
//                 <input type="date" value={appointmentDate} onChange={handleDateChange} />
//                 <Select
//                     value={doctorOptions.find(option => option.value === doctorId)}
//                     onChange={handleDoctorChange}
//                     options={doctorOptions}
//                     placeholder="Select Doctor"
//                 />
//                 <Select
//                                         value={timeSlotOptions.find(option => option.value === timeSlot)}
//                                         onChange={handleTimeSlotChange}
//                                         options={timeSlotOptions}
//                                         placeholder="Select Time Slot"
//                                         isDisabled={!doctorId || !appointmentDate} // Disable if no doctor or date is selected
//                                     />
//                                     <div>
//                                         <h3>Select Treatments</h3>
//                                         <Select
//                                             options={treatmentOptions}
//                                             value={treatmentTypes}
//                                             getOptionLabel={(option) => option.plan_name}
//                                             getOptionValue={(option) => option.plan_id}
//                                             onChange={handleTreatmentChange}
//                                             isMulti
//                                             placeholder="Select treatments..."
//                                             closeMenuOnSelect={false}
//                                         />
//                                     </div>
//                                     <div className="form-actions">
//                                         <button type="button" onClick={handleSave}>
//                                             {appointmentToEdit ? 'Update Appointment' : 'Add Appointment'}
//                                         </button>
//                                         <button type="button" onClick={handleCancel}>
//                                             Cancel
//                                         </button>
//                                     </div>
//                                 </form>
//                             </Modal>






//                         );
//                     };
                    
//                     export default AppointmentForm;







import React, { useState, useEffect, useCallback} from 'react';
import Select from 'react-select';
import Modal from 'react-modal';
import { VINOOTNEW } from "../Helper/Helper";
import axios from 'axios';
import './AppointmentForm.css';
import { FaSearch } from 'react-icons/fa';
// import { Link} from "react-router-dom";

Modal.setAppElement('#root'); // Set for accessibility

const AppointmentForm = ({ isOpen, onClose, onSave, appointmentToEdit, initialData }) => {
    const [appointmentId, setAppointmentId] = useState('');
    const [appointmentDate, setAppointmentDate] = useState('');
    const [doctorId, setDoctorId] = useState('');
    const [doctorName, setDoctorName] = useState('');
    const [patientId, setPatientId] = useState('');
    const [patientName, setPatientName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [timeSlot, setTimeSlot] = useState('');
    const [treatmentTypes, setTreatmentTypes] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [timeSlots, setTimeSlots] = useState([]);
    const [bookedSlots, setBookedSlots] = useState([]);
    const [patientSuggestions, setPatientSuggestions] = useState([]);
    const [treatmentOptions, setTreatmentOptions] = useState([]);
    const [doctorOptions, setDoctorOptions] = useState([]);
    const [timeSlotOptions, setTimeSlotOptions] = useState([]);


    const [formError, setFormError] = useState('');

    

const resetForm = useCallback(() => {
    handlePatientSearch('');
    setAppointmentId('');
    setAppointmentDate('');
    setDoctorId('');
    setDoctorName('');
    setPatientId('');
    setPatientName('');
    setMobileNumber('');
    setTimeSlot('');
    setTreatmentTypes([]);
    setPatientSuggestions([]);
}, []);

    useEffect(() => {
        if (isOpen) {
            if (appointmentToEdit) {
                // Pre-fill form with appointment details when editing
                setAppointmentId(appointmentToEdit.appointmentId);
                setAppointmentDate(new Date(appointmentToEdit.appointmentDate).toISOString().split('T')[0]);
                setDoctorId(appointmentToEdit.doctorId);
                setDoctorName(appointmentToEdit.doctorName);
                setPatientId(appointmentToEdit.patientId);
                setPatientName(appointmentToEdit.patientName);
                setMobileNumber(appointmentToEdit.mobileNumber);
                setTimeSlot(appointmentToEdit.timeSlot);
                setTreatmentTypes(appointmentToEdit.treatmentTypes.map(treatmentId => (
                    treatmentOptions.find(option => option.plan_id === treatmentId)
                )));
            }  else if (initialData) {
                setPatientId(initialData.patient_id);
                setPatientName(initialData.patient_name);
                setMobileNumber(initialData.mobile_number);
                setDoctorId(initialData.docid);
                setDoctorName(initialData.docname);
                
            }else {
                resetForm();
            }
            fetchDoctors();
        } else {
            resetForm();
        }
    }, [isOpen, appointmentToEdit, treatmentOptions,resetForm,initialData]);

    useEffect(() => {
        if (doctorId && appointmentDate) {
            fetchBookedSlots(doctorId, appointmentDate);
        }
    }, [doctorId, appointmentDate]);

    useEffect(() => {
        generateTimeSlots();
        fetchPlans();
    }, []);

    useEffect(() => {
        setDoctorOptions(doctors.map(doctor => ({
            value: doctor.userId,
            label: doctor.fullname
        })));
    }, [doctors]);

    // useEffect(() => {
    //     setTimeSlotOptions(timeSlots.map(slot => ({
    //         value: slot,
    //         label: slot,
    //         isDisabled: bookedSlots.includes(slot)
    //     })));
    // }, [timeSlots, bookedSlots]);


    useEffect(() => {
        const currentTime = new Date();
    
        setTimeSlotOptions(timeSlots.map(slot => {
            // Create a Date object for the slot time on the appointment date
            const [hours, minutes] = slot.split(':').map(Number);
            const slotDateTime = new Date(appointmentDate);
            slotDateTime.setHours(hours, minutes, 0, 0);
    
            // Determine if the slot should be disabled
            let isDisabled = bookedSlots.includes(slot) && slotDateTime > currentTime;
    
            // If editing, keep the current appointment's slot enabled
            if (appointmentToEdit && slot === appointmentToEdit.timeSlot) {
                isDisabled = false;
            }
    
            return {
                value: slot,
                label: slot,
                isDisabled
            };
        }));
    }, [timeSlots, bookedSlots, appointmentDate, appointmentToEdit]);
    
    // const generateAppointmentId = () => {
    //     const branchCode = localStorage.getItem('franchiseID');
    //     const prefix = `APPT-${branchCode}-`;
    //     const date = new Date().toISOString().split('T')[0].replace(/-/g, ''); // Format: YYYYMMDD
    //     const sequenceKey = `appointmentSequence_${branchCode}_${date}`;
    
    //     // Retrieve the current sequence number for the branch and date
    //     const sequence = parseInt(localStorage.getItem(sequenceKey) || '1', 10);
    
    //     // Generate the unique appointment ID
    //     const uniqueId = `${prefix}${date}-${sequence.toString().padStart(4, '0')}`; // Format: APPT-BRANCH-YYYYMMDD-0001
    
    //     // Update the sequence number in localStorage
    //     localStorage.setItem(sequenceKey, sequence + 1);
    
    //     return uniqueId;
    // }; 
    const generateAppointmentId = () => {
        const branchCode = localStorage.getItem('franchiseID') || '000'; // Fallback if branch code is missing
        const prefix = `AP-${branchCode}-`;
      
        // Use current date and time for uniqueness
        const date = new Date().toISOString().split('T')[0].replace(/-/g, ''); // Format: YYYYMMDD
        const timestamp = new Date().getTime(); // Current time in milliseconds
      
        // Generate a short random suffix (2 digits)
        const randomSuffix = Math.floor(10 + Math.random() * 90); // Generates a random 2-digit number
      
        // Shorten the ID by including only the last 6 digits of the timestamp
        const shortTimestamp = (timestamp % 1000000).toString().padStart(6, '0'); // Last 6 digits of the timestamp
      
        // Generate the unique appointment ID
        const uniqueId = `${prefix}${date}-${shortTimestamp}-${randomSuffix}`; // Format: AP-BRANCH-YYYYMMDD-TIMESTAMP-RANDOM
      
        return uniqueId;
      };

    const fetchDoctors = async () => {
      const  frid = localStorage.getItem('franchiseID')
        try {
            // const response = await axios.get(`${VINOOTNEW}/api/doctors`);
            const response = await axios.get(
                `${VINOOTNEW}/api/franchisefetchusers/${frid}`
              );

              const doctorList = response.data.filter(
                (admin) => admin.designation === "Doctor"
              );
              setDoctors(doctorList);
            // setDoctors(response.data);
        } catch (error) {
            console.error('Error fetching doctors:', error);
        }
    };

    const fetchBookedSlots = async (doctorId, date) => {
        const formattedDate = new Date(date).toISOString().split('T')[0];
        try {
            const response = await axios.get(`${VINOOTNEW}/api/bookedslotappointments`, {
                params: { doctorId, appointmentDate: formattedDate }
            });
            setBookedSlots(response.data.bookedTimeSlots);
        } catch (error) {
            console.error('Error fetching booked slots:', error);
        }
    };

    const generateTimeSlots = () => {
        const slots = [];
        for (let hour = 9; hour <= 21; hour++) {
            slots.push(`${hour}:00`);
            slots.push(`${hour}:30`);
        }
        setTimeSlots(slots);
    };

    const fetchPlans = async () => {
        try {
            const response = await axios.get(`${VINOOTNEW}/api/treatment-plan`);
            
            const activePlans = response.data.filter(plan => plan.status === 'active');

            setTreatmentOptions(activePlans);
        } catch (error) {
            console.error("Error fetching plans:", error);
        }
    };

    // const handlePatientSearch = async (query) => {
    //     try {
    //         if (query.length < 3) {
    //             setPatientSuggestions([]);
    //             return;
    //         }
    //         const response = await axios.get(`${VINOOTNEW}/api/searchPatients`, {
    //             params: { q: query }
    //         });
    //         setPatientSuggestions(response.data);
    //     } catch (error) {
    //         console.error('Error searching patients:', error);
    //     }
    // };


    const handlePatientSearch = async (query) => {
        try {
          if (query.length < 3) {
            setPatientSuggestions([]);
            return;
          }
          const branchId = localStorage.getItem('franchiseID'); // retrieve branchId from local storage
          const response = await axios.get(`${VINOOTNEW}/api/searchPatients`, {
            params: { q: query, branchId } // pass branchId as a query parameter
          });
          setPatientSuggestions(response.data);
        } catch (error) {
          console.error('Error searching patients:', error);
        }
      };

    const handlePatientSelect = (patient) => {
        setPatientId(patient.patient_id);
        setPatientName(patient.patient_name);
        setMobileNumber(patient.mobile_number);
        setPatientSuggestions([]);
    };

    const validateForm = () => {
        if (!patientName || !mobileNumber || mobileNumber.length !== 10 || !/^\d+$/.test(mobileNumber) ||
            !appointmentDate || !doctorId || !timeSlot || treatmentTypes.length === 0) {
            setFormError('Please fill out all fields correctly.');
            return false;
        }
        setFormError('');
        return true;
    };
    

    const handleSave = () => {
        // if (!appointmentDate || !doctorId || !timeSlot) {
        //     alert('Please select a date, doctor, and time slot.');
        //     return;
        // }
       
       
        if (validateForm()) {
        const newAppointmentId = appointmentToEdit ? appointmentId : generateAppointmentId();
        const appointmentData = {
            appointmentId: newAppointmentId,
            appointmentDate,
            doctorId,
            doctorName,
            patientId,
            patientName,
            mobileNumber,
            timeSlot,
            treatmentTypes: treatmentTypes.map(treatment => treatment.plan_id),
            franchiseName: localStorage.getItem('franchiseName'),
            franchiseID: localStorage.getItem('franchiseID'),
            createdBy: appointmentToEdit ? appointmentToEdit.createdBy : localStorage.getItem('userId'),
            modifiedBy: localStorage.getItem('userId'),
            modifiedAt: new Date().toISOString()
        };
        onSave(appointmentData);
        handleCancel();
    }
    };

    const handleCancel = () => {
        resetForm();
        onClose();
    };

    // const resetForm = () => {
    //     handlePatientSearch('');
    //     setAppointmentId('');
    //     setAppointmentDate('');
    //     setDoctorId('');
    //     setDoctorName('');
    //     setPatientId('');
    //     setPatientName('');
    //     setMobileNumber('');
    //     setTimeSlot('');
    //     setTreatmentTypes([]);
    // };

    const handleDoctorChange = (selectedOption) => {
        setFormError(''); 
        const newDoctorId = selectedOption ? selectedOption.value : '';
        const newDoctorName = selectedOption ? selectedOption.label : '';
        setDoctorId(newDoctorId);
        setDoctorName(newDoctorName);
        setTimeSlot(''); // Clear the selected time slot
        if (newDoctorId && appointmentDate) {
            fetchBookedSlots(newDoctorId, appointmentDate); // Fetch booked slots for the selected doctor and date
        }
    };

    const handleDateChange = (e) => {
        setFormError(''); 
        const newDate = e.target.value;
        setAppointmentDate(newDate);
        setTimeSlot(''); // Clear the selected time slot
        if (doctorId && newDate) {
            fetchBookedSlots(doctorId, newDate); // Fetch booked slots for the new date
        }
    };

    const handleTimeSlotChange = (selectedOption) => {
        setFormError(''); 
        setTimeSlot(selectedOption ? selectedOption.value : '');
    };

    const handleTreatmentChange = (selectedOptions) => {
        setTreatmentTypes(selectedOptions || []);
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={handleCancel} className="apop" overlayClassName="modal-overlay">
            <h5>{appointmentToEdit ? 'Edit Appointment' : 'Add Appointment'}</h5>
            <form className='ap-form'>
            <div className="search-bar-container-app">
    <FaSearch className="search-icon" />
    <input 
        type="text"
        placeholder="Search Patient"
        onChange={(e) => handlePatientSearch(e.target.value)}
        className="search-input-app"
    />
</div>
{/* <Link to="/Franchisetogglepatients">register patient</Link> */}
                
                {patientSuggestions.length > 0 && (
                    <ul className="suggestions-list">
                        {patientSuggestions.map(patient => (
                            <li key={patient.patient_id} onClick={() => handlePatientSelect(patient)}>
                              {patient.patient_id} {'\u00A0'} {patient.patient_name} {'\u00A0'} {patient.mobile_number}
                            </li>
                        ))}
                    </ul>
                )}
                <p>Patient Name</p>
                <input type="text" value={patientName} onChange={()=>{setFormError('')}} />
                <p>Mobile Number</p>
                {/* <input type="text" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} /> */}
                <input
    type="text"
    value={mobileNumber}
    onChange={(e) => {
        const value = e.target.value;
   
        // Clear the error message as soon as the user starts typing

        // Validate that only digits are entered and length is up to 10 characters
        if (/^\d{0,10}$/.test(value)) {
            setMobileNumber(value);
        }
        setFormError(''); 
    }}
    placeholder="Enter Mobile Number"
/>
                {/* <input type="text" value={appointmentId} readOnly /> */}
                <p>select Doctor</p>
                <Select
                    value={doctorOptions.find(option => option.value === doctorId)}
                    onChange={handleDoctorChange}
                    options={doctorOptions}
                    placeholder="Select Doctor"
                />
                 <p>Date and Time</p>
                <div className='doc-time'>
                <input type="date" value={appointmentDate} min={new Date().toISOString().split('T')[0]} onChange={handleDateChange} />
               
                 <Select
                                        value={timeSlotOptions.find(option => option.value === timeSlot)}
                                        onChange={handleTimeSlotChange}
                                        options={timeSlotOptions}
                                        placeholder="Select Time Slot"
                                        isDisabled={!doctorId || !appointmentDate} // Disable if no doctor or date is selected
                                    />
                </div>
                
               
                                    <div>
                                        <h6>Select Treatments</h6>
                                        <Select
                                            options={treatmentOptions}
                                            value={treatmentTypes}
                                            getOptionLabel={(option) => option.plan_name}
                                            getOptionValue={(option) => option.plan_id}
                                            onChange={handleTreatmentChange}
                                            isMulti
                                            placeholder="Select treatments..."
                                            closeMenuOnSelect={false}
                                        />
                                    </div>
                                    {formError && <small className="form-error">{formError}</small>}
                                    
                                    <div className="form-actions">
                                        <button type="button" className='btn-save' onClick={handleSave}>
                                            {appointmentToEdit ? 'Update Appointment' : 'Add Appointment'}
                                        </button>
                                        <button type="button" className='btn-cancel' onClick={handleCancel}>
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </Modal>


                        );
                    };
                    
                    export default AppointmentForm;
