// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fra-pat-togg-total {
  display: flex;
}
.fra-pat-togg-right {
  margin-left: 19%;
  margin-top: 1.7vh;
  display: flex;
  flex-direction: column;
}
.fra-pat-togg-r1 {
  display: flex;
}
.pattoggle-button123 {
  margin-top: 4.7vh;
  height: 35px;
  width: 15vw;
  margin-left: 30vw;
  background-color: #0aa506;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.pattog-icon {
  margin-left: 10px;
  font-size: 20px;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/Franchisemanagement/Reception/Patientdetails/FranchiseToggledetails/Togglepatients.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,aAAa;AACf;AACA;EACE,iBAAiB;EACjB,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".fra-pat-togg-total {\n  display: flex;\n}\n.fra-pat-togg-right {\n  margin-left: 19%;\n  margin-top: 1.7vh;\n  display: flex;\n  flex-direction: column;\n}\n.fra-pat-togg-r1 {\n  display: flex;\n}\n.pattoggle-button123 {\n  margin-top: 4.7vh;\n  height: 35px;\n  width: 15vw;\n  margin-left: 30vw;\n  background-color: #0aa506;\n  border: none;\n  border-radius: 5px;\n  color: white;\n  font-weight: 600;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n}\n.pattog-icon {\n  margin-left: 10px;\n  font-size: 20px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
