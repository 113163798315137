import React, { useEffect } from "react";
import ReactModal from 'react-modal';
import "./FranchiseDetailsInfo.css"

const FranchiseDetailsInfo = ({franchise, isOpen, onClose}) => {
    useEffect(() => {
        if (!franchise) return null;
      },[franchise]);
  return (
    <ReactModal
    isOpen={isOpen}
    onRequestClose={onClose}
    className="info_franchise_popup"
    overlayClassName="info_franchise_popup_overlay"
  >
    <div className="info_franchise_container">
      <h2>
        Details for <span>{franchise.franchisename}</span>
      </h2>
      <ol>
        <li>
          <strong>branch ID :</strong> {franchise.franchiseID}
        </li>
        
        <li>
          <strong>Mobile No :</strong> {franchise.mobileNumber}
        </li>
        <li>
          <strong>State :</strong> {franchise.state}
        </li>
        <li>
          <strong>City :</strong> {franchise.city}
        </li>
        <li>
          <strong>Area :</strong> {franchise.area}
        </li>
        <li>
          <strong>Address :</strong> {franchise.address}
        </li>
        <li>
          <strong>Status :</strong>{" "}
          <span className={franchise.isActive ? "" : "inactive"}>
            {franchise.isActive ? "Active" : "Inactive"}
          </span>
        </li>
      </ol>
    </div>
  </ReactModal>
  )
}

export default FranchiseDetailsInfo