import { FaFileCsv } from "react-icons/fa6";
import React, { useState, useEffect } from "react";
import axios from "axios";
import SuperSidebar from "../../Masterdata/Sidebar/Sidebar";
import "./Franchisepatientbilling.css";
import { VINOOTNEW } from "../../Helper/Helper";
// import { useNavigate } from "react-router-dom";
import ReactModal from "react-modal";

const Franchisepatientbilling = () => {
  const [billingDetails, setBillingDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [/*loading*/, setLoading] = useState(false);
  const [/*error*/, setError] = useState(null);

  const [billingData, setBillingData] = useState([]);
  const [branchNames, setBranchNames] = useState([]);
  const [totalPatients, setTotalPatients] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [filters, setFilters] = useState({
    fromDate: "",
    toDate: "",
    franchisename: "",
    mobileNumber: "",
    planType: "",
    patientname: "",
    remainingAmount: "",
  });
  const [errors, setErrors] = useState({
    mobileNumber: "",
  });

  useEffect(() => {
    const fetchBillingData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${VINOOTNEW}/api/billing`);
        // console.log("Billing Data Response: ", response.data);
        setBillingData(response.data);

        // Create a map to store unique franchises by franchiseID
        const franchiseMap = new Map();

        response.data.forEach((billing) => {
          if (!franchiseMap.has(billing.franchiseID)) {
            franchiseMap.set(billing.franchiseID, {
              franchiseID: billing.franchiseID,
              franchisename: billing.franchiseName,
            });
          }
        });

        const uniqueBranches = Array.from(franchiseMap.values());
        setBranchNames(uniqueBranches);
      } catch (error) {
        console.error("Error fetching billing data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBillingData();
  }, []);

  const filteredData = billingData.filter((billing) => {
    const remainingAmount = parseFloat(billing?.remainingAmount ?? 0);
    const filterValue = parseFloat(filters.remainingAmount);
    const lowercaseName = filters.patientname.toLowerCase();
    const lowercaseBillingName = billing?.patient_name?.toLowerCase() ?? "";
    const lowercasefranchise = filters.franchisename.toLowerCase();
    const lowercaseFranchiseName = billing?.franchiseID?.toLowerCase() ?? "";

    const currentDate = new Date(billing?.currentDate);
    const fromDate = filters.fromDate ? new Date(filters.fromDate) : null;
    const toDate = filters.toDate ? new Date(filters.toDate) : null;

    // Only apply filtering if the filter value is not empty
    return (
      (!fromDate || currentDate >= fromDate) &&
      (!toDate || currentDate <= toDate) &&
      (!filters.mobileNumber ||
        (billing?.mobile_number?.toString() ?? "").includes(
          filters.mobileNumber
        )) &&
      // (!filters.planType || (billing?.plan_name ?? "").includes(filters.planType)) &&
      (!filters.planType ||
        billing.plans.some((plan) =>
          plan.plan_name.toLowerCase().includes(filters.planType.toLowerCase())
        )) &&
      (!filters.patientname || lowercaseBillingName.includes(lowercaseName)) &&
      (!filters.franchisename ||
        lowercaseFranchiseName.includes(lowercasefranchise)) &&
      (isNaN(filterValue) || remainingAmount >= filterValue)
    );
  });

  useEffect(() => {
    const calculateTotals = () => {
      const patientsCount = filteredData.length;
      const revenue = filteredData.reduce(
        (acc, billing) => acc + parseFloat(billing.totalAmount ?? 0),
        0
      );

      setTotalPatients(patientsCount);
      setTotalRevenue(revenue);
    };

    calculateTotals();
  }, [filteredData]);

  const [, setSelectedFranchiseID] = useState("");
  // const handleDetailsClick = (patientId, billNumber, franchiseID) => {
  //   // Update localStorage with the branch name from the clicked bill
  //   localStorage.setItem("franchiseID", franchiseID);

  //   // Navigate to DoctorShowPatient with the correct path
  //   navigate(`/showPatient/${franchiseID}/${patientId}/${billNumber}`);
  // };

  const handleDetailsClick = async (patientId, billNumber) => {
    try {
      setLoading(true);
      setError(null);

      // Fetch billing details using patientId and billNumber
      const response = await axios.get(
        `${VINOOTNEW}/api/billings/${patientId}/${billNumber}`
      );

      setBillingDetails(response.data); // Set fetched billing details
      setShowModal(true); // Show modal with billing details
    } catch (err) {
      setError("Error fetching billing details. Please try again.");
      console.error("Error fetching billing details:", err);
    } finally {
      setLoading(false);
    }
  };

  // const navigate = useNavigate();

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    // console.log("Filters Updated:", filters); // Add this for debugging

    if (name === "mobileNumber") {
      const mobileNumberRegex = /^[0-9]{0,10}$/;
      const specialCharRegex = /[^0-9]/;

      if (specialCharRegex.test(value)) {
        setErrors({
          ...errors,
          mobileNumber: "Special characters are not allowed.",
        });
      } else if (!mobileNumberRegex.test(value)) {
        setErrors({
          ...errors,
          mobileNumber: "Invalid mobile number. Only 10 digits are allowed.",
        });
      } else {
        setErrors({ ...errors, mobileNumber: "" });
      }
    }

    if (name === "patientname") {
      const nameRegex = /^[a-zA-Z\s]{1,50}$/;

      if (!nameRegex.test(value)) {
        setErrors({
          ...errors,
          patientname:
            "Invalid name. Only letters and spaces are allowed, up to 50 characters.",
        });
      } else {
        setErrors({ ...errors, patientname: "" });
      }
    }
    // console.log("Filters Updated:", filters); // Add this for debugging

    if (name === "franchisename") {
      setSelectedFranchiseID(value); // Save the selected franchiseID
      localStorage.setItem("franchiseID", value); // Optional: Save to localStorage

      // Check if a branch is selected. If empty, set franchiseID to null for displaying all branches.
      if (value === "") {
        // console.log("No branch selected, showing all branches.");
        setFilters((prevFilters) => ({
          ...prevFilters,
          franchisename: "", // Clear the franchise filter when no branch is selected
        }));
      } else {
        // console.log("Selected franchiseID:", value);  // Log the selected franchiseID
        setFilters((prevFilters) => ({
          ...prevFilters,
          franchisename: value, // Update the filter to the selected franchiseID
        }));
      }
    }

    if (name === "remainingAmount") {
      const amountRegex = /^\d*\.?\d{0,2}$/;

      if (!amountRegex.test(value)) {
        setErrors({
          ...errors,
          remainingAmount:
            "Invalid amount. Only positive numbers with up to two decimal places are allowed.",
        });
      } else {
        setErrors({ ...errors, remainingAmount: "" });
      }
    }
  };

  const exportToCSV = () => {
    // Fetch userId (replace with actual method to get userId)
    const userId = localStorage.getItem("userId"); // Replace with actual logic to fetch userId

    // Format current date and time
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours() % 12 || 12;
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const ampm = currentDate.getHours() >= 12 ? "PM" : "AM";
    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = `${hours}:${minutes} ${ampm}`;
    const dateTimeString = `${formattedDate}@${formattedTime}`;

    // Generate CSV content with headers
    const headers = [
      "Branch Name",
      "Branch ID",
      "Date",
      "Bill Number",
      "Patient ID",
      "Patient Name",
      "Mobile Number",
      "Doctor",
      "Therapist",
      "Plan Name",
      "Price",
      "Total Amount",
      "Remaining Amount",
    ];

    const csvData = [
      headers,
      ...filteredData.map((billing) => [
        billing.franchiseName,
        billing.franchiseID,
        billing.currentDate,
        billing.bill_number,
        billing.patient_id,
        billing.patient_name,
        billing.mobile_number,
        billing.doctor,
        billing.therapist,
        billing.plan_name,
        billing.price,
        billing.TotalAmount,
        billing.remainingAmount,
      ]),
    ];

    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvData.map((row) => row.join(",")).join("\n");

    // Create a link element to trigger download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");

    // Set the download attribute with userId and current date/time
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `${userId}_${dateTimeString}_Branchwise_Billing_data.csv`
    );

    document.body.appendChild(link);

    // Trigger download
    link.click();
  };

  return (
    <div className="franbill-total">
      <div>
        <SuperSidebar />
      </div>
      <div className="franbill-right">
        <div className="franbill-123">
          <h1>Patients Billing Data</h1>
          <button onClick={exportToCSV}>
            <FaFileCsv className="xlsiocn" />
          </button>
        </div>
        <div className="franbill-filterss">
          <div className="franbill_top_filterss">
            <div className="franbill-filterss-11">
              <label>
                <span>From Date:</span>
                <input
                  type="date"
                  name="fromDate"
                  value={filters.fromDate}
                  onChange={handleFilterChange}
                />
              </label>
              <label>
                <span>To Date:</span>
                <input
                  type="date"
                  name="toDate"
                  value={filters.toDate}
                  onChange={handleFilterChange}
                />
              </label>
            </div>
            <div className="franbill_totalpatients_totalrevenue_parent">
              <div className="franbill_totalpatients">
                <h6>Total Patient Bills</h6>
                <span> {totalPatients}</span>
              </div>
              <div className="franbill_totalrevenue">
                <h6>Total Revenue</h6>
                <span> ₹ {totalRevenue.toFixed(2)}</span>{" "}
              </div>
            </div>
          </div>
          <div className="franbill-filterss-1">
            <label>
              <span>Branch Name:</span>
              <select
                className="branch-branches"
                name="franchisename"
                value={filters.franchisename}
                onChange={handleFilterChange}
              >
                <option value="">Select Branch</option>
                {branchNames.map((branch, index) => (
                  <option key={index} value={branch.franchiseID}>
                    {branch.franchisename}
                  </option>
                ))}
              </select>
            </label>

            <label>
              <span>Mobile Number:</span>
              <input
                type="text"
                name="mobileNumber"
                value={filters.mobileNumber}
                onChange={handleFilterChange}
              />
              {errors.mobileNumber && (
                <span className="error" style={{ color: "red" }}>
                  {errors.mobileNumber}
                </span>
              )}
            </label>
            <label>
              <span>Name</span>
              <input
                type="text"
                name="patientname"
                value={filters.patientname}
                onChange={handleFilterChange}
              />
              {errors.patientname && (
                <span className="error" style={{ color: "red" }}>
                  {errors.patientname}
                </span>
              )}
            </label>
            <label>
              <span>Plan </span>
              <input
                type="text"
                name="planType"
                value={filters.planType}
                onChange={handleFilterChange}
              />
            </label>
            <label>
              <span>Remaining Amount above:</span>
              <input
                type="text"
                name="remainingAmount"
                value={filters.remainingAmount}
                onChange={handleFilterChange}
              />
              {errors.remainingAmount && (
                <span className="error" style={{ color: "red" }}>
                  {errors.remainingAmount}
                </span>
              )}
            </label>
          </div>
        </div>

        <div className="patienttable_pagination_parent">
          <div className="patientbilling_table">
            {isLoading ? (
              <p>Loading areas...</p>
            ) : filteredData.length > 0 ? ( // <-- Conditional rendering starts here..This 0 checks if there's any data to display
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Bill Number</th>
                    <th>Patient ID</th>
                    <th>Patient Name</th>
                    <th>Mobile No.</th>
                    <th>Doctor</th>
                    <th>Services</th>
                    <th>Medicines</th>
                    <th>Amount Paid</th>
                    <th>Remaining Amount</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((billing, index) => (
                    <tr key={index}>
                      <td>{billing.currentDate}</td>
                      <td>{billing.bill_number}</td>
                      <td>{billing.patient_id}</td>
                      <td>{billing.patient_name}</td>
                      <td>{billing.mobile_number}</td>
                      <td>{billing.doctor}</td>
                      <td>
                        {billing.plans &&
                          billing.plans.map((plan, index) => (
                            <div key={index}>
                              {plan.plan_name} - {plan.days} days,{" "}
                              {plan.TotalAmount}
                            </div>
                          ))}
                      </td>
                      <td>
                        {billing.medicines &&
                          billing.medicines.map((med, index) => (
                            <div key={index}>
                              {med.plan_name} -{" "}
                              {med.quantity ? `${med.quantity} units` : "nil"},{" "}
                              {med.totalPrice}
                            </div>
                          ))}
                      </td>
                      <td>{billing.amountPaid}</td>
                      <td>{billing.remainingAmount}</td>
                      <td>
                        <button
                          onClick={() =>
                            handleDetailsClick(
                              billing.patient_id,
                              billing.bill_number,
                              billing.franchiseID
                            )
                          }
                        >
                          Details
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No bills found.</p>
            )}
          </div>
        </div>
        <ReactModal
          className="info_billingdetail_popup"
          overlayClassName="info_billingdetail_popup_overlay"
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
        >
          {billingDetails ? (
            <div  className="info_billingdetail_container">
              <h2>Billing Details</h2>
              <p>
                <strong>Franchise Name :</strong> {billingDetails.franchiseName}
              </p>
              <p>
                <strong>Franchise ID :</strong> {billingDetails.franchiseID}
              </p>
              <p>
                <strong>Bill Number :</strong> {billingDetails.bill_number}
              </p>
              <p>
                <strong>Bill Date :</strong> {billingDetails.currentDate}
              </p>
              <p>
                <strong>Patient ID :</strong> {billingDetails.patient_id}
              </p>
              <p>
                <strong>Patient Name :</strong> {billingDetails.patient_name}
              </p>
              <p>
                <strong>Mobile Number :</strong> {billingDetails.mobile_number}
              </p>
              <p>
                <strong>Doctor :</strong> {billingDetails.doctor}
              </p>
              <p>
                <strong>Therapist :</strong> {billingDetails.therapist} NA
              </p>
             

              {billingDetails.plans &&
                billingDetails.plans.map((plan) => (
                  <div key={plan._id}>
                    <p>
                      <strong>Plan Name :</strong>
                      {plan.plan_name} - {plan.price ? `${plan.price}` : "nil"},{" "}
                      {plan.TotalAmount}{" "}
                    </p>
                  </div>
                ))}

              {billingDetails.medicines &&
                billingDetails.medicines.map((med) => (
                  <div key={med._id}>
                    <p>
                      <strong>Medicine Name :</strong>
                      {med.plan_name} -{" "}
                      {med.quantity ? `${med.quantity} units` : "nil"},{" "}
                      {med.totalPrice}{" "}
                    </p>
                  </div>
                ))}

           
              <p>
                <strong>Payment Method :</strong> {billingDetails.paymentType}
              </p>
              <p>
                <strong>Amount Paid :</strong> {billingDetails.amountPaid}
              </p>
              <p>
                <strong>Remaining Amount :</strong>{" "}
                {billingDetails.remainingAmount}
              </p>
              <p>
                <strong>Total Amount :</strong> {billingDetails.totalAmount}
              </p>
             
              <p>
                <strong>Status :</strong> {billingDetails.status}
              </p>

              <button
                onClick={() => {
                  setShowModal(false);
                  setBillingDetails(null);
                }}
              >
                Close
              </button>
            </div>
          ) : (
            <p>No billing details available.</p>
          )}
        </ReactModal>
      </div>
    </div>
  );
};

export default Franchisepatientbilling;
