// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fra-togg-total{
    display: flex;
}
.fra-togg-right{
    margin-left: 19%;
    margin-top: 1.7vh;
    display: flex;
    flex-direction: column;
}
.fra-togg-r1{
    display: flex;
    padding: 5px;
}
.toggle-button123{
    margin-top: 4.7vh;
    height: 35px;
    width: 15vw;
    margin-left: 30vw;
    background-color: #0aa506;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* padding: 19px; */
}
.tog-icon{
    margin-left: 10px;
    font-size: 20px;
    align-items: center;
    /* padding: 19px; */
}`, "",{"version":3,"sources":["webpack://./src/Franchisemanagement/Franchiseadmin/Franchise-s/Togglebutton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,YAAY;AAChB;AACA;IACI,iBAAiB;IACjB,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,eAAe;IACf,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".fra-togg-total{\n    display: flex;\n}\n.fra-togg-right{\n    margin-left: 19%;\n    margin-top: 1.7vh;\n    display: flex;\n    flex-direction: column;\n}\n.fra-togg-r1{\n    display: flex;\n    padding: 5px;\n}\n.toggle-button123{\n    margin-top: 4.7vh;\n    height: 35px;\n    width: 15vw;\n    margin-left: 30vw;\n    background-color: #0aa506;\n    border: none;\n    border-radius: 5px;\n    color: white;\n    font-weight: 600;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    /* padding: 19px; */\n}\n.tog-icon{\n    margin-left: 10px;\n    font-size: 20px;\n    align-items: center;\n    /* padding: 19px; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
