// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.franchise-login-details-icon-container-vik {
    position: fixed;
    top: 10px; /* Adjust top position as needed */
    right: 10px; /* Adjust right position as needed */
    z-index: 999; /* Ensure it's above other content */


.icon-container-vik {
    cursor: pointer;
}

.dropdown-vik {
    position: absolute;
    top: 30px; /* Adjust dropdown position as needed */
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure dropdown is above other content */
    width:max-content; 
}

}`, "",{"version":3,"sources":["webpack://./src/Franchisemanagement/Franchiseadmin/FranchiseLoginDetailsIcon.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS,EAAE,kCAAkC;IAC7C,WAAW,EAAE,oCAAoC;IACjD,YAAY,EAAE,oCAAoC;;;AAGtD;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,SAAS,EAAE,uCAAuC;IAClD,QAAQ;IACR,sBAAsB;IACtB,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,aAAa,EAAE,2CAA2C;IAC1D,iBAAiB;AACrB;;AAEA","sourcesContent":[".franchise-login-details-icon-container-vik {\n    position: fixed;\n    top: 10px; /* Adjust top position as needed */\n    right: 10px; /* Adjust right position as needed */\n    z-index: 999; /* Ensure it's above other content */\n\n\n.icon-container-vik {\n    cursor: pointer;\n}\n\n.dropdown-vik {\n    position: absolute;\n    top: 30px; /* Adjust dropdown position as needed */\n    right: 0;\n    background-color: #fff;\n    border: 1px solid #ccc;\n    padding: 10px;\n    border-radius: 5px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n    z-index: 1000; /* Ensure dropdown is above other content */\n    width:max-content; \n}\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
