import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Franchisestaffdetails.css";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { VINOOTNEW } from "../../../Helper/Helper";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditStaffModal from "./EditStaffModal"; // Ensure this path is correct

const Franchisestaffdetails = () => {
  const [admins, setAdmins] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(3);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const fetchAdmins = async () => {
    try {
      const frid = localStorage.getItem("franchiseID");
      if (frid) {
        const response = await axios.get(
          `${VINOOTNEW}/api/franchisefetchusers/${frid}`
        );
        setAdmins(response.data);
      } else {
        console.error("FranchiseID not found in localStorage");
      }
    } catch (error) {
      console.error("Error fetching admins:", error);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, []);

  const toggleActiveState = async (id, fullname, isActive) => {
    try {
      const updatedBy = localStorage.getItem("username");
      await axios.patch(`${VINOOTNEW}/api/franchisestateupdatepart2/${id}`, {
        isActive: !isActive,
        updatedBy,
      });
      toast[isActive ? "error" : "success"](
        `${fullname} ${isActive ? "Deactivated" : "Activated"} successfully.`,
        {
          position: "top-right",
          autoClose: 900,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        }
      );
      fetchAdmins();
    } catch (error) {
      console.error("Error updating active state:", error);
    }
  };

  const handleEdit = (admin) => {
    setSelectedAdmin(admin);
    setModalOpen(true);
  };

  const handleUpdate = async (updatedAdmin) => {
    const updatedBy = localStorage.getItem("username");

      try {
      await axios.patch(`${VINOOTNEW}/api/franchisestateupdate/${updatedAdmin._id}`, {
        fullname: updatedAdmin.fullname,
        password: updatedAdmin.password,
        designation: updatedAdmin.designation,
        mobileNumber: updatedAdmin.mobileNumber,
        updatedBy,
      });
      setModalOpen(false);
      toast.success("Franchise staff details updated successfully.", {
        position: "top-right",
        autoClose: 900,
        theme: "light",
      });
      fetchAdmins();
    } catch (error) {
      console.error("Error updating admin details:", error);
      toast.error("Failed to update franchise staff details.", {
        position: "top-right",
        autoClose: 900,
        theme: "light",
      });
    }
  };

  const handleCancel = () => {
    setModalOpen(false);
    setSelectedAdmin(null);
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const indexOfLastPlan = currentPage * itemsPerPage;
  const indexOfFirstPlan = indexOfLastPlan - itemsPerPage;
  const currentPlans = admins.slice(indexOfFirstPlan, indexOfLastPlan);
  const totalPages = Math.ceil(admins.length / itemsPerPage);

  return (
    <>
      <div>
        <ToastContainer />
        <div className="franchisestaffdetail">
          <table>
            <thead>
              <tr>
                <th>Full Name</th>
                <th>User ID</th>
                <th>Designation</th>
                <th>Email</th>
                <th>Password</th>
                <th>Mobile Number</th>
                <th>Modified By</th>
                <th>Modified At</th>
                <th>Created At</th>
                <th>Created By</th>
                <th>Status</th>
                <th>Action</th>
                <th>Edit/Update</th>
              </tr>
            </thead>
            <tbody>
              {currentPlans.map((admin) => (
                <tr key={admin._id}>
                  <td>{admin.fullname}</td>
                  <td>{admin.userId}</td>
                  <td>{admin.designation}</td>
                  <td>{admin.email}</td>
                  <td>{admin.password}</td>
                  <td>{admin.mobileNumber}</td>
                  <td>{admin.modifiedBy}</td>
                  <td>{admin.modifiedAt}</td>
                  <td>{admin.createdAt}</td>
                  <td>{admin.createdBy}</td>
                  <td className={admin.isActive ? "Activate1" : "Deactivate1"}>
                    {admin.isActive ? "Active" : "Inactive"}
                  </td>
                  <td>
                    <button
                      className={admin.isActive ? "Deactivate" : "Activate"}
                      onClick={() =>
                        toggleActiveState(admin._id, admin.fullname, admin.isActive)
                      }
                    >
                      {admin.isActive ? "Inactive" : "Active"}
                    </button>
                  </td>
                  <td>
                    <button onClick={() => handleEdit(admin)}>Edit</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="paginationss">
            <span onClick={() => handlePageChange(1)}>
              <KeyboardDoubleArrowLeftIcon />
            </span>
            <span onClick={() => handlePageChange(currentPage - 1)}>
              <KeyboardArrowLeftIcon />
            </span>
            {[...Array(totalPages)].map((_, index) => (
              <span
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={currentPage === index + 1 ? "pageactive-page" : ""}
              >
                {index + 1}
              </span>
            ))}
            <span onClick={() => handlePageChange(currentPage + 1)}>
              <KeyboardArrowRightIcon />
            </span>
            <span onClick={() => handlePageChange(totalPages)}>
              <KeyboardDoubleArrowRightIcon />
            </span>
          </div>
        </div>
        {modalOpen && selectedAdmin && (
          <EditStaffModal
            admin={selectedAdmin}
            isOpen={modalOpen}
            onClose={handleCancel}
            onUpdate={handleUpdate}
          />
        )}
      </div>
    </>
  );
};

export default Franchisestaffdetails;
