/* eslint-disable no-unused-vars */
import React, { useState, useEffect} from "react";
//import axios from "axios";
import DoctorSidebar from "./DoctorSB/DoctorSidebar";
import AppointmentDetails from "./AppointmentDetails";
import Treatment from "./TreatmentPages/Treatment";
import ViewHistory from "./TreatmentPages/ViewHistory";
//import { VINOOTNEW } from "../../Helper/Helper";


const Dashboard = () => {
  const [appointments, /*setAppointments*/] = useState([]);
  const [currentPage, setCurrentPage] = useState("appointments");
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [/*viewHistoryPage*/, setViewHistoryPage] = useState(false);

//  useEffect(() => {
//    fetchAppointments();
//  }, []);

  
//  const fetchAppointments = async () => {
//    try {
//        const doctorId = "doctorId"; 
//        //const currentDate = new Date().toISOString().split('T')[0]; 
//      const response = await axios.get(`${VINOOTNEW}/api/appointments`, {
//          params: {
//            doctorId,
//              //date: currentDate,
//          },
//      });

//        //console.log("Fetching appointments for doctorId:", doctorId, "on date:", currentDate);
//        console.log("Appointments fetched successfully:", response.data);
//        setAppointments(response.data);
//    } catch (error) {
//        console.error("Error fetching appointments:", error);
//    }
//};

  const handleAppointmentClick = (id) => {
    const app = appointments.find((app) => app.id === id);
    setSelectedAppointment(app);
    setCurrentPage("treatment");
  };

  const handleViewHistoryClick = () => {
    setViewHistoryPage(true);
    setCurrentPage("ViewHistory");
  };

  return (
    <div>
      <DoctorSidebar />
      <div className="container-fluid">
        <div className="dashboard-content">
          {currentPage === "appointments" && (
            <AppointmentDetails
              appointments={appointments}
              onAppointmentClick={handleAppointmentClick}
            />
          )}
          
          {currentPage === "treatment" && selectedAppointment && (
            <Treatment
              appointment={selectedAppointment}
            />
          )}
          {currentPage === "viewHistory" && 
          <ViewHistory />}
          <button onClick={handleViewHistoryClick}>View History</button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
