// import React, { useState, useEffect } from 'react';
// import AppointmentForm from './AppointmentForm';
// import { VINOOTNEW } from "../Helper/Helper";
// import axios from 'axios';
// import './AppointmentsPage.css';
// import { MdAddCard } from "react-icons/md";
// import ReceptionSidebar from "../Franchisemanagement/Reception/ReceptionSidebar/ReceptionSidebar";
// const AppointmentsPage = () => {
//     const [appointments, setAppointments] = useState([]);
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [appointmentToEdit, setAppointmentToEdit] = useState(null);
//     const [treatmentNames, setTreatmentNames] = useState([]);
//  const [searchQuery, setSearchQuery] = useState("");

//     useEffect(() => {
//         fetchAppointments();
//     }, []);

//     useEffect(() => {
//         // Fetch all treatments 
//         const fetchTreatments = async () => {
//             try {
//                 const response = await axios.get(`${VINOOTNEW}/api/treatment-plan`);
//                 setTreatmentNames(response.data);
//             } catch (error) {
//                 console.error('Error fetching treatments:', error);
//             }
//         };

//         fetchTreatments();
//     }, []);

//     const getTreatmentNameById = (id) => {
//         const treatment = treatmentNames.find(treatment => treatment.plan_id === id);
//         return treatment ? treatment.plan_name : '';
//     };


//     // const fetchAppointments = async () => {
//     //     try {
//     //         const response = await axios.get(`${VINOOTNEW}/api/appointments`);
//     //         setAppointments(response.data);
//     //     } catch (error) {
//     //         console.error('Error fetching appointments:', error);
//     //     }
//     // };
//     const fetchAppointments = async () => {
//         try {
//             const response = await axios.get(`${VINOOTNEW}/api/appointments`);
//             const allAppointments = response.data;
    
//             // Get today's date without the time part
//             const today = new Date();
//             today.setHours(0, 0, 0, 0); // Set the time to midnight to compare only the date part
    
//             // Filter appointments
//             const filteredAppointments = allAppointments.filter(appointment => {
//                 const appointmentDate = new Date(appointment.appointmentDate);
//                 appointmentDate.setHours(0, 0, 0, 0); // Set time to midnight for comparison
    
//                 return appointment.status === 'scheduled' && appointmentDate >= today;
//             });
    
//             setAppointments(filteredAppointments);
//         } catch (error) {
//             console.error('Error fetching appointments:', error);
//         }
//     };
    

//     const handleAddAppointment = () => {
//         setAppointmentToEdit(null); // Clear any previously selected appointment
//         setIsModalOpen(true);
//     };

//     const handleEditAppointment = (appointment) => {
//         setAppointmentToEdit(appointment);
//         setIsModalOpen(true);
//     };

//     const handleSaveAppointment = async (appointmentData) => {
//         try {
//             if (appointmentToEdit) {
//                 // Update existing appointment
//                 await axios.put(`${VINOOTNEW}/api/appointments/${appointmentToEdit._id}`, appointmentData);
//             } else {
//                 // Create new appointment
//                 await axios.post(`${VINOOTNEW}/api/appointments`, appointmentData);
//             }
//             fetchAppointments(); // Refresh the list after save
//             setIsModalOpen(false); // Close the modal
//         } catch (error) {
//             console.error('Error saving appointment:', error);
//         }
//     };

//     const handleDeleteAppointment = async (appointmentId) => {
//         try {
//             await axios.delete(`${VINOOTNEW}/api/appointments/${appointmentId}`);
//             fetchAppointments(); // Refresh the list after deletion
//         } catch (error) {
//             console.error('Error deleting appointment:', error);
//         }
//     };

//     const handleCloseModal = () => {
//         setIsModalOpen(false); // Close the modal
//     };
//     const filteredPlans = appointments.filter((appointment) => {
//         // Get all treatment names for the current appointment and join them into a single string
//         const treatmentNamesForAppointment = appointment.treatmentTypes
//             .map(treatmentId => getTreatmentNameById(treatmentId).toLowerCase())
//             .join(', ');
    
//         return (
//             appointment.patientName.toLowerCase().includes(searchQuery.toLowerCase()) ||
//             appointment.appointmentDate.substring(0, 10).toLowerCase().includes(searchQuery.toLowerCase()) ||
//             appointment.doctorName.toLowerCase().includes(searchQuery.toLowerCase()) ||
//             appointment.timeSlot.toLowerCase().includes(searchQuery.toLowerCase()) ||
//             treatmentNamesForAppointment.includes(searchQuery.toLowerCase())
//         );
//     });
    
//     return (
//         <div className='app-page-container'>
//             <ReceptionSidebar/>
//             <div className='apps-cont'>
//             <h1 style={{fontStyle : 'poppins'}}>Appointments</h1>
//             <div>
//           <input
//             type="text"
//             placeholder="Search..."
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)}
//             className="searches-bar"
//           />
//           </div>

//             <button onClick={handleAddAppointment} className='add-app-btn'>Add Appointment <MdAddCard /></button>
//             {/* <ul>
//                 {appointments.map(appointment => (
//                     <li key={appointment._id}>
//                         {appointment.patientName} - {appointment.appointmentDate} - {appointment.timeSlot}
//                         <button onClick={() => handleEditAppointment(appointment)}>Edit</button>
//                         <button onClick={() => handleDeleteAppointment(appointment._id)}>Delete</button>
//                     </li>
//                 ))}
//             </ul> */}



// <div className="appointment-table-container">
//     <table className="appointment-table">
//         <thead>
//             <tr>
//                 <th>Patient Name</th>
//                 <th>Date</th>
//                 {/* <th>Doctor Id</th> */}
//                 <th>Doctor Name</th>
//                 <th>Treatments</th>
//                 <th>Time Slot</th>
//                 <th>Actions</th>
//             </tr>
//         </thead>
//         <tbody>
//             {filteredPlans.map(appointment => (
//                 <tr key={appointment._id}>
//                     <td>{appointment.patientName}</td>
//                     <td>{appointment.appointmentDate.substring(0,10)}</td>
//                     {/* <td>{appointment.doctorId}</td> */}
//                     <td>{appointment.doctorName}</td>
                    
//                     {/* <td>
//     {appointment.treatmentTypes.map((treatment, index) => (
//         <span key={index}>
//             {treatment}
//             {index < appointment.treatmentTypes.length - 1 && ', '}
//         </span>
//     ))}
// </td> */}
// <td>
//             {appointment.treatmentTypes.map((treatmentId, index) => (
//                 <span key={index}>
//                     {getTreatmentNameById(treatmentId)}
//                     {index < appointment.treatmentTypes.length - 1 && ', '}
//                 </span>
//             ))}
//         </td>

//                     <td>{appointment.timeSlot}</td>
//                     <td>
//                         <button className="btn-edit" onClick={() => handleEditAppointment(appointment)}>Edit</button>
//                         <button className="btn-delete" onClick={() => handleDeleteAppointment(appointment._id)}>Delete</button>
//                     </td>
//                 </tr>
//             ))}
//         </tbody>
//     </table>
// </div>






//             <AppointmentForm
//                 isOpen={isModalOpen}
//                 onClose={handleCloseModal}
//                 onSave={handleSaveAppointment}
//                 appointmentToEdit={appointmentToEdit}
//             />
//             </div>
//         </div>
//     );
// };

// export default AppointmentsPage;









import React, { useState, useEffect } from 'react';
import AppointmentForm from '../../../appointments/AppointmentForm';
import { VINOOTNEW } from "../../../Helper/Helper";
import axios from 'axios';
import '../../../appointments/AppointmentsPage.css';
import { MdAddCard } from "react-icons/md";
import DoctorSidebar from "../DoctorSB/DoctorSidebar";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import the default styles for React Toastify
import { useLocation, useNavigate} from "react-router-dom";//new


const AppointmentsPage = () => {
    const location = useLocation();
    const navigate1 = useNavigate();//new
    const { exportData } = location.state || {};//new

    const [senddata, setsenddata] = useState({});
    const [appointments, setAppointments] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [appointmentToEdit, setAppointmentToEdit] = useState(null);
    const [treatmentNames, setTreatmentNames] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [todaysPendingCount, setTodaysPendingCount] = useState(0); // New state for today's pending appointments count
    const [totalAppointmentsCount, setTotalAppointmentsCount] = useState(0); // New state for total appointments count
    useEffect(() => {
        // Log exportData before the state update to see the current value
        // console.log('Current exportData:', exportData);
        
        if (exportData) {
            setsenddata(exportData);
            setIsModalOpen(true);
        } else {
            setsenddata(null);
            setIsModalOpen(false); // Ensure modal is closed if no exportData
        }
    }, [exportData]);
    
    useEffect(() => {
        fetchAppointments();
    }, []);
    

    useEffect(() => {
        // Fetch all treatments 
        const fetchTreatments = async () => {
            try {
                const response = await axios.get(`${VINOOTNEW}/api/treatment-plan`);
                setTreatmentNames(response.data);
            } catch (error) {
                console.error('Error fetching treatments:', error);
            }
        };

        fetchTreatments();
    }, []);

    const getTreatmentNameById = (id) => {
        const treatment = treatmentNames.find(treatment => treatment.plan_id === id);
        return treatment ? treatment.plan_name : '';
    };

    const fetchAppointments = async () => {
        const branchId = localStorage.getItem('franchiseID');
        try {
            const response = await axios.get(`${VINOOTNEW}/api/appointments`);
            const allAppointments = response.data;
    
            // Get today's date without the time part
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Set the time to midnight to compare only the date part
    
            // Filter appointments
            const filteredAppointments = allAppointments.filter(appointment => {
                const appointmentDate = new Date(appointment.appointmentDate);
                appointmentDate.setHours(0, 0, 0, 0); // Set time to midnight for comparison
    
                return appointment.status === 'scheduled' && appointmentDate >= today && appointment.franchiseID === branchId;
            });

            const pendingCount = filteredAppointments.filter(appointment => {
                const appointmentDate = new Date(appointment.appointmentDate);
                appointmentDate.setHours(0, 0, 0, 0); // Set time to midnight for comparison
                return appointmentDate.getTime() === today.getTime();
            }).length;

            setAppointments(filteredAppointments);
            setTodaysPendingCount(pendingCount); // Set today's pending appointments count
            setTotalAppointmentsCount(filteredAppointments.length); // Set total appointments count
        } catch (error) {
            console.error('Error fetching appointments:', error);
        }
    };

    // const fetchAppointments = async () => {
    //     const branchId = localStorage.getItem('franchiseID');
    //     try {
    //         const response = await axios.get(`${VINOOTNEW}/api/appointments`);
    //         const allAppointments = response.data;
    
    //         // Get today's date without the time part
    //         const today = new Date();
    //         today.setHours(0, 0, 0, 0); // Set the time to midnight to compare only the date part
    
    //         // Filter appointments
    //         const filteredAppointments = allAppointments.filter(appointment => {
    //             const appointmentDate = new Date(appointment.appointmentDate);
    //             appointmentDate.setHours(0, 0, 0, 0); // Set time to midnight for comparison
    
    //             return appointment.status === 'scheduled' && appointmentDate >= today && appointment.franchiseID === branchId;
    //         });

    //         const pendingCount = filteredAppointments.filter(appointment => {
    //             const appointmentDate = new Date(appointment.appointmentDate);
    //             appointmentDate.setHours(0, 0, 0, 0); // Set time to midnight for comparison
    //             return appointmentDate.getTime() === today.getTime();
    //         }).length;

    //         setAppointments(filteredAppointments);
    //         setTodaysPendingCount(pendingCount); // Set today's pending appointments count
    //         setTotalAppointmentsCount(filteredAppointments.length); // Set total appointments count
    //     } catch (error) {
    //         console.error('Error fetching appointments:', error);
    //     }
    // };





    const handleAddAppointment = () => {
        setAppointmentToEdit(null); // Clear any previously selected appointment
        setIsModalOpen(true);
    };

    const handleEditAppointment = (appointment) => {
        setAppointmentToEdit(appointment);
        setIsModalOpen(true);
    };

    const handleSaveAppointment = async (appointmentData) => {
        try {
            if (appointmentToEdit) {
                // Update existing appointment
                await axios.put(`${VINOOTNEW}/api/appointments/${appointmentToEdit._id}`, appointmentData);
            } else {
                // Create new appointment
                await axios.post(`${VINOOTNEW}/api/appointments`, appointmentData);
                setsenddata(null)
            }
            toast.success("appointment saved", {
                position: "top-right",
                autoClose: 1500,
              });
            fetchAppointments(); // Refresh the list after save
            setIsModalOpen(false); // Close the modal
        } catch (error) {
            console.error('Error saving appointment:', error);
        }
    };

    const handleDeleteAppointment = async (appointmentId) => {
        try {
            await axios.delete(`${VINOOTNEW}/api/appointments/${appointmentId}`);
            toast.error("appointment cancelled", {
                position: "top-right",
                autoClose: 1500,
              });
            fetchAppointments(); // Refresh the list after deletion
        } catch (error) {
            console.error('Error deleting appointment:', error);
        }
    };

   
     const handleCloseModal = () => {
        setsenddata(null);
        setIsModalOpen(false); // Close the modal
        navigate1('.', { replace: true });
    };

    const filteredPlans = appointments.filter((appointment) => {
        // Get all treatment names for the current appointment and join them into a single string
        const treatmentNamesForAppointment = appointment.treatmentTypes
            .map(treatmentId => getTreatmentNameById(treatmentId).toLowerCase())
            .join(', ');
    
        return (
            appointment.patientName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            appointment.appointmentDate.substring(0, 10).toLowerCase().includes(searchQuery.toLowerCase()) ||
            appointment.doctorName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            appointment.timeSlot.toLowerCase().includes(searchQuery.toLowerCase()) ||
            treatmentNamesForAppointment.includes(searchQuery.toLowerCase())
        );
    });

    const formatDate =(dateString)=> {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
    
        return `${day}-${month}-${year}`;
    }
    

    return (
        <div className='app-page-container'>
            <ToastContainer />
            <DoctorSidebar/>
            <div className='apps-cont'>
                <h1 style={{ fontStyle: 'poppins' }}>Appointments</h1>
                
                {/* Display counts */}
               

                <div>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="searches-bar"
                    />
                     <div className="appointment-counts">
                    <div>Today's Pending Appointments: {todaysPendingCount}</div>
                    <div>Total Appointments: {totalAppointmentsCount}</div>
                </div>
                </div>
              

                <button onClick={handleAddAppointment} className='add-app-btn'>
                    Add Appointment <MdAddCard />
                </button>

                <div className="appointment-table-container">
                    <table className="appointment-table">
                        <thead>
                            <tr>
                                <th>Patient Name</th>
                                <th>Mobile No.</th>
                                <th>Date</th>
                                <th>Doctor Name</th>
                                <th>Treatments</th>
                                <th>Time Slot</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredPlans.map(appointment => (
                                <tr key={appointment._id}>
                                    <td>{appointment.patientName}</td>
                                    <td>{appointment.mobileNumber}</td>
                                    <td>{formatDate(appointment.appointmentDate)}</td>
                                    <td>{appointment.doctorName}</td>
                                    <td>
                                        {appointment.treatmentTypes.map((treatmentId, index) => (
                                            <span key={index}>
                                                {getTreatmentNameById(treatmentId)}
                                                {index < appointment.treatmentTypes.length - 1 && ', '}
                                            </span>
                                        ))}
                                    </td>
                                    <td>{appointment.timeSlot}</td>
                                    <td>
                                        <button className="btn-edit" onClick={() => handleEditAppointment(appointment)}>change</button>
                                        <button className="btn-delete" onClick={() => handleDeleteAppointment(appointment._id)}>cancel</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <AppointmentForm
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    onSave={handleSaveAppointment}
                    appointmentToEdit={appointmentToEdit}
                     initialData={senddata || null}
                />
            </div>
        </div>
    );
};

export default AppointmentsPage;









