import React, { useState, useEffect } from "react";
// import "./Franchisedetails.css";
import "./EditFranchiseModal.css";
import ReactModal from "react-modal";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

ReactModal.setAppElement("#root"); // Set for accessibility

const EditFranchiseModal = ({ franchise, isOpen, onUpdate, onClose }) => {
  const [editedFranchise, setEditedFranchise] = useState({});
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    // console.log("EditFranchiseModal mounted with franchise:", franchise);
    if (franchise) {
      setEditedFranchise(franchise);
    }
  }, [franchise]);

  const handleInputChange = (e, key) => {
    const { value } = e.target;
    setEditedFranchise((prevState) => ({ ...prevState, [key]: value }));
    // console.log(`Input changed - ${key}:`, value);
  };

  const validateForm = () => {
    const errors = {};
    if (!editedFranchise.franchisename)
      errors.franchisename = "Franchise Name is required";
    if (!editedFranchise.mobileNumber)
      errors.mobileNumber = "Mobile Number is required";
    if (!editedFranchise.state) errors.state = "State is required";
    if (!editedFranchise.city) errors.city = "City is required";
    if (!editedFranchise.area) errors.area = "Area is required";
    if (!editedFranchise.address) errors.address = "Address is required";
    if (!editedFranchise.pincode) errors.pincode = "Pincode is required";
    // console.log("Validation errors:", errors);
    return errors;
  };

  const handleUpdate = () => {
    // console.log("Attempting to update franchise:", editedFranchise);
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    onUpdate(editedFranchise);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="edit_franichise_popup"
      overlayClassName="edit_franichise_popup_overlay"
    >
      <div className="edit_branchname_close">
        <h2 className="edit_branchname">Edit Branch</h2>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <form
        className="edit_branch_formpage"
        onSubmit={(e) => {
          e.preventDefault();
          handleUpdate();
        }}
      >
        <label>
          <input
            type="text"
            value={editedFranchise.franchisename || ""}
            onChange={(e) => handleInputChange(e, "franchisename")}
          />
          <span>
            Branch Name<span style={{ color: "red" }}>*</span>
          </span>
          {validationErrors.franchisename && (
            <p className="error">{validationErrors.franchisename}</p>
          )}
        </label>
        <label>
          <input
            type="text"
            value={editedFranchise.franchiseID || ""}
            onChange={(e) => handleInputChange(e, "franchiseID")}
            disabled
          />
          <span>
            Branch ID<span style={{ color: "red" }}>*</span>
          </span>
          {validationErrors.franchiseID && (
            <p className="error">{validationErrors.franchiseID}</p>
          )}
        </label>

        <label>
          <input
            type="tel"
            value={editedFranchise.mobileNumber || ""}
            onChange={(e) => handleInputChange(e, "mobileNumber")}
          />
          <span>
            Mobile Number<span style={{ color: "red" }}>*</span>
          </span>
          {validationErrors.mobileNumber && (
            <p className="error">{validationErrors.mobileNumber}</p>
          )}
        </label>
        {/* <label>
          <input
            type="text"
            value={editedFranchise.state || ""}
            onChange={(e) => handleInputChange(e, "state")}
          />
          <span>State</span>
          {validationErrors.state && (
            <p className="error">{validationErrors.state}</p>
          )}
        </label>
        <label>
          <input
            type="text"
            value={editedFranchise.city || ""}
            onChange={(e) => handleInputChange(e, "city")}
          />
          <span>City</span>
          {validationErrors.city && (
            <p className="error">{validationErrors.city}</p>
          )}
        </label> */}
        {/* <label>
          <input
            type="text"
            value={editedFranchise.area || ""}
            onChange={(e) => handleInputChange(e, "area")}
          />
          <span>Area</span>
          {validationErrors.area && (
            <p className="error">{validationErrors.area}</p>
          )}
        </label> */}
        <label>
          <input
            type="text"
            value={editedFranchise.address || ""}
            onChange={(e) => handleInputChange(e, "address")}
          />
          <span>Address</span>
          {validationErrors.address && (
            <p className="error">{validationErrors.address}</p>
          )}
        </label>
        {/* <label>
          <input
            type="number"
            value={editedFranchise.pincode || ""}
            onChange={(e) => handleInputChange(e, "pincode")}
          />
          <span>Pincode</span>
          {validationErrors.pincode && (
            <p className="error">{validationErrors.pincode}</p>
          )}
        </label> */}
        <div className="edit_modal_actions">
          <button className="update" type="submit">
            Update
          </button>
          <button className="cancel" type="button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </form>
    </ReactModal>
  );
};

export default EditFranchiseModal;
