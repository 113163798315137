
import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import the default styles for React Toastify
import { useNavigate } from "react-router-dom";
import { VINOOTNEW } from "../../Helper/Helper";
import Sidebar from "../Sidebar/Sidebar";
import "./State.css";

// import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
// import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";


const States = () => {
  const navigate = useNavigate();

  const [stateName, setStateName] = useState("");
  const [states, setStates] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage] = useState(3);

  // Get user ID from local storage
  const userId = localStorage.getItem("userId");
  const presentTime = new Date().toLocaleString();

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${VINOOTNEW}/api/states`);
      setStates(response.data);
    } catch (error) {
      console.error("Failed to fetch states", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  // const handleSelect = (event) => {
  //   const inputValue = event.target.value;
  //   setStateName(inputValue);
  //   if (inputValue.length < 3 || inputValue.length > 60) {
  //     setError("State name must be between 3 and 60 characters.");
  //   } else {
  //     setError("");
  //   }
  // };

  const handleSelect = (event) => {
    const inputValue = event.target.value.trim();
    setStateName(inputValue);

    if (inputValue === "") {
      setError(""); // Clear the error if the input is empty
    } else {
    // Regular expression to match alphabetic characters only
    const alphabeticRegex = /^[a-zA-Z\s]*$/;

    if (!alphabeticRegex.test(inputValue)) {
      setError("State name must contain only alphabetic characters.");
    } else if (inputValue.length < 3 || inputValue.length > 60) {
      setError("State name must be between 3 and 60 characters.");
    } else {
      setError("");
    }
  }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (error) {
      toast.error("Please fix the errors before submitting", {
        position: "top-right",
        autoClose: 1500,
      });
      return;
    }

    if (!stateName) {
      toast.error("Please enter a valid state name", {
        position: "top-right",
        autoClose: 1500,
      });
      return;
    }
    try {
      const existingStates = await axios.get(`${VINOOTNEW}/api/states`);
      const count = existingStates.data.length;
      const state_id = generateUniqueId(stateName, count + 0);
      const response = await axios.post(`${VINOOTNEW}/api/states`, {
        state_id: state_id,
        name: stateName,
        createdBy: userId, // Set createdBy field
        createdAt: presentTime, // Set createdAt field
        modifiedBy: userId, // Set modifiedBy field
        modifiedAt: presentTime, // Set modifiedAt field
      });
      if (response.status === 201) {
        // console.log("State added successfully");
        toast.success("State added successfully", {
          position: "top-right",
          autoClose: 1500,
          onClose: () => {
            navigate("/Cities");
          },
        });
        return;
      } else {
        toast.error("Failed to add State", {
          position: "top-right",
          autoClose: 1500,
        });
        return;
      }
    } catch (error) {
      // console.error("Failed to add state", error);
      if (error.response && error.response.status === 400) {
        toast.error("State already exists", {
          position: "top-right",
          autoClose: 1500,
        });
        // return;
      } else {
        toast.error("Failed to add state", {
          position: "top-right",
          autoClose: 1500,
        });
    }
  }
  };

  const toggleStateStatus = async (stateId, stateName, currentState) => {
    try {
      const updatedStatus = currentState === "active" ? "inactive" : "active";
      await axios.put(`${VINOOTNEW}/api/states/${stateId}/toggle`, {
        status: updatedStatus,
        modifiedBy: userId, // Set modifiedBy field
        modifiedAt: presentTime, // Set modifiedAt field
      });
      if (currentState === "inactive") {
        toast.success(stateName + " is Activated sucessfully.", {
          position: "top-right",
          autoClose: 800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(stateName + " is Deactivated .", {
          position: "top-right",
          autoClose: 800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      fetchStates(); // Refresh states after status update
    } catch (error) {
      // console.error("Failed to toggle state status", error);
      toast.error("Failed to toggle state status", {
        position: "top-right",
        autoClose: 1500,
      });
      return;
    }
  };

  const generateUniqueId = (name, count) => {
    const abbreviation = name.substring(0, 3).toUpperCase();
    const paddedCount = (count + 1).toString().padStart(3, "0");
    const id = abbreviation + paddedCount;
    return id;
  };

  // Pagination handlers
  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  // Get current plans
  // const indexOfLastPlan = currentPage * itemsPerPage;
  // const indexOfFirstPlan = indexOfLastPlan - itemsPerPage;
  // const currentPlans = states.slice(indexOfFirstPlan, indexOfLastPlan);

  // Calculate total pages
  // const totalPages = Math.ceil(states.length / itemsPerPage);

  return (
    <div className="states-total">
      <ToastContainer />
      <div>
        <Sidebar />
      </div>
      <div className="staes-right">
        <h1>State Master</h1>
        <form className="states-form" onSubmit={handleSubmit}>
          <label>
            State Name: <span style={{ color: "red" }}>*</span>
            <input type="text" value={stateName} onChange={handleSelect} />
          </label>
          {error && <div className="price-btn">{error}</div>}
          <button className="createe-btnn" type="submit">Create</button>
        </form>

        <div className="statetable_pagination_parent">
        <h2 className="states_list_heading">States List</h2>
        {isLoading ? (
              <p>Loading areas...</p>
            ) : (
        <table className="states-table">
          <thead>
            <tr>
              <th>State Name</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {states.map((state) => (
              <tr key={state._id}>
                <td>{state.name}</td>
                <td className={state.status === "active"? "button1-Active" : "button1-Inactive"}>
                  {state.status === "active" ? "Active" : "Inactive"}</td>
                <td>
                  <button className={state.status === "active" ? "button-Inactive" : "button-Active"}
                    onClick={() => toggleStateStatus(state._id, state.name, state.status)}>
                    {state.status === "active" ? "Inactive" : "Active"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
            )}

{/* 
<div className="paginationss">
  <span
    onClick={() => handlePageChange(1)}
    disabled={currentPage === 1}
  >
    <KeyboardDoubleArrowLeftIcon />
  </span>
  <span
    onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
    disabled={currentPage === 1}
  >
    <KeyboardArrowLeftIcon />
  </span>
  {Array.from({ length: 3 }, (_, index) => {
    const startPage = Math.max(
      Math.floor((currentPage - 1) / 3) * 3 + 1,
      1
    );
    const pageNumber = startPage + index;

    return (
      pageNumber <= totalPages && (
        <span
          key={pageNumber}
          onClick={() => handlePageChange(pageNumber)}
          id={currentPage === pageNumber ? "pageactive-page" : ""}
        >
          {pageNumber}
        </span>
      )
    );
  })}
  <span
    onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
    disabled={currentPage === totalPages}
  >
    <KeyboardArrowRightIcon />
  </span>
  <span
    onClick={() => handlePageChange(totalPages)}
    disabled={currentPage === totalPages}
  >
    <KeyboardDoubleArrowRightIcon />
  </span>
</div> */}

</div>


      </div>
    </div>
  );
};

export default States;
