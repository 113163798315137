import React from "react";
import ReactModal from "react-modal";
import "./FranchiseDetailsInfo.css";

const ShowFranchiseUsersInfo = ({ franchiseAdmins, isOpen, onClose }) => {
  if (!franchiseAdmins) return null;
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="info_franchiseAdminadmin_popup"
      overlayClassName="info_franchiseAdminadmin_popup_overlay"
    >
      <div className="info_franchiseAdminadmin_container">
        <h2>
          Details for{" "}
          <span>{franchiseAdmins.fullname || console.log("details")}</span>
        </h2>
        <ol>
          <li>
            <strong>User ID :</strong> {franchiseAdmins.userId}
          </li>

          <li>
            <strong>Designation :</strong> {franchiseAdmins.designation}
          </li>
          <li>
            <strong>BranchName :</strong> {franchiseAdmins.franchisename}
          </li>
          <li>
            <strong>Branch ID :</strong> {franchiseAdmins.franchisename}
          </li>
          <li>
            <strong>Mobile No :</strong> {franchiseAdmins.mobileNumber}
          </li>

          <li>
            <strong>Email :</strong> {franchiseAdmins.email}
          </li>
          <li>
            <strong>Password :</strong> {franchiseAdmins.password}
          </li>
          <li>
            <strong>Status :</strong>
            <span className={franchiseAdmins.isActive ? "" : "inactive"}>
              {franchiseAdmins.isActive ? "Active" : "Inactive"}
            </span>
          </li>
        </ol>
      </div>
    </ReactModal>
  );
};

export default ShowFranchiseUsersInfo;
