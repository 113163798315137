// import React, { useState, useEffect } from "react";
// import "./EditStaffModal.css";
// import ReactModal from "react-modal";
// import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
// ReactModal.setAppElement("#root"); // Set for accessibility


// const EditStaffModal = ({ admin, isOpen, onClose, onUpdate }) => {
//   const [updatedAdmin, setUpdatedAdmin] = useState({ });
//   const [validationErrors, setValidationErrors] = useState({});
//   const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility


//   useEffect(() => {
//     if (admin) {
//       setUpdatedAdmin(admin);
//     }
//   }, [admin ]);

//   const handleInputChange = (e, key) => {
//     const { value } = e.target;
//     setUpdatedAdmin((prevState) => ({ ...prevState, [key]: value }));
//   };

//   const validateForm = () => {
//     const errors = {};
//     if (!updatedAdmin.fullname) errors.fullname = "Full Name is required";
//     if (!updatedAdmin.designation) errors.designation = "Designation is required";
//     if (!updatedAdmin.mobileNumber)
//       errors.mobileNumber = "Phone Number is required";
//     if (!updatedAdmin.password) errors.password = "Password is required";
//     return errors;
//   };

//   const handleUpdate = () => {
//     const errors = validateForm();
//     if (Object.keys(errors).length > 0) {
//       setValidationErrors(errors);
//       return;
//     }
//     onUpdate(updatedAdmin);
//   };

//   const toggleShowPassword = () => {
//     setShowPassword(!showPassword);
//   };
  
//   const handleMouseDownPassword = (event) => {
//     event.preventDefault();
//   };

//   return (
//  <ReactModal
//       isOpen={isOpen}
//       onRequestClose={onClose}
//       className="edit_updateadmin_popup"
//       overlayClassName="edit_updateadmin_popup_overlay"
//     >         <div className="edit_updateadmin_close">
//    <h2 className="edit_updatename">Edit Admin Details</h2>
//    <IconButton onClick={onClose}>
//           <CloseIcon />
//         </IconButton>
//       </div>
//       <form   className="edit_updateadmin_formpage"
//         onSubmit={(e) => {
//           e.preventDefault();
//           handleUpdate();
//         }} >
//         <label>
//           <input
//             type="text"
//             value={updatedAdmin.fullname || ""}
//             onChange={(e) => handleInputChange(e, "fullname")}
            
//           />
//            <span>
//             Full Name<span style={{ color: "red" }}>*</span>
//           </span>
//         </label>
//         {validationErrors.fullname && (
//             <p className="error">{validationErrors.fullname}</p>
//           )}
//         <label>
          
//           <input
//             type="text"
//             value={updatedAdmin.designation || ""}
//             onChange={(e) => handleInputChange(e, "designation")}
//           /> <span>
//             Designation<span style={{ color: "red" }}>*</span>
//           </span>
//           {validationErrors.designation && (
//             <p className="error">{validationErrors.designation}</p>
//           )}
//         </label>
//         <label>
//           <input
//             type="text"
//             name="mobileNumber"
//             value={updatedAdmin.mobileNumber || ""}
//             onChange={(e) => handleInputChange(e, "mobileNumber")}
//           /> <span>
//             Mobile Number<span style={{ color: "red" }}>*</span>
//           </span>
//           {validationErrors.mobileNumber && (
//             <p className="error">{validationErrors.mobileNumber}</p>
//           )}
//         </label>
//         <label>
//         <div className="inputpassword_eye_container">
//         <input
//               type={showPassword ? "text" : "password"}
//               value={updatedAdmin.password || ""}
//               onChange={(e) => handleInputChange(e, "password")}
//             />
//             <span>
//               Password<span style={{ color: "red" }}>*</span>
//             </span>
//             <IconButton
//               aria-label="toggle password visibility"
//               onClick={toggleShowPassword}
//               onMouseDown={handleMouseDownPassword}
//               edge="end"
//             >   {showPassword ? <VisibilityOff /> : <Visibility />}
//             </IconButton>
//           </div>
//           {validationErrors.password && (
//             <p className="error">{validationErrors.password}</p>
//           )}
//         </label>
//         <div className="edit_updateadmin_actions">
//           <button className="update" onClick={handleUpdate}>
//             Update
//           </button>
//           <button className="cancel" onClick={onClose}>
//             Cancel
//           </button>
//         </div>
//       </form>
//     </ReactModal>
//   );
// };

// export default EditStaffModal;








import React, { useState, useEffect } from "react";
import "./EditStaffModal.css";
import ReactModal from "react-modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

ReactModal.setAppElement("#root"); // Set for accessibility

const EditStaffModal = ({ admin, isOpen, onClose, onUpdate }) => {
  const [updatedAdmin, setUpdatedAdmin] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (admin) {
      setUpdatedAdmin(admin);
    }
  }, [admin]);

  const handleInputChange = (e, key) => {
    const { value } = e.target;
    setUpdatedAdmin((prevState) => ({ ...prevState, [key]: value }));
  };

  const validateForm = () => {
    const errors = {};
    if (!updatedAdmin.fullname) errors.fullname = "Full Name is required";
    if (!updatedAdmin.designation) errors.designation = "Designation is required";
    if (!updatedAdmin.mobileNumber) {
      errors.mobileNumber = "Phone Number is required";
    } else if (!/^\d{10}$/.test(updatedAdmin.mobileNumber)) {
      errors.mobileNumber = "Phone Number must be 10 digits";
    }
    if (!updatedAdmin.password) errors.password = "Password is required";
    return errors;
  };

  const handleUpdate = () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    onUpdate(updatedAdmin);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="edit_updateadmin_popup"
      overlayClassName="edit_updateadmin_popup_overlay"
    >
      <div className="edit_updateadmin_close">
        <h2 className="edit_updatename">Edit Admin Details</h2>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <form
        className="edit_updateadmin_formpage"
        onSubmit={(e) => {
          e.preventDefault();
          handleUpdate();
        }}
      >
        <label>
          <input
            type="text"
            value={updatedAdmin.fullname || ""}
            onChange={(e) => handleInputChange(e, "fullname")}
            aria-label="Full Name"
          />
          <span>
            Full Name<span style={{ color: "red" }}>*</span>
          </span>
        </label>
        {validationErrors.fullname && (
          <p className="error">{validationErrors.fullname}</p>
        )}
        <label>
          <input
            type="text"
            value={updatedAdmin.designation || ""}
            onChange={(e) => handleInputChange(e, "designation")}
            aria-label="Designation"
          />
          <span>
            Designation<span style={{ color: "red" }}>*</span>
          </span>
        </label>
        {validationErrors.designation && (
          <p className="error">{validationErrors.designation}</p>
        )}
        <label>
          <input
            type="text"
            name="mobileNumber"
            value={updatedAdmin.mobileNumber || ""}
            onChange={(e) => handleInputChange(e, "mobileNumber")}
            aria-label="Mobile Number"
          />
          <span>
            Mobile Number<span style={{ color: "red" }}>*</span>
          </span>
        </label>
        {validationErrors.mobileNumber && (
          <p className="error">{validationErrors.mobileNumber}</p>
        )}
        <label>
          <div className="inputpassword_eye_container">
            <input
              type={showPassword ? "text" : "password"}
              value={updatedAdmin.password || ""}
              onChange={(e) => handleInputChange(e, "password")}
              aria-label="Password"
            />
            <span>
              Password<span style={{ color: "red" }}>*</span>
            </span>
            <IconButton
              aria-label="toggle password visibility"
              onClick={toggleShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </div>
        </label>
        {validationErrors.password && (
          <p className="error">{validationErrors.password}</p>
        )}
        <div className="edit_updateadmin_actions">
          <button className="update" type="submit">
            Update
          </button>
          <button className="cancel" type="button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </form>
    </ReactModal>
  );
};

export default EditStaffModal;
