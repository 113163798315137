

import React, { useState, useEffect, useRef } from "react";
import { VINOOTNEW } from "../../../Helper/Helper";
import axios from "axios";
// import VW1Logo from "..src/asset/VW1Logo.png";
import VW2 from "../../../images/VW2.png"

// import ReceptionSidebar from "../ReceptionSidebar/ReceptionSidebar";
import DoctorSidebar from "../../Doctor/DoctorSB/DoctorSidebar";
// import VW1-logo from "./asset/VW1-logo.png"
// import VW1Logo from "./asset/VW1Logo.png";
// import VW1Logo from "../asset/VW1Logo.png"
// import VW1Logo from '../src/asset/VW1Logo.png';


import "./Billing.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom"; // Import useLocation
import ReceptionSidebar from "../ReceptionSidebar/ReceptionSidebar";

const Billing = () => {
  

  const location = useLocation();
  const { billingData } = location.state || {}; // Extract billingData from location state
  const [phoneInput, setPhoneInput] = useState("");
  const [/*patient_name*/, setPatient_name] = useState("");
  const [/*patient_id*/, setPatient_id] = useState("");
  const [/*address*/, setAddress] = useState("");
  // const [additionalField, setAdditionalField] = useState(false);

  // UseEffect to set patient details automatically from billingData
  useEffect(() => {
    if (billingData) {
      setPhoneInput(billingData.mobileNumber || "");

      // Directly set patient details if they are available
      if (billingData.patientName) {
        setPatient_name(billingData.patientName);
      }
      
      if (billingData.patientId) {
        setPatient_id(billingData.patientId);
      }
      
      if (billingData.patientAddress) {
        setAddress(billingData.patientAddress);
      }

      // Disable the dropdown and suggestions as the data is now coming directly
      setAdditionalField(false);
    }
  }, [billingData]);

    
   
      // Set mobile numberb2

  
  // --------------patient details fetch---------------

  // const [phoneInput, setPhoneInput] = useState("");
  const [selectedNumber, setSelectedNumber] = useState(null);
  // const [suggestions1, setSuggestions1] = useState([]);
  const [filteredSuggestions1, setFilteredSuggestions1] = useState([]);
  const [focusedInput1, setFocusedInput1] = useState(null);
  // const [isLoading1, setIsLoading1] = useState(false);
  const [patientError, setPatientError] = useState("");
  const [plans, setPlans] = useState([
    {
      plan_name: " ",
      GST: " ",
      GSTamount: " ",
      days: " ",
      price: " ",
      TotalAmount: " "
    }
  ]);
  const suggestionBoxRef3 = useRef(null);

  const [patients, setPatients] = useState([]);
  const [medicines, setMedicines] = useState([]);
  const [filteredMedicineSuggestions, setFilteredMedicineSuggestions] = useState([]);
  const [medicineName, setMedicineName] = useState("");

  const [selectedMedicines, setSelectedMedicines] = useState([
    {
      plan_name: "",
      price: "",
      status: ""
    }
  ]);  // const [isLoading, setIsLoading] = useState(false);
  const medicineSuggestionRef = useRef(null);
  const inputRef = useRef(null); // Ref for the input field
  const [focusedMedicineInput, setFocusedMedicineInput] = useState(null);

  useEffect(() => {
    const fetchNumbers = async () => {
      setIsLoading(true);
      try {
        const frid = localStorage.getItem("franchiseID");
        if (frid) {
          const response = await axios.get(`${VINOOTNEW}/api/patients/franchise/${frid}`);
          setPatients(response.data);
          const mobileNumberSet = new Set();
          response.data.forEach((patient) => {
            mobileNumberSet.add(patient.mobile_number); // Add mobile number to the set
          });

          // setSuggestions1(mobileNumberSet);
          setFilteredSuggestions1(mobileNumberSet);
          // setIsLoading1(false);
        } else {
          console.error("FranchiseID not found in localStorage");
        }
      } catch (error) {
        console.error("Failed to fetch details", error);
        // setIsLoading1(false);
      }
    };
    fetchNumbers();
  }, []);

  useEffect(() => {
    const filterSuggestions = () => {
      if (typeof phoneInput !== "string" || phoneInput.trim() === "") {
        setFilteredSuggestions1([]);
        setSelectedNumber(null); // Reset selected number
        setPatientError(""); // Clear patient error
      } else {
        const filtered = patients.filter(
          (patient, index, self) =>
            patient.mobile_number.includes(phoneInput) &&
            self.findIndex((p) => p.mobile_number === patient.mobile_number) ===
              index
        );

        setFilteredSuggestions1(filtered);
        if (phoneInput.trim() !== "" && filtered.length === 0) {
          setSelectedNumber(null);
          setPatientError(
            "Mobile number not registered. Please add the patient."
          );
        } else {
          setPatientError("");
        }
      }
    };
    filterSuggestions();
  }, [phoneInput, patients]);

  const handlePlanChange1 = (e) => {
    const newPhoneInput = e.target.value;
    const previousPhoneInput = phoneInput;
    setPhoneInput(newPhoneInput);
    if (newPhoneInput.length < previousPhoneInput.length) {
      setAdditionalField(false);
      setSelectedNumber(null);
      setPatient_id("");
      setPatient_name("");
      setAddress("");
    }

    setFocusedInput1("number");
  };

  // const handlePlanChange1 = (e) => {
  //   setPhoneInput(e.target.value);
  //   setAdditionalField(false);
  //   // Reset all fields when manually changing the input
  //   setPatient_name("");
  //   setPatient_id("");
  //   setAddress("");
  // };
  // Step 1: Identify duplicate mobile numbers
  const mobileNumberMap = {};
  patients.forEach((patient) => {
    if (mobileNumberMap[patient.mobile_number]) {
      mobileNumberMap[patient.mobile_number].push(patient);
    } else {
      mobileNumberMap[patient.mobile_number] = [patient];
    }
  });
  const [additionalField, setAdditionalField] = useState(false);
  const [patientSuggestions, setPatientSuggestions] = useState([]);
  const [patientName, setPatientName] = useState("");

  // this is for enter the plan dropdown error
  // const suggestionBoxRef1 = useRef(null); // Ref for plan suggestion box
const inputRef1 = useRef(null); // Ref for plan input field


  const handlePlanSelection1 = (mobileNumber) => {
    const associatedPatients = mobileNumberMap[mobileNumber] || [];
    if (associatedPatients.length > 1) {
      const selectedPatient = associatedPatients[0];
      // Multiple patients with this mobile number
      setSelectedNumber(null); // Reset the selected patient
      setPatientError(""); // Reset error message
      setPhoneInput(selectedPatient.mobile_number); // Set the mobile number
      setAdditionalField(true); // Toggle visibility for additional patient name selection
      setPatientSuggestions(associatedPatients); // Set suggestions for the patient name field
    } else {
      // Only one patient with this mobile number
      const selectedPatient = associatedPatients[0];
      setSelectedNumber(selectedPatient); // Select the patient
      setPhoneInput(selectedPatient.mobile_number); // Set the mobile number
      setPatientError(""); // Clear patient error
      setAdditionalField(false); // Hide additional patient name selection
    }
    // Handle cases where mobile number has only one associated patient
    if (selectedNumber && additionalField === false) {
      // Fill patient details when only one patient is associated with the selected mobile number
      setPatient_id(selectedNumber.patient_id);
      setPatient_name(selectedNumber.patient_name);
      setAddress(selectedNumber.address);
    } else {
      setPatient_id("");
      setPatient_name("");
      setAddress("");
    }
  };
  // Function to handle patient name selection
  const handlePatientNameSelection = (patient) => {
    setSelectedNumber(patient); // Set the selected patient
    setPhoneInput(patient.mobile_number); // Set the mobile number
    setAdditionalField(false); // Hide the additional field
  };

  useEffect(() => {
    if (billingData) {
      // Set the mobile number from billingData
      setPhoneInput(billingData.mobileNumber || "");
  
      // Directly set patient details from billingData
      if (billingData.patientName) {
        setPatient_name(billingData.patientName); // Set patient name
      }
  
      if (billingData.patientId) {
        setPatient_id(billingData.patientId); // Set patient ID
      }
  
      if (billingData.patientAddress) {
        setAddress(billingData.patientAddress); // Set patient address
      }
  
      // Ensure additional field is hidden if data is complete
      setAdditionalField(false);
    }
  }, [billingData]);


  // -------------------Doctor details fetch ---------------
  const [doctors, setDoctors] = useState([]);
  const [paymentType, setPaymentType] = useState("Cash"); // State for payment type
  const [amountPaid, setAmountPaid] = useState(0); // State for amount paid

  const [selectedDoctor, setSelectedDoctor] = useState(localStorage.getItem("fullname") || "");

  // Function to handle doctor selection
  const handleDoctorChange = (e) => {
    const selectedDoctorId = e.target.value; // Get the selected doctor's ID
    setSelectedDoctor(selectedDoctorId);
    // Disable therapist selection
    setSelectedTherapist("");
  };

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const frid = localStorage.getItem("franchiseID");
        if (frid) {
          const response = await axios.get(
            `${VINOOTNEW}/api/franchisefetchusers/${frid}`
          );
          // Filter doctors from the response data
          const doctorList = response.data.filter(
            (admin) => admin.designation === "Doctor"
          );
          setDoctors(doctorList);
        } else {
          console.error("FranchiseID not found in localStorage");
        }
      } catch (error) {
        console.error("Error fetching doctors:", error);
      }
    };

    fetchDoctors();
  }, []);

  // -------------------fetching therspits------------
  const [therapists, setTherapists] = useState([]);
  const [selectedTherapist, setSelectedTherapist] = useState(""); // State for selected doctor

  // Function to handle doctor selection
  const handleTherapistChange = (e) => {
    const selectedTherapistId = e.target.value; // Get the selected doctor's ID
    setSelectedTherapist(selectedTherapistId);
    // Disable doctor selection
    setSelectedDoctor("");
  };

  useEffect(() => {
    const fetchTherapists = async () => {
      try {
        const frid = localStorage.getItem("franchiseID");
        if (frid) {
          const response = await axios.get(
            `${VINOOTNEW}/api/franchisefetchusers/${frid}`
          );
          // Filter doctors from the response data
          const therapitsList = response.data.filter(
            (admin) => admin.designation === "Therapist"
          );
          setTherapists(therapitsList);
        } else {
          console.error("FranchiseID not found in localStorage");
        }
      } catch (error) {
        console.error("Error fetching Therapists:", error);
      }
    };

    fetchTherapists();
  }, []);

  // -------------------posting of all data ----------------

  // const [gst, setGST] = useState("");
  // const [price, setPrice] = useState("");
  // const [days, setDays] = useState(0);
  // const [GSTamount, setGSTamount] = useState("");
  // const [TotalAmount, setTotalAmount] = useState("");
  const [status, setStatus] = useState("");
  const [remaining, setRemainingAmount] = useState(0);
  const [planName, setPlanName] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [suggestions, setSuggestions] = useState([]); // Autosuggest options
  const [filteredSuggestions, setFilteredSuggestions] = useState([]); // Filtered suggestions based on input
  const [focusedInput, setFocusedInput] = useState(null);
  const [focusedInput2, setFocusedInput2] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Loading indicator
  // // const [mobile_number, setMobile_number] = useState(false); // Loading indicator
  // const [/*patient_id*/, setPatient_id] = useState(false); // Loading indicator
  // const [/*patient_name*/, setPatient_name] = useState(false); // Loading indicator
  // const [/*address*/, setAddress] = useState(false); // Loading indicator
  const [bill_numbers, setBill_numbers] = useState([]); // Loading indicator

  // Fetch suggestions when the planName changes
  useEffect(() => {
    const fetchPlans = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${VINOOTNEW}/api/treatment-plan`);
        const activePlans = response.data.filter(
          (plan) => plan.status === "active"
        );
        setSuggestions(activePlans);
        setFilteredSuggestions(activePlans);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch plans", error);
        setIsLoading(false);
      }
    };
    fetchPlans();
  }, []);

  // Filter suggestions based on input value
  useEffect(() => {
    const filterSuggestions = () => {
      if (typeof planName !== "string" || planName.trim() === "") {
        setFilteredSuggestions(suggestions); // Show all suggestions if input is empty or not a string
      } else {
        const lowerCasePlanName = planName.toLowerCase();
        const filtered = suggestions.filter((plan) =>
          plan.plan_name.toLowerCase().includes(lowerCasePlanName)
        );
        setFilteredSuggestions(filtered);
      }
    };
    filterSuggestions();
  }, [planName, suggestions]);

  // Ref for suggestion box
  const suggestionBoxRef = useRef(null);

  // Function to handle click outside suggestion box
 useEffect(() => {
  function handleClickOutside(event) {
    if (
      suggestionBoxRef3.current &&
      !suggestionBoxRef3.current.contains(event.target) &&
      inputRef1.current &&
      !inputRef1.current.contains(event.target)
    ) {
      setFocusedInput(null); // Close the dropdown
    }
  }

  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, [suggestionBoxRef3, inputRef1]);


  const handlePlanChange = (e) => {
    const PlanName = e.target.value;
    setPlanName(PlanName); // Update selected plan
    setFocusedInput("plan");
  };

  const handlePlanSelection = (suggestion) => {
    const selectedPlan = suggestions.find(
      (plan) => plan.plan_name === suggestion
    );

    if (selectedPlan) {
      setSelectedPlan(selectedPlan); // Set the entire selected plan object
      setPlanName(selectedPlan.plan_name); // Set the planName
      setFocusedInput(null); // Hide suggestion list when a suggestion is clicked
    }
  };

  
  

  //-------------------Bill Numbers Fetching ----------------
  const [billingNumber, setBillingNumber] = useState("");
  
  //const generateBillNumber = (bill_numbers) => {
  //  if (bill_numbers.length === 0) {
  //    // If there are no existing patients, start with the first ID
  //    return "BIL001";
  //  } else {
  //    // Extract the numeric part of the last patient ID
  //    const lastIDNumeric = parseInt(
  //      bill_numbers[bill_numbers.length - 1].bill_number.substr(3),
  //      10
  //    );
  //    // Increment the numeric part by 1
  //    const nextIDNumeric = lastIDNumeric + 1;
  //    // Pad the numeric part with zeros to maintain the format "PAT001"
  //    const nextID = "BIL" + nextIDNumeric.toString().padStart(3, "0");
  //    return nextID;
  //  }
  //};

  useEffect(() => {
    // Calculate total amount from plans
    const totalPlanAmount = plans.reduce((total, plan) => {
      const planAmount = parseFloat(plan.TotalAmount) || 0; // default to 0 if TotalAmount is missing or invalid
      return total + planAmount;
    }, 0);
    
    // Calculate total amount from selected medicines
    const totalMedicinePrice = selectedMedicines.reduce((acc, medicine) => {
      const medicinePrice = parseFloat(medicine.totalPrice) || 0; // default to 0 if totalPrice is missing or invalid
      return acc + medicinePrice;
    }, 0);
    
    // Safeguard amountPaid
    const validAmountPaid = parseFloat(amountPaid) || 0; // Ensure amountPaid is always a valid number
    
    // Calculate the combined total amount
    const totalAmount = totalPlanAmount + totalMedicinePrice;
    // console.log("Total Amount (Plans + Medicines):", totalAmount);
  
    // Calculate remaining amount
    const remaining = totalAmount - validAmountPaid;
    // console.log("Remaining Amount:", remaining);
  
    // Set remaining amount, avoid negative values
    setRemainingAmount(Math.max(remaining, 0)); // Avoid negative remaining amounts
    
    // Update payment status
    if (validAmountPaid >= totalAmount) {
      setStatus("Paid");
    } else {
      setStatus("Unpaid");
    }
  }, [amountPaid, plans, selectedMedicines]);
  
  const generateBillNumber = (bill_numbers) => {
    const franchiseID = localStorage.getItem("franchiseID") || "FR"; // Default to "FR" if not found
    const prefix = `${franchiseID} - BIL`; // Prefix including franchiseID
  
    if (bill_numbers.length === 0) {
      // If there are no existing bill numbers, start with the first ID
      return `${prefix}001`;
    } else {
      // Extract the numeric part of the last bill number by removing the prefix
      const lastBill = bill_numbers[bill_numbers.length - 1].bill_number;
      const lastIDNumeric = parseInt(lastBill.replace(prefix, ''), 10);
      
      // If the parsing failed, start with 1
      if (isNaN(lastIDNumeric)) {
        return `${prefix}001`;
      }
      
      // Increment the numeric part by 1
      const nextIDNumeric = lastIDNumeric + 1;
      
      // Pad the numeric part with zeros to maintain the format "001"
      const nextID = prefix + nextIDNumeric.toString().padStart(3, "0");
      return nextID;
    }
  };














  // const [resetKey, setResetKey] = useState(0);

  // const [installments, setInstallments] = useState([]);
  const saveData = async () => {
    const newBillNumber = generateBillNumber(bill_numbers);
    setBillingNumber(newBillNumber);
  
    try {
      const createdBy = localStorage.getItem("userId");
      const franchiseName = localStorage.getItem("franchisename");
      const franchiseID = localStorage.getItem("franchiseID");
      const currentDate = new Date().toISOString().split("T")[0];
  
      // Calculate the total amount
      const totalAmount = (
        totalMedicinePrice+totalPlanAmount).toFixed(2);
  
      const remaining = (totalAmount - parseFloat(amountPaid)).toFixed(2);
  
      // Prepare data to be sent to the backend
      const dataToSend = {
        bill_number: newBillNumber,
        doctor: selectedDoctor,
        therapist: selectedTherapist,
        // Ensure the plans array includes the updated days and total amounts
        plans: plans.map(plan => ({
          ...plan,
          days: plan.editableDays || plan.days, // Use the updated days if available
          TotalAmount: plan.TotalAmount, // Send the updated TotalAmount
        })),
        medicines: selectedMedicines || [], // Save the entire array of medicines
        paymentType: paymentType,
        amountPaid: amountPaid,
        status: amountPaid >= totalAmount ? "Paid" : "Unpaid",
        createdBy: createdBy,
        franchiseName: franchiseName,
        franchiseID: franchiseID,
        remainingAmount: remaining,
        totalAmount: totalAmount,
        mobile_number: phoneInput ,
        patient_id: selectedNumber.patient_id  ,
        patient_name: selectedNumber.patient_name ,
        address: selectedNumber.address ,
        currentDate: currentDate,
      };
  
      // Send the data to the backend API endpoint for saving
      await axios.post(`${VINOOTNEW}/api/billing`, dataToSend);
      // console.log("Billing saved successfully!");
  
      // Reset form fields after successful save
      // setPlanName("");
      // setPaymentType("");
      // setAmountPaid(0);
      // setSelectedDoctor("");
      // setPhoneInput("");
      // setPlans([
      //   {
      //     plan_name: " ",
      //     GST: " ",
      //     GSTamount: " ",
      //     days: "0",
      //     price: " ",
      //     TotalAmount: " "
      //   }
      // ]); // Clear the plans array
      // setSelectedMedicines([
      //   {
      //     plan_name: "",
      //     price: "",
      //     quantity:"",
      //     status: ""
      //   }
      // ]); // Clear the medicines array
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };
  
  
  useEffect(() => {
    fetchBillNumbers();
  }, []);

  const fetchBillNumbers = async () => {
    try {
      const frid = localStorage.getItem("franchiseID");

      if (frid) {
        const response = await axios.get(`${VINOOTNEW}/api/billing${frid}`);
        setBill_numbers(response.data);
      } else {
        console.error("FranchiseID not found in localStorage");
      }
    } catch (error) {
      console.error("Error fetching billNUmbers:", error);
    }
  };

  // Initialize form data including the patient ID
  useEffect(() => {
    const newBillNumber = generateBillNumber(bill_numbers);
    setBillingNumber(newBillNumber);
  }, [bill_numbers]);

  // --------------------

  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const fetchCurrentDate = () => {
      const date = new Date();
      // Format the date as DD/MM/YYYY
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      setCurrentDate(formattedDate);
    };
  
    fetchCurrentDate();
  }, []);
  
  // const handleSaveAndPrint = () => {
  //   saveData(); // Call the saveData function to save the data
  //   printDetails(); // Call the printDetails function to print the data
  // };


  const [/*franchises*/, setFranchises] = useState([]);
  const [franchiseAddress, setFranchiseAddress] = useState("");

  useEffect(() => {
    const fetchFranchises = async () => {
      try {
        const response = await axios.get(`${VINOOTNEW}/api/franchise`);
        setFranchises(response.data.franchises);

        // console.log("Franchises fetched successfully:", response.data.franchises);

        const franchiseID = localStorage.getItem("franchiseID");

        // console.log(`Looking for franchise with ID: ${franchiseID}`);

        // Find the specific franchise by ID
        const selectedFranchise = response.data.franchises.find(
          (franchise) => franchise.franchiseID === franchiseID
        );

        if (selectedFranchise) {
          // console.log("Selected Franchise Data:", selectedFranchise);
          setFranchiseAddress(selectedFranchise.address);
        } else {
          console.warn(`No franchise found with ID: ${franchiseID}`);
        }
      } catch (error) {
        console.error("Error fetching franchises:", error);
      }
    };

    fetchFranchises();
  }, []);


  const printDetails = () => {

    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(" "); // Clear any existing content
    printWindow.document.close();
  
  
    const htmlContent = `
      <html>
        <head>
          <title>Vande Wellness</title>
              <link rel="icon" href="https://static.wixstatic.com/media/87b37c_4f087e673951454d870325c82063ae31~mv2.png/v1/fill/w_627,h_600,al_c/87b37c_4f087e673951454d870325c82063ae31~mv2.png" />

          // <img src= "${VW2}" alt="vande" className="imgvan"/>
        

          <style>
          //  .imgvan{
          //  width:98px;
          //  height:34px;
          //  opacity:5!impartant; 
          // }
          body {
          visibility : hidden
           }
        @media print {
          /* Force page breaks where necessary */
          body {
              visibility: visible!important;
              position: absolute;
              left:0;
              top: 0;
              width: 97%;
              height: 100%;
              padding : 3% 0 3% 5px;
            }
            @page {
              margin: 0;
              size: A4;
              /* Change this to landscape for a landscape layout */
              /* orientation: landscape; */
              /* For page breaks */
              @bottom-center {
                content: "Page " counter(page) " of " counter(pages);
              }
             
            .pagebreak {
              page-break-before: always;
            }
            }
  
            .no-print {
              display: none;
            }
  
            table {
              border-collapse: collapse;
              width: 100%;
            }
  
            th, td {
              border: 1px solid #dddddd;
              text-align: left;
              padding: 8px;
            }
  
            th {
              background-color: #f2f2f2;
            }
  
            .print-logo img {
              width: 20%;
              justify-content: center;
              align-items: center;
              // margin-left: -5px;
            }
            .branch-address {
              position: absolute;
              // margin-bottom:2px;
              // top: 0;
              right: 0;
              margin-right:25px;
              text-align: right;
              max-width: 250px; /* Adjust this width as needed */
              word-wrap: break-word; /* Ensure long addresses wrap within the container */
            }

            .billing-details {
              clear: both;
              margin-top: 20px;
            }
            .branch-h3{
              margin-top: -20px
              }
            .sign{
            text-align: right;
            margin-right: 10%;
            padding: 2%;
            }
          </style>
        </head>
        <body>
        <div id="header">
         <div class="branch-address">
          <h2>Registered Address</h2>
          <p class="branch-h3">No 37 / D. kannada sahithya parishath road, Vijayanagar 1<sup>st</sup> stage, Mysore, Karnataka - 570017.</p>
          <h2>Branch Address</h2>
          <p class="branch-h3">${franchiseAddress}</p>
          </div>
          <div class="print-logo">
            <img src="https://static.wixstatic.com/media/87b37c_4f087e673951454d870325c82063ae31~mv2.png/v1/fill/w_627,h_600,al_c/87b37c_4f087e673951454d870325c82063ae31~mv2.png" alt="logo" />
          </div>
         
          </div>
          <h1 class="billing-details">Billing Details</h1>
          <table>
            
             <tr>
              <td>Bill Number</td>
              <td>${billingNumber}</td>
            </tr>
            <tr>
              <td>Date</td>
              <td>${currentDate}</td>
            </tr>
           <tr>
            <td>Mobile Number</td>
            <td>${selectedNumber ? selectedNumber.mobile_number : ""}</td>
          </tr>
          <tr>
            <td>Patient ID</td>
            <td>${selectedNumber ? selectedNumber.patient_id : ""}</td>
          </tr>
          <tr>
            <td>Patient Name</td>
            <td>${selectedNumber ? selectedNumber.patient_name : ""}</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>${selectedNumber ? selectedNumber.address : ""}</td>
          </tr>
          <tr>
            <td>Branch Name</td>
            <td>${localStorage.getItem("franchisename")}</td>
          </tr>
          <tr>
            <td>Branch ID</td>
            <td>${localStorage.getItem("franchiseID")}</td>
          </tr>
         
          <tr>
          <td> ${selectedDoctor ? "Doctor" : "Therapist"}</td>
          <td>${selectedDoctor || selectedTherapist || "N/A"}</td>
          </tr>
          </table>
        <h2>Services</h2>
          <table>
            <thead>
              <tr>
                <th>Service Name</th>
                <th>Days</th>
                <th>Price</th>
                <th>GST</th>
                <th>GST Amount</th>
                <th>Total Amount</th>
              </tr>
            </thead>
           
 <tbody>
  ${plans && plans.length > 0 && plans.some(plan => plan.plan_name.trim() !== "") // Check if there's at least one valid plan with a non-empty name
    ? plans
        .map((plan) => `
          <tr>
            <td>${plan.plan_name && plan.plan_name.trim() !== "" ? plan.plan_name : "nil"}</td>
            <td>${plan.editableDays || plan.days || "nil"}</td>
            <td>${plan.editablePrice || plan.price || "nil"}</td>
            <td>${plan.GST || "nil"}</td>
            <td>${plan.GSTamount || "nil"}</td>
            <td>${plan.TotalAmount || plan.editableTotalAmount || "nil"}</td>
          </tr>
        `)
        .join("")
    : `
      <tr>
        <td>nil</td>
        <td>nil</td>
        <td>nil</td>
        <td>nil</td>
        <td>nil</td>
        <td>nil</td>
      </tr>
    `}
</tbody>

          </table>
        
        
  
          <h2>Medicines</h2>
          <table>
            <thead>
              <tr>
                <th>Medicine Name</th>
                <th>Quantity</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              ${selectedMedicines
                .map(
                  (medicine) => `
                <tr>
                  <td>${medicine.plan_name || "nil"}</td>
                  <td>${medicine.quantity || "nil"}</td>
                  <td>${medicine.totalPrice || medicine.price || "nil"}</td>
                  
                </tr>
              `
                )
                .join("")}
            </tbody>
          </table>
          <!-- Payment details here -->
          <h2>Payment Details</h2>
          <table>
          <tr>
            <td><b>Payment Type</h></td>
            <td>${paymentType || "0"}</td>
          </tr>
         
          <tr>
            <td><b>Payment Status</b></td>
            <td>${status || "N/A"}</td>
          </tr>
           <tr>
            <td><b>Total Amount for All Services</b></td>
            <td>Rs. ${
              plans.reduce(
                (total, plan) => total + parseFloat(plan.TotalAmount || 0),
                0
              ) || "0"
            }</td>
          </tr>
          <tr>
            <td><b>Total Amount for All Medicines</b></td>
            <td>Rs. ${
              selectedMedicines.reduce(
                (acc, medicine) => acc + (medicine.totalPrice || 0),
                0
              ) || "0"
            }</td>
          </tr>
          <tr>
            <td><b>Total Amount (Services + Medicines)</b></td>
            <td>Rs. ${
              plans.reduce(
                (total, plan) => total + parseFloat(plan.TotalAmount || 0),
                0
              ) +
                selectedMedicines.reduce(
                  (acc, medicine) => acc + (medicine.totalPrice || 0),
                  0
                ) || "0"
            }</td>
          </tr>
           <tr>
            <td><b>Amount Paid</b></td>
            <td>Rs. ${amountPaid || "0"}</td>
          </tr>
          <tr>
            <td><b>Remaining Amount to be Paid</b></td>
            <td>Rs. ${remaining || "0"}</td>
          </tr>
          </table>
        <p>*Terms & Conditions Apply </p>

        <h3 class="sign">Signature</h3>

          
          
        </body>
      </html>
    `;
  
     printWindow.document.open();
    printWindow.document.write(htmlContent);
    printWindow.document.close();

    printWindow.print();
    printWindow.close();
    
  };
  

  
  const handleSaveAndPrint = async (e) => {
    e.preventDefault(); // Prevent form submission
    // Check if mobile number is selected
    if (!selectedNumber || !selectedNumber.mobile_number) {
      // Display error toast if mobile number is not selected
      toast.error("Please select a mobile number.");
      return; // Exit the function
    }

    // Check if plan is selected
    // if (!selectedPlan) {
    //   // Display error toast if plan is not selected
    //   toast.error("Please select a plan.");
    //   return; // Exit the function
    // }

    // Check if either doctor or therapist is selected
    if (!selectedDoctor && !selectedTherapist) {
      // Display error toast if neither doctor nor therapist is selected
      toast.error("Please select either a doctor or a therapist.");
      return; // Exit the function
    }

    // Check if amount paid is greater than 0
    if (amountPaid <= 0) {
      // If amount paid is 0, paymentType is not required
      setPaymentType(""); // Reset paymentType if amountPaid is 0
    }
    if (amountPaid > 0 && !paymentType) {
      toast.error("please select payment type.");
      return; // Exit the function
    }

    try {
      await saveData(); // Call the saveData function to save the data

      // If saveData succeeds, display success toast and then print the details
      toast.success("Data saved successfully!", {
        autoClose: 1500,
        onClose: () => {
          printDetails(); // Call the printDetails function to print the data
          window.location.reload();
        },
      });
    } catch (error) {
      console.error("Error saving data:", error);
      // If saveData fails, display error toast
      toast.error("Error saving data. Please try again.");
    }
  };

  // 1. State for Plans

 

  const addPlan = () => {
    if (!selectedPlan) {
        toast.error("Please select a Service first.");
        return;
    }

    // Calculate the price per day
    const pricePerDay = selectedPlan.price / selectedPlan.days;

    // Initialize the plan with default values
    const planWithDefaultDays = {
        ...selectedPlan,
        editableDays: selectedPlan.days, // Add editableDays
        editablePrice: selectedPlan.days * pricePerDay, // Adjusted price per day
        GSTamount: ((selectedPlan.days * pricePerDay) * selectedPlan.GST / 100).toFixed(2), // Initial GST amount
        TotalAmount: ((selectedPlan.days * pricePerDay) + parseFloat((selectedPlan.days * pricePerDay) * selectedPlan.GST / 100)).toFixed(2), // Initial total amount
    };

    if (plans.length === 1 && plans[0].plan_name.trim() === "") {
        setPlans([planWithDefaultDays]);
    } else {
        setPlans(prevPlans => [...prevPlans, planWithDefaultDays]);
    }

    // Reset the selectedPlan state to clear the input
    setSelectedPlan(""); // Clear the input field after adding the plan
};


// automatically add treatments from doctor module
useEffect(() => {
  const fetchPlans = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${VINOOTNEW}/api/treatment-plan`);
      const activePlans = response.data.filter((plan) => plan.status === "active");
      
      setSuggestions(activePlans);
      setFilteredSuggestions(activePlans);
      

      if (billingData && billingData.treatments && billingData.treatments.length > 0) {
        const autoAddedPlans = billingData.treatments.map((treatment) => {
          const matchedPlan = activePlans.find(plan => plan.plan_name === treatment.treatmentPlan);

          if (matchedPlan) {
            const pricePerDay = matchedPlan.price / matchedPlan.days;
            const initialPrice = treatment.treatmentDays * pricePerDay;
            const GSTamount = (initialPrice * matchedPlan.GST / 100).toFixed(2);
            const TotalAmount = (initialPrice + parseFloat(GSTamount)).toFixed(2);
            // console.log("Fetched treatment:", treatment);
            // console.log("Matched plan:", matchedPlan);


            return {
              plan_name: matchedPlan.plan_name,
              days: treatment.treatmentDays, // Original days value from useLocation
              editableDays: treatment.treatmentDays,
              
              // Recalculate price based on treatment days
              price: matchedPlan.price * treatment.treatmentDays / matchedPlan.days,
              editablePrice: initialPrice.toFixed(2),

              GST: matchedPlan.GST,
              GSTamount: GSTamount,
              TotalAmount: TotalAmount,
            };
          }
          return null;
        }).filter(plan => plan !== null);
        

        // Replace the empty row or add new plans if plans already exist
        setPlans(prevPlans => {
          const existingPlanNames = prevPlans.map(plan => plan.plan_name);

          if (prevPlans.length === 1 && prevPlans[0].plan_name.trim() === "") {
            return [...autoAddedPlans]; // Replace the empty row with auto-added plans
          } else {
            const newPlans = autoAddedPlans.filter(plan => !existingPlanNames.includes(plan.plan_name));
            return [...prevPlans, ...newPlans]; // Add new plans, avoiding duplication
          }
        });
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch plans", error);
      setIsLoading(false);
    }
  };

  fetchPlans();
}, [billingData]);



const handleDaysChange = (e, index) => {
  const updatedDays = e.target.value;
  const updatedPlans = [...plans];

  if (!isNaN(updatedDays) && updatedDays !== "") {
    const daysAsNumber = parseFloat(updatedDays);

    // Ensure the correct per-day price is used
    const pricePerDay = updatedPlans[index].price / updatedPlans[index].days;

    updatedPlans[index].editableDays = daysAsNumber;
    const updatedPrice = daysAsNumber * pricePerDay; // Multiply by per-day cost
    updatedPlans[index].editablePrice = updatedPrice.toFixed(2);

    const updatedGSTAmount = (updatedPrice * updatedPlans[index].GST / 100).toFixed(2);
    updatedPlans[index].GSTamount = updatedGSTAmount;
    updatedPlans[index].TotalAmount = (updatedPrice + parseFloat(updatedGSTAmount)).toFixed(2);
  } else {
    updatedPlans[index].editableDays = "";
    updatedPlans[index].editablePrice = "";
    updatedPlans[index].GSTamount = "";
    updatedPlans[index].TotalAmount = "";
  }

  setPlans(updatedPlans);

  // Calculate total amount from updated plans
  const totalPlanAmount = updatedPlans.reduce((total, plan) => {
    // console.log("Plan TotalAmount after update:", plan.TotalAmount);
    return total + parseFloat(plan.TotalAmount || 0);
  }, 0);

  // Calculate total amount from selected medicines
  const totalMedicinePrice = selectedMedicines.reduce((acc, medicine) => {
    // console.log("Medicine TotalPrice after update:", medicine.totalPrice);
    return acc + (medicine.totalPrice || 0);
  }, 0);

  // Calculate the combined total amount
  const totalAmount = totalPlanAmount + totalMedicinePrice;
  // console.log("Total Amount after Days Change (Plans + Medicines):", totalAmount);

  // Set remaining amount
  setRemainingAmount(totalAmount - parseFloat(amountPaid));
};









 
  useEffect(() => {
    const fetchMedicines = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${VINOOTNEW}/api/manage-medicine`);

            const activeMedicines = response.data.filter(medicine => medicine.status === 'active');

            setMedicines(activeMedicines);
            setFilteredMedicineSuggestions(activeMedicines);
        } catch (error) {
            console.error("Failed to fetch medicines", error);
        } finally {
            setIsLoading(false);
        }
    };
    fetchMedicines();
}, []);



  useEffect(() => {
      const filterSuggestions = () => {
          if (!medicineName.trim()) {
              setFilteredMedicineSuggestions(medicines);
          } else {
              const filtered = medicines.filter((medicine) =>
                  medicine.plan_name.toLowerCase().includes(medicineName.toLowerCase())
              );
              setFilteredMedicineSuggestions(filtered);
          }
      };
      filterSuggestions();
  }, [medicineName, medicines]);

  const handleMedicineSelection = (selectedMedicine) => {
      setMedicineName(selectedMedicine.plan_name);
      setFocusedMedicineInput(null);
  };

 
  const addMedicine = () => {
    if (!medicineName) {
      toast.error("Please select a medicine first.");
      return;
    }
    const selectedMedicine = medicines.find(medicine => medicine.plan_name === medicineName);
    // console.log("Selected Medicine:", selectedMedicine);
    
    
  
    if (!selectedMedicine) {
      toast.error("Invalid medicine selection.");
      return;
    }
  
    // Prepare the medicine object with quantity and totalPrice
    const medicineToAdd = { ...selectedMedicine, quantity: 1, totalPrice: selectedMedicine.price };
  
    // If the selectedMedicines array contains only the default entry, replace it
    if (selectedMedicines.length === 1 && selectedMedicines[0].plan_name.trim() === "") {
      setSelectedMedicines([medicineToAdd]); // Replace with the selected medicine
    } else {
      setSelectedMedicines(prevSelectedMedicines => [...prevSelectedMedicines, medicineToAdd]); // Add the selected medicine to the array
    }
  
    setMedicineName(""); // Clear the medicine input
  };
  
// Function to handle quantity change
// const handleQuantityChange = (index, newQuantity) => {
//   setSelectedMedicines(prev => prev.map((medicine, i) => {
//       if (i === index) {
//           const updatedPrice = medicine.price * newQuantity;
//           return { ...medicine, quantity: newQuantity, totalPrice: updatedPrice };
//       }
//       return medicine;
//   }));
// };\
// Function to add a blank medicine row for manual input
const addMedicineManually = () => {
  const emptyMedicine = { plan_name: "", quantity: 1, totalPrice: 0 }; // Initialize with empty fields
  
  setSelectedMedicines(prevSelectedMedicines => {
    // Check if the first row is an empty one (e.g., no plan_name)
    if (prevSelectedMedicines.length === 1 && prevSelectedMedicines[0].plan_name.trim() === "") {
      return [emptyMedicine]; // Replace the empty row
    } else {
      return [...prevSelectedMedicines, emptyMedicine]; // Add a new row
    }
  });
};


// Function to handle manual medicine name change
const handleMedicineNameChange = (index, newName) => {
  setSelectedMedicines(prev => prev.map((medicine, i) => {
      if (i === index) {
          return { ...medicine, plan_name: newName }; // Update the plan_name with new input
      }
      return medicine;
  }));
};

// Function to handle quantity change
const handleQuantityChange = (index, newQuantity) => {
  setSelectedMedicines(prev => prev.map((medicine, i) => {
      if (i === index) {
          const updatedPrice = medicine.price ? medicine.price * newQuantity : medicine.totalPrice; // Calculate updated price
          return { ...medicine, quantity: newQuantity, totalPrice: updatedPrice }; // Update quantity and price
      }
      return medicine;
  }));
};

// Function to handle manual price input
const handlePriceChange = (index, newPrice) => {
  setSelectedMedicines(prev => prev.map((medicine, i) => {
      if (i === index) {
          return { ...medicine, totalPrice: newPrice }; // Update total price
      }
      return medicine;
  }));
};


  const handleClickOutside1 = (event) => {
    if (
          medicineSuggestionRef.current &&
          !medicineSuggestionRef.current.contains(event.target) &&
          inputRef.current &&
          !inputRef.current.contains(event.target)
      ) {
          setFocusedMedicineInput(null);
      }
  };

  useEffect(() => {
      document.addEventListener("click", handleClickOutside1);
      return () => {
          document.removeEventListener("click", handleClickOutside1);
      };
  }, []);

//   const calculateTotalPrice = () => {
//     return selectedMedicines.reduce((acc, medicine) => acc + (medicine.totalPrice || 0), 0);
// };

const removePlan = (indexToRemove) => {
  // Create a new array excluding the plan at the specified index
  const updatedPlans = plans.filter((_, index) => index !== indexToRemove);

  setPlans(updatedPlans); // Update the plans state with the new array

  // Calculate total amount from updated plans
  const totalPlanAmount = updatedPlans.reduce((total, plan) => {
    // console.log("Plan TotalAmount after removal:", plan.TotalAmount);
    return total + parseFloat(plan.TotalAmount || 0);
  }, 0);

  // Calculate total amount from selected medicines
  const totalMedicinePrice = selectedMedicines.reduce((acc, medicine) => {
    // console.log("Medicine TotalPrice after removal:", medicine.totalPrice);
    return acc + (medicine.totalPrice || 0);
  }, 0);

  // Calculate the combined total amount
  const totalAmount = totalPlanAmount + totalMedicinePrice;
  // console.log("Total Amount after Plan Removal (Plans + Medicines):", totalAmount);

  
  const amountPaidValue = parseFloat(amountPaid) || 0;
  const remaining = totalAmount - amountPaidValue;

  // Update the remaining amount
  setRemainingAmount(Math.max(remaining, 0));

  // Update payment status
  if (amountPaidValue >= totalAmount) {
    setStatus("Paid");
  } else {
    setStatus("Unpaid");
  }
};


const removeMedicine = (indexToRemove) => {
  // Create a new array excluding the medicine at the specified index
  const updatedMedicines = selectedMedicines.filter((_, index) => index !== indexToRemove);

  setSelectedMedicines(updatedMedicines); // Update the selectedMedicines state with the new array

  // Calculate the total amount from updated plans
  const totalPlanAmount = plans.reduce((total, plan) => total + parseFloat(plan.TotalAmount || 0), 0);

  // Calculate the total amount from updated medicines
  const totalMedicinePrice = updatedMedicines.reduce((acc, medicine) => acc + (medicine.totalPrice || 0), 0);

  // Calculate the combined total amount
  const totalAmount = totalPlanAmount + totalMedicinePrice;

  // Calculate the remaining amount
  const amountPaidValue = parseFloat(amountPaid) || 0;
  const remaining = totalAmount - amountPaidValue;

  // Update the remaining amount
  setRemainingAmount(Math.max(remaining, 0));

  // Update payment status
  if (amountPaidValue >= totalAmount) {
    setStatus("Paid");
  } else {
    setStatus("Unpaid");
  }
};


// automatically add medicines from doctor module
useEffect(() => {
  if (billingData && billingData.medicines && billingData.medicines.length > 0) {
    const fetchAndSetMedicines = async () => {
      try {
        const response = await axios.get(`${VINOOTNEW}/api/manage-medicine`);
        const activeMedicines = response.data.filter(medicine => medicine.status === 'active');

        // Map the medicines from billingData with the fetched data
        const medicinesToAdd = billingData.medicines.map((medicine) => {
          const matchedMedicine = activeMedicines.find(m => m.plan_name === medicine.medicineName);

          if (matchedMedicine) {
            return {
              ...matchedMedicine,
              quantity: medicine.quantity, // Use quantity from billingData
              totalPrice: matchedMedicine.price * medicine.quantity, // Calculate total price based on quantity
            };
          } else {
            // If the medicine is not found in activeMedicines, assume it's manually added
            return {
              plan_name: medicine.medicineName,  // Use the medicine name from billingData
              quantity: medicine.quantity,       // Use quantity from billingData
              totalPrice: medicine.price  * medicine.quantity|| 0, // Use totalPrice from billingData or default to 0
            };
          }
        });

        setSelectedMedicines(medicinesToAdd);
      } catch (error) {
        console.error("Failed to fetch medicines", error);
      }
    };

    fetchAndSetMedicines();
  }
}, [billingData]);


const totalPlanAmount = plans.reduce((total, plan) => {
  const planAmount = parseFloat(plan.TotalAmount) || 0; // default to 0 if TotalAmount is missing or invalid
  return total + planAmount;
}, 0);

const totalMedicinePrice = selectedMedicines.reduce((acc, medicine) => {
  const medicinePrice = parseFloat(medicine.totalPrice) || 0; // default to 0 if totalPrice is missing or invalid
  // console.log("Medicine TotalPrice:", medicinePrice);
  return acc + medicinePrice;
}, 0);



// console.log("Plans array:", plans); // Log the plans array

  const designate = localStorage.getItem("designation");
  // console.log(designate)


  return (
    <div className="billing-total">
      <div>
      {designate === "Doctor" ? <DoctorSidebar /> : <ReceptionSidebar />}

        <ToastContainer />
      </div>
      <div className="billing-right">
        <article>Billing</article>
        <div className="container-fetch-mbl">
          <div>
            <label>
              <span>Date</span>
              <input type="text" value={currentDate} disabled />
            </label>
            <label>
              <span>Bill Number</span>
              <input
                type="text"
                name="bill_number"
                value={billingNumber}
                readOnly
              />
            </label>

            <label >
              <span>
                Enter Mobile Number <span style={{ color: "red" }}>*</span>
              </span>
              <input
                type="text"
                name="planName"
                value={phoneInput}
                onChange={handlePlanChange1}
                onFocus={() => setFocusedInput1("plan")}
                placeholder="Enter mobile number"
                required
              />
              {patientError ? (
                <div style={{ color: "red", fontSize: "0.8rem" }}>
                  {patientError}
                </div>
              ) : (
                isLoading && <div className="loading-fetch-mbl">Loading...</div>
              )}
              {focusedInput1 === "number" &&
                filteredSuggestions1.length > 0 && (
                  <div
                    ref={suggestionBoxRef}
                    className="suggestions-fetch-mblsss"
                    style={{
                      position: "absolute",
                      backgroundColor: "white",
                      border: "1px solid #ccc",
                      width: "20.5%",
                      marginTop: "55px",
                      height: "15vh",
                      overflowY: "auto",
                      zIndex:1000
                    }}>
                    {filteredSuggestions1.map((suggestion) => (
                      <p
                        key={suggestion._id}
                        className="suggestion-item-fetch-mbl"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handlePlanSelection1(suggestion.mobile_number)
                        }>
                        {suggestion.mobile_number}
                      </p>
                    ))}
                  </div>
                )}
            </label>
            {additionalField && (
              <label>
                <span>Select Patient Name</span>
                <input
                  type="text"
                  value={patientName}
                  onChange={(e) => setPatientName(e.target.value)}
                  onFocus={() => setFocusedInput2("patientName")}
                  placeholder="Select patient name"
                  required
                />
                {focusedInput2 === "patientName" && (
                  <div
                    ref={suggestionBoxRef}
                    className="suggestions-fetch-mbl"
                    style={{
                      position: "absolute",
                      backgroundColor: "white",
                      border: "1px solid #ccc",
                      width: "20.5%",
                      marginTop: "55px",
                      height: "13vh",
                      overflowY: "auto",
                      zIndex:1000

                    }}>
                    {patientSuggestions
                      .filter((patient) =>
                        patient.patient_name
                          .toLowerCase()
                          .includes(patientName.toLowerCase())
                      )
                      .map((patient) => (
                        <p
                          key={patient.patient_id}
                          className="suggestion-item-fetch-mbl"
                          style={{ cursor: "pointer" }}
                          onClick={() => handlePatientNameSelection(patient)}>
                          {patient.patient_name}
                        </p>
                      ))}
                  </div>
                )}
              </label>
            )}
            {!additionalField || selectedNumber ? (
                <label className="pNam">
                  <span>Patient Name</span>
                  <input
                    type="text"
                    value={selectedNumber ? selectedNumber.patient_name : ""}
                    disabled
                  />
                </label>
              ) : null}
           
            {/* <label className="pNam">
              <span>Patient Name</span>
              <input
                type="text"
                value={selectedNumber ? selectedNumber.patient_name : ""}
                disabled
              />
            </label> */}
            
            {/* <label>
              <span>
                Enter Mobile Number <span style={{ color: "red" }}>*</span>
              </span>
              <input
                type="text"
                value={phoneInput}
                onChange={handlePlanChange1}
                placeholder="Enter mobile number"
                required
              />
            </label>

            <label className="pNam">
              <span>Patient Name</span>
              <input
                type="text"
                value={patient_name}
                readOnly 
              />
            </label> */}

            
          </div>

          <div>
            <label>
              <span>Branch Name</span>
              <input
                type="text"
                value={localStorage.getItem("franchisename")}
                disabled
              />
            </label>
            <label>
              <span> Branch ID</span>
              <input
                type="text"
                value={localStorage.getItem("franchiseID")}
                disabled
              />
            </label>
            <label className="patientID">
              <span>Patient ID</span>
              <input
                type="text"
                value={selectedNumber ? selectedNumber.patient_id : ""}
                disabled
              />
            </label>
            <label className="Address">
              <span>Patient Address</span>
              <input
                type="text"
                value={selectedNumber ? selectedNumber.address : ""}
                disabled
              />
            </label>
            {/* <label >
              <span>Patient ID</span>
              <input
                type="text"
                value={patient_id}
                readOnly
              />
            </label>

            <label >
              <span>Patient Address</span>
              <input
                type="text"
                value={address}
                readOnly 
              />
            </label> */}
          </div>
        </div>

        <div className="billig-below">
          {/* ----------------------doctor selection ------------------ */}
          <div className="billing-below-11">
            <table className="plan-table">
              <thead>
                <tr>
                  <th>
                    Select Doctor / Therapist{" "}
                    <span style={{ color: "red" }}>*</span>
                  </th>
                  <th>
                    Service Name <span style={{ color: "red" }}>*</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <select
                      value={selectedDoctor}
                      required
                      onChange={handleDoctorChange}
                      disabled={selectedTherapist !== ""}>
                      <option value="">Select Doctor</option>
                      {doctors.map((doctor) => (
                        <option key={doctor._id} value={doctor.fullname}>
                          {doctor.fullname}
                        </option>
                      ))}
                    </select>
                    <select
                      value={selectedTherapist}
                      required
                      onChange={handleTherapistChange}
                      disabled={selectedDoctor !== ""}>
                      <option value="">Select Therapist</option>
                      {therapists.map((therapist) => (
                        <option key={therapist._id} value={therapist.fullname}>
                          {therapist.fullname}
                        </option>
                      ))}
                    </select>
                  </td>

                  <td>
                    <input
                      className="bill-tplanselecet"
                      type="text"
                      name="planName"
                      value={planName}
                      required
                      onChange={handlePlanChange} // Update the planName
                      onFocus={() => setFocusedInput("plan")}
                      ref={inputRef1}
                      placeholder="Enter the service"
                    />
                    <button 
                        type="button" 
                        onClick={() => {
                          addPlan();
                          setPlanName(""); // Clear the input field after adding the plan
                        }} 
                        className="franchise-btn"
                      >
                        Add
                      </button>
                    {isLoading && <div>Loading...</div>}
                    {focusedInput === "plan" &&
                      filteredSuggestions.length > 0 && (
                        <div
                        ref={suggestionBoxRef3} // Attach the ref here

                          className="overflow-scroll"
                          style={{
                            position: "absolute",
                            backgroundColor: "white",
                            border: "1px solid #ccc",
                            width: "25.6%",
                            height: "15vh",
                            marginLeft:"30px",
                            overflowY: "auto",
                            zIndex:1000
                          }}>
                          {filteredSuggestions.map((suggestion) => (
                            <p
                              key={suggestion._id}
                              className="suggestion"
                              onClick={() =>
                                handlePlanSelection(suggestion.plan_name)
                              }
                              // Event handler for clicks
                              style={{ cursor: "pointer", padding: "5px" }}>
                              {suggestion.plan_name}
                            </p>
                          ))}
                        </div>
                      )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="billing-below-1">
            <table className="billing-last-table">
              <thead>
              <tr>
                <th>Service Name</th>
                <th>Days</th>
                <th>Amount</th>
                <th>GST</th>
                <th>GST Amount</th>
                <th>Total Amount</th>
                <th>Remove</th>
                </tr>
              </thead>
              
              <tbody>
  {plans.map((plan, index) => (
    <tr key={index}>
      <td><input type="text" value={plan.plan_name} disabled className="pNa" /></td>
      <td>
        <input
          type="number"
          value={plan.editableDays}
          className="pDay"
          onChange={(e) => handleDaysChange(e, index)}
        />
      </td>
      <td>
        <input
          type="text"
          value={plan.editablePrice || plan.price}
          disabled
          className="pri"
        />
      </td>
      <td><input type="text" value={plan.GST} disabled className="pGst" /></td>
      <td><input type="text" value={plan.GSTamount} disabled className="pGsta" /></td>
     
      
      <td>
        <input
          type="text"
          value={plan.TotalAmount || plan.editableTotalAmount} // Ensure this is correct
          disabled
          className="amt"
        />
      </td>      
      <td>
        <button onClick={() => removePlan(index)} className="Rem1">Remove</button>
      </td>
    </tr>
  ))}
</tbody>

    
            </table>
          </div>

          

          
          <div >
                <div className="med">
                    <label>
                        {/* <span>Enter Medicine Name</span> */}
                        <input
                            type="text" id="input"
                            value={medicineName}
                            onChange={(e) => setMedicineName(e.target.value)}
                            onFocus={() => setFocusedMedicineInput("medicine")}
                            placeholder="Enter the medicine"
                            required
                            ref={inputRef} // Attach ref to input field
                        />
                        {isLoading && <div>Loading...</div>}
                        {focusedMedicineInput === "medicine" &&
                            filteredMedicineSuggestions.length > 0 && (
                                <div
                                    ref={medicineSuggestionRef}
                                    className="suggestions-fetch-mbl"
                                    style={{
                                        position: "absolute",
                                        backgroundColor: "white",
                                        border: "1px solid #ccc",
                                        width: "25.6%",
                                        height: "15vh",
                                        overflowY: "auto",
                                        zIndex:1000
                                    }}
                                >
                                    {filteredMedicineSuggestions.map((suggestion) => (
                                        <p
                                            key={suggestion._id}
                                            className="suggestion"
                                            onClick={() => handleMedicineSelection(suggestion)}
                                            style={{ cursor: "pointer", padding: "5px" }}
                                        >
                                            {suggestion.plan_name}
                                        </p>
                                    ))}
                                </div>
                            )}
                    </label>
                   

          
          
          
        {/* <button type="button" onClick={addMedicine} className="med-bill" >Add</button> */}
        <button type="button" onClick={addMedicine} className="med-bill">Add from Medicines List</button>
        <button type="button" onClick={addMedicineManually} className="med-bill">Add Manually</button>

        </div>
        <div>
            <table className="billing-last-table">
                <thead>
                    <tr>
                        <th>Medicine Name</th>
                        <th>Quantity</th>
                        <th>Total Amount</th>
                        <th>Remove</th>
                        {/* <th>Status</th> */}
                    </tr>
                </thead>
                {/* <tbody>
                    {selectedMedicines.map((medicine, index) => (
                        <tr key={index}>
                            <td><input type="text" value={medicine.plan_name} disabled style={{ width: "200px" }} /></td>
                            <td>
                                <input
                                    type="number"
                                    min="1"
                                    value={medicine.quantity}
                                    onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}className="mQua"
                                />
                            </td>
                            <td><input type="text" value={medicine.totalPrice || medicine.price} disabled className="mPri" /></td>
                            {/* <td><input type="text" value={medicine.status} disabled /></td> */}
                            {/* <td>
                            <button onClick={() => removeMedicine(index)} className="Rem2">Remove</button>
                            </td>
                        </tr>
                    ))}
                </tbody>  */}
                <tbody>
                {selectedMedicines.map((medicine, index) => (
                    <tr key={index}>
                        <td>
                            <input
                                type="text"
                                value={medicine.plan_name}
                                onChange={(e) => handleMedicineNameChange(index, e.target.value)} // Allow manual name change
                                style={{ width: "200px" }}
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                min="1"
                                value={medicine.quantity}
                                onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))} 
                                className="mQua"
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                value={medicine.totalPrice || medicine.price} // Allow manual price input
                                onChange={(e) => handlePriceChange(index, parseFloat(e.target.value))} 
                                className="mPri"
                            />
                        </td>
                        <td>
                            <button onClick={() => removeMedicine(index)} className="Rem2">Remove</button>
                        </td>
                    </tr>
                ))}
              </tbody>    

            </table>
            <div className="billing-below-1">
            <table className="billing-last-table">
              <thead>
                <tr>
                  <th>
                    Payment type <span style={{ color: "red" }}>*</span>
                  </th>
                  <th>
                    Amount paid <span style={{ color: "red" }}>*</span>
                  </th>
                  <th>Payment Status</th>
                  <th>Remaining Amount: Rs.</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <select
                      value={paymentType}
                      onChange={(e) => setPaymentType(e.target.value)}
                      required>
                      <option value="">Select Payment Type</option>
                      <option value="Cash">Cash</option>
                      <option value="Card">Card</option>
                    </select>
                  </td>
                  <td>
                    <input
                      className="input-num"
                      type="number"
                      value={amountPaid}
                      required
                      // onChange={(e) => setAmountPaid(e.target.value)}
                      onChange={(e) => {
                        // Parse input and ensure it's a valid number, defaulting to 0 if invalid
                        const inputAmount = parseFloat(e.target.value) || 0;
                      
                        // Calculate the total amount from all selected plans
                        const totalPlanAmount = plans.reduce((total, plan) => {
                          const planAmount = parseFloat(plan.TotalAmount) || 0; // default to 0 if TotalAmount is missing or invalid
                          return total + planAmount;
                        }, 0);
                      
                        // Calculate the total price from all selected medicines
                        const totalMedicinePrice = selectedMedicines.reduce((acc, medicine) => {
                          const medicinePrice = parseFloat(medicine.totalPrice) || 0; // ensure totalPrice is valid
                          return acc + medicinePrice;
                        }, 0);
                      
                        // Calculate the combined total amount
                        const totalAmount = totalPlanAmount + totalMedicinePrice;
                      
                        // Set the amountPaid to the lesser of inputAmount and totalAmount
                        const amountToPay = Math.min(inputAmount, totalAmount);
                      
                        // Update the state with a valid value
                        setAmountPaid(amountToPay);
                      }}
                      
                      
                      
                    />
                  </td>
                  <td>
                    <input value={status} readOnly className="sta"/>
                  </td>
                  <td>
                    <input value={remaining} readOnly className="remain"/>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
            <div >
            <h4>Total Amount of All Services:{totalPlanAmount}</h4>
    <h4>Total Amount of All Medicines: {totalMedicinePrice}</h4>
  <h4>Total Amount (Services + Medicines): {totalMedicinePrice+totalPlanAmount}</h4>
    <h4>Amount Paid: {amountPaid}</h4>
    <h3>Remaining Amount to be Paid: {remaining}</h3>
        </div>
        </div>
    </div>
   

          <button className="btnbilling" onClick={handleSaveAndPrint}>
            Save & Print
          </button>
        </div>
      </div>
    </div>
  );
};

export default Billing;


