import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import the default styles for React Toastify
import { useNavigate } from "react-router-dom";
import { VINOOTNEW } from "../../Helper/Helper";
import Sidebar from "../Sidebar/Sidebar";
import "./City.css";

// import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
// import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
const Cities = () => {
  const navigate = useNavigate();

  const [stateName, setStateName] = useState("");
  const [cityName, setCityName] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage] = useState(3);
  // Get user ID from local storage
  const userId = localStorage.getItem("userId");
  const presentTime = new Date().toLocaleString();

  useEffect(() => {
    fetchCities();
    fetchStates();
  }, []);

  const fetchCities = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${VINOOTNEW}/api/cities`);
      setCities(response.data);
    } catch (error) {
      // console.error("Failed to fetch cities", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStates = async () => {
    try {
      const response = await axios.get(`${VINOOTNEW}/api/states`);
      // setStates(response.data);

      const activeStates = response.data.filter(
        (state) => state.status === "active"
      );
      setStates(activeStates);
    } catch (error) {
      // console.error("Failed to fetch states", error);
    }
  };

  const handleStateChange = (e) => {
    const selectedState = states.find((state) => state.name === e.target.value);
    if (selectedState) {
      setStateName(selectedState.name);
    }
  };

  // const handleCityChange = (e) => {
  //   const inputValue = e.target.value;
  //   setCityName(inputValue);
  //   if (inputValue.length < 3 || inputValue.length > 60) {
  //     setError("City name must be between 3 and 60 characters.");
  //   } else {
  //     setError("");
  //   }
  // };

  const handleCityChange = (e) => {
    const inputValue = e.target.value;
    setCityName(inputValue);
    
    if (inputValue === "") {
      setError(""); // Clear the error if the input is empty
    } else {
    // Regular expression to match at least 3 alphabetic characters
    const alphaRegex = /[a-zA-Z]/g;
    const alphaCount = (inputValue.match(alphaRegex) || []).length;

    if (alphaCount < 3) {
      setError("City name must contain at least 3 alphabetical characters.");
    } else if (inputValue.length < 3 || inputValue.length > 60) {
      setError("City name must be between 3 and 60 characters.");
    } else {
      setError("");
    }
  }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (error) {
      toast.error("Please fix the errors before submitting", {
        position: "top-right",
        autoClose: 1500,
      });
      return;
    }

    try {
      const selectedState = states.find((state) => state.name === stateName);
      // if (!selectedState) {
      //   console.error("Selected state not found");
      //   return;
      // }

      if (!stateName) {
        toast.error("Please select a state", {
          position: "top-right",
          autoClose: 1500,
        });
        return;
      }
      if (!cityName) {
        toast.error("Please enter a city name", {
          position: "top-right",
          autoClose: 1500,
        });
        return;
      }

      const existingStates = await axios.get(`${VINOOTNEW}/api/cities`);
      const count = existingStates.data.length;
      const city_id = generateUniqueId(cityName, count + 0);

      const response = await axios.post(`${VINOOTNEW}/api/cities`, {
        stateId: selectedState._id,
        cityName: cityName,
        city_id: city_id,
        createdBy: userId, // Set createdBy field
        createdAt: presentTime, // Set createdAt field
        modifiedBy: userId, // Set modifiedBy field
        modifiedAt: presentTime, // Set modifiedAt field
      });
      if (response.status === 201) {
        // console.log("City added successfully");
        toast.success("City added successfully", {
          position: "top-right",
          autoClose: 1500,
          onClose: () => {
            navigate("/Area");
          },
        });
        return;
      } else {
        // console.error("Failed to add city");
        toast.error("Failed to add city", {
          position: "top-right",
          autoClose: 1500,
        });
        return;
      }
    } catch (error) {
      // console.error("Failed to add city", error);
      if (error.response && error.response.status === 400) {
        toast.error("City already exists in this state", {
          position: "top-right",
          autoClose: 1500,
        });
        return;
      }
    }
  };

  const toggleCityStatus = async (cityId, cityName, currentStatus) => {
    try {
      const updatedStatus = currentStatus === "active" ? "inactive" : "active";
      const response = await axios.put(
        `${VINOOTNEW}/api/cities/${cityId}/toggle`,
        {
          status: updatedStatus,
          modifiedBy: userId,
          modifiedAt: presentTime,
        }
      );
      if (currentStatus === "inactive") {
        toast.success(cityName + " is Activated sucessfully.", {
          position: "top-right",
          autoClose: 800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(cityName + " is Deactivated .", {
          position: "top-right",
          autoClose: 800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      if (response.status === 200) {
        // Find the index of the area that was toggled
        const cityIndex = cities.findIndex((city) => city._id === cityId);

        // Create a new array with the updated status
        const updatedCities = [...cities];
        updatedCities[cityIndex].status = updatedStatus;

        // Update the state with the new array
        setCities(updatedCities);
      } else {
        // console.error("Failed to toggle city status");
      }
    } catch (error) {
      // console.error("Failed to toggle city status:", error);
    }
  };

  const generateUniqueId = (name, count) => {
    const abbreviation = name.substring(0, 3).toUpperCase();
    const paddedCount = (count + 1).toString().padStart(3, "0");
    const id = abbreviation + paddedCount;
    return id;
  };

  // Pagination handlers
  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  // Get current plans
  // const indexOfLastPlan = currentPage * itemsPerPage;
  // const indexOfFirstPlan = indexOfLastPlan - itemsPerPage;
  // const currentPlans = cities.slice(indexOfFirstPlan, indexOfLastPlan);

  // Calculate total pages
  // const totalPages = Math.ceil(cities.length / itemsPerPage);

  return (
    <div className="city-total">
      <ToastContainer />
      <div>
        <Sidebar />
      </div>
      <div className="city-right">
        <h1>City Master</h1>
        <form className="city-form" onSubmit={handleSubmit}>
          <div>
            <label>
              Select State: <span style={{ color: "red" }}>*</span>
              <select value={stateName} onChange={handleStateChange}>
                <option value="">Select a state</option>
                {states.map((state) => (
                  <option key={state._id} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div>
            <label className="all-ar-wid">
              City Name: <span style={{ color: "red" }}>*</span>
              <input type="text" value={cityName} onChange={handleCityChange} />
            </label>
          </div>
          {error && <div className="price-btn">{error}</div>}
          <button className="createe-btnn" type="submit">Create</button>
        </form>

        <h2 className="cities_list_heading">Cities List</h2>

        <div className="citytable_pagination_parent">
          {isLoading ? (
            <p>Loading cities...</p>
          ) : (
            <table className="cities-table">
              <thead>
                <tr>
                  <th>State Name</th>
                  <th>City Name</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {cities.map((city) => (
                  <tr key={city._id}>
                    <td>{city.StateName}</td>
                    <td>{city.name}</td>
                    {/* <td>{city.status}</td> */}
                    <td
                      className={
                        city.status === "active"
                          ? "button1-Active"
                          : "button1-Inactive"
                      }
                    >
                      {city.status === "active" ? "Active" : "Inactive"}
                    </td>
                    <td>
                      <button
                        className={
                          city.status === "active"
                            ? "button-Inactive"
                            : "button-Active"
                        }
                        onClick={() =>
                          toggleCityStatus(city._id, city.name, city.status)
                        }
                      >
                        {city.status === "active" ? "Inactive" : "Active"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {/* 
<div className="paginationss">
  <span
    onClick={() => handlePageChange(1)}
    disabled={currentPage === 1}
  >
    <KeyboardDoubleArrowLeftIcon />
  </span>
  <span
    onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
    disabled={currentPage === 1}
  >
    <KeyboardArrowLeftIcon />
  </span>
  {Array.from({ length: 3 }, (_, index) => {
    const startPage = Math.max(
      Math.floor((currentPage - 1) / 3) * 3 + 1,
      1
    );
    const pageNumber = startPage + index;

    return (
      pageNumber <= totalPages && (
        <span
          key={pageNumber}
          onClick={() => handlePageChange(pageNumber)}
          id={currentPage === pageNumber ? "pageactive-page" : ""}
        >
          {pageNumber}
        </span>
      )
    );
  })}
  <span
    onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
    disabled={currentPage === totalPages}
  >
    <KeyboardArrowRightIcon />
  </span>
  <span
    onClick={() => handlePageChange(totalPages)}
    disabled={currentPage === totalPages}
  >
    <KeyboardDoubleArrowRightIcon />
  </span>
</div> */}
        </div>
      </div>
    </div>
  );
};

export default Cities;
