import React, { useState, useEffect } from "react";
import "./FranchiseAdmintable.css";
import axios from "axios";
// import { Link } from "react-router-dom";
import Sidebar from "../../Masterdata/Sidebar/Sidebar";
import "./FranchiseAdmintable.css";
// import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
// import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { VINOOTNEW } from "../../Helper/Helper";
import { toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FranchiseAdmintable = () => {
  const [admins, setAdmins] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage] = useState(3);

  useEffect(() => {
    fetchAdmins();
  }, []);

  const fetchAdmins = async () => {
    try {
      const response = await axios.get(`${VINOOTNEW}/api/franchisefetchAdmin`);
      // Filter admins whose designation is "FranchiseAdmin"
      const filteredAdmins = response.data.filter(
        (admin) => admin.designation === "FranchiseAdmin"
      );
      setAdmins(filteredAdmins);
    } catch (error) {
      console.error("Error fetching admins:", error);
    }
  };

  const toggleActiveState = async (id,franchisename, isActive) => {
    try {
      const updatedBy = localStorage.getItem("userId");
      await axios.patch(`${VINOOTNEW}/api/franchisestateupdate/${id}`, {
        isActive: !isActive,
        updatedBy,
      });
      if(!isActive){
        toast.success(franchisename+" Activated sucessfully.", {
          position: "top-right",
  autoClose: 800,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
        });
      }
      else{
        toast.error(franchisename+" Deactivated .", {
          position: "top-right",
          autoClose: 800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      fetchAdmins();
    } catch (error) {
      console.error("Error updating active state:", error);
    }
  };
  // Pagination handlers
  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  // // Get current plans
  // const indexOfLastPlan = currentPage * itemsPerPage;
  // const indexOfFirstPlan = indexOfLastPlan - itemsPerPage;
  // const currentPlans = admins.slice(indexOfFirstPlan, indexOfLastPlan);

  // // Calculate total pages
  // const totalPages = Math.ceil(admins.length / itemsPerPage);
  return (
    <div className="franchise-details">
    <ToastContainer/>
      <div>
        <Sidebar />
      </div>
      <div className="franchise-admin-rights">
        <h1>Branch Details</h1>
        <table className="tabf">
          <thead>
            <tr>
              <th>Full Name</th>
              <th>userId</th>
              <th>Branch Name</th>
              <th>Branch ID</th>
              <th>Designation</th>
              <th>Email</th>
              <th>Password</th>
              <th>Is Active</th>
              <th>Action</th>
              <th>Modified By</th>
              <th>Modified At</th>
              <th>Created At</th>
              <th>Created By</th>
            
            </tr>
          </thead>
          <tbody>
            {admins.map((admin) => (
              <tr key={admin._id}>
                <td>{admin.fullname}</td>
                <td>{admin.userId}</td>
                <td>{admin.franchisename}</td>
                <td>{admin.FranchiseID}</td>
                <td>{admin.designation}</td>
                <td>{admin.email}</td>
                <td>{admin.password}</td>
                <td>{admin.isActive ? "Active" : "Inactive"}</td>
                <td>
                  <button
                    className="farnchiseadmin-activebtn"
                    onClick={() =>
                      toggleActiveState(admin._id,admin.franchisename, admin.isActive)
                    }>
                    {admin.isActive ? "Deactivate" : "Activate"}
                  </button>
                </td>
                <td>{admin.modifiedBy}</td>
                <td>{admin.modifiedAt}</td>
                <td>{admin.createdAt}</td>
                <td>{admin.createdBy}</td>
              </tr>
            ))}
          </tbody>
        </table>
{/*        
<div className="paginationss">
  <span
    onClick={() => handlePageChange(1)}
    disabled={currentPage === 1}
  >
    <KeyboardDoubleArrowLeftIcon />
  </span>
  <span
    onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
    disabled={currentPage === 1}
  >
    <KeyboardArrowLeftIcon />
  </span>
  {Array.from({ length: 3 }, (_, index) => {
    const startPage = Math.max(
      Math.floor((currentPage - 1) / 3) * 3 + 1,
      1
    );
    const pageNumber = startPage + index;

    return (
      pageNumber <= totalPages && (
        <span
          key={pageNumber}
          onClick={() => handlePageChange(pageNumber)}
          className={currentPage === pageNumber ? "pageactive-page" : ""}
        >
          {pageNumber}
        </span>
      )
    );
  })}
  <span
    onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
    disabled={currentPage === totalPages}
  >
    <KeyboardArrowRightIcon />
  </span>
  <span
    onClick={() => handlePageChange(totalPages)}
    disabled={currentPage === totalPages}
  >
    <KeyboardDoubleArrowRightIcon />
  </span>
</div> */}



      </div>
    </div>
  );
};

export default FranchiseAdmintable;
