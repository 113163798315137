import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./Treatment.css";
import DoctorSidebar from "../DoctorSB/DoctorSidebar";
import ReceptionSidebar from "../../Reception/ReceptionSidebar/ReceptionSidebar";
import { VINOOTNEW } from "../../../Helper/Helper";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import the default styles for React Toastify

function Treatment() {
  const navigate = useNavigate();
  const { patientId } = useParams(); // Retrieve patientId from URL
  const [patientDetails, setPatientDetails] = useState({});

  const [vitals, setVitals] = useState([]);
  const [userRole, setUserRole] = useState(null); // State to hold the user role
  const [newVital, setNewVital] = useState({
    date: "",
    weight: "",
    height: "",
    bloodPressure: "",
    temperature: "",
    pulseRate: "",
    notes: "",
  });

  // Fetch Patient Details
  const fetchPatientDetails = useCallback(async () => {
    try {
      if (patientId) {
        const response = await axios.get(
          `${VINOOTNEW}/api/patients/${patientId}`
        );
        setPatientDetails(response.data);
      } else {
        console.error("Patient ID not found");
      }
    } catch (error) {
      console.error("Error fetching PatientID:", error);
    }
  }, [patientId]);

  // Fetch Vitals
  const fetchVitals = useCallback(async () => {
    try {
      const response = await axios.get(`${VINOOTNEW}/api/vitals/${patientId}`);
      setVitals(response.data);
    } catch (error) {
      console.error("Error fetching vitals:", error);
    }
  }, [patientId]);

  // Fetch the user role from localStorage or an API
  useEffect(() => {
    const role = localStorage.getItem("designation"); // Assuming role is stored in localStorage
    setUserRole(role);
  }, []);

  // UseEffect to fetch both Patient Details and Vitals
  useEffect(() => {
    fetchPatientDetails();
    fetchVitals();
  }, [fetchPatientDetails, fetchVitals]);

  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    setNewVital((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${VINOOTNEW}/api/vitals`, { patientId, ...newVital });
      toast.success("appointment saved", {
        position: "top-right",
        autoClose: 1500,
      });
      setNewVital({
        date: "",
        weight: "",
        height: "",
        bloodPressure: "",
        temperature: "",
        pulseRate: "",
        notes: "",
      });
      const response = await axios.get(`/api/vitals/${patientId}`);
      setVitals(response.data);
    } catch (error) {
      console.error("Error submitting vital:", error);
    }
    fetchVitals();
  };

  const handleNoteChange = (e) => {
    setNotes(e.target.value);
  };

  const handleNote1Change = (e) => {
    setNote1(e.target.value);
  };

  const handleNote2Change = (e) => {
    setNote2(e.target.value);
  };

  const handleNote3Change = (e) => {
    setNote3(e.target.value);
  };

  const handleNote4Change = (e) => {
    setNote4(e.target.value);
  };

  // State declarations...
  const [treatmentPlans, setTreatmentPlans] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [treatmentPlan, setTreatmentPlan] = useState("");
  const [treatmentDays, setTreatmentDays] = useState("");
  const [medicines, setMedicines] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [medicineName, setMedicineName] = useState("");
  const [frequency, setFrequency] = useState("");
  const [duration, setDuration] = useState("");
  const [whenToTake, setWhenToTake] = useState("");
  const [editIndex, setEditIndex] = useState(-1);
  const [notes, setNotes] = useState("");
  const [note1, setNote1] = useState("");
  const [note2, setNote2] = useState("");

  const [note3, setNote3] = useState(""); // parakruthi
  const [note4, setNote4] = useState(""); // vikruthi
  // const [errors, setErrors] = useState({});
  const [categories, setCategories] = useState([]);
  //const [selectedCategory, setSelectedCategory] = useState("");
  const [treatments, setTreatments] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [query, setQuery] = useState("");
  const [treatmentHistory, setTreatmentHistory] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [quantity, setQuantity] = useState("");

  const [price, setPrice] = useState(""); // State to store price
  const [/*manualEntry*/, setManualEntry] = useState(false); // State to determine if medicine is manually entered
  const [isManualMode, setIsManualMode] = useState(false); // State to track manual mode
  const [isListMode, setIsListMode] = useState(false); // State to track list mode



  // When manually adding the price:
  const handlePriceChange = (e) => {
    setPrice(e.target.value); // Manually set price for manual medicine
  };

  // Fetch categories
  useEffect(() => {
    // Fetch both categories and treatment plans together
    axios
      .all([
        axios.get(`${VINOOTNEW}/api/treatment-category`),
        axios.get(`${VINOOTNEW}/api/treatment-plan`),
      ])
      .then(
        axios.spread((categoriesResponse, treatmentPlansResponse) => {
          // Handle the responses separately
          setCategories(categoriesResponse.data);
          setTreatmentPlans(treatmentPlansResponse.data);
        })
      )
      .catch((error) => {
        console.error("Error fetching data:", error);
        alert("Failed to fetch data. Please try again later.");
      });
  }, []);

  // Fetch treatment history
  useEffect(() => {
    if (patientId) {
      axios
        .get(`${VINOOTNEW}/api/patient-history/${patientId}`)
        .then((response) => {
          setTreatmentHistory(response.data);
        })
        .catch((error) => {
          console.error("Error fetching treatment history:", error);
          alert("Failed to fetch treatment history. Please try again later.");
        });
    }
  }, [patientId]);

  const handleAddTreatment = () => {
    if (selectedCategory && selectedPlan && treatmentDays) {
      const newTreatment = {
        category: selectedCategory.value,
        plan: selectedPlan.value,
        days: treatmentDays,
      };
      setTreatments([...treatments, newTreatment]);

      // Clear inputs
      setSelectedCategory(null);
      setSelectedPlan(null);
      setTreatmentDays("");
    } else {
      alert("Please fill out all fields before adding.");
    }
  };

  const handleDeleteTreatment = (index) => {
    const updatedTreatments = treatments.filter((_, i) => i !== index);
    setTreatments(updatedTreatments);
  };

  const handleSave = async () => {
    const userId = localStorage.getItem("userId");
  
    try {
      // Update the appointment status to "completed"
      // const updateStatusResponse = await axios.put(
      //   `${VINOOTNEW}/api/appointments/patient/${patientId}/status`,
      //   {
      //     status: "completed",
      //   }
      // );
      // console.log("Update status response:", updateStatusResponse.data);
  
      // Save the treatment data with the status set to "completed"
      await axios.post(`${VINOOTNEW}/api/PatientHistory`, {
        patientId,
        patientName: patientDetails.patient_name,
        mobileNumber: patientDetails.mobile_number,
        treatments: treatments.map((treatment) => ({
          treatmentCategory: treatment.category,
          treatmentPlan: treatment.plan,
          treatmentDays: treatment.days,
        })),
        medicines,
        notes: {
          advice: note1,
          dietAndExercise: note2,
          summary: notes,
        },
        prakruthi: note3,
        vikruthi: note4,
        status: "completed",
        completedAt: new Date(),
        userId,
      });
  
      // Show a toast message indicating that the treatment is completed and saved
      toast.success("Treatment completed and data saved!");
  
      // Export the details to the billing page
      const billingData = {
        patientId,
        patientName: patientDetails.patient_name,
        mobileNumber: patientDetails.mobile_number,
        patientAddress: patientDetails.address, // Make sure this is available in your patient details
        treatments: treatments.map((treatment) => ({
          treatmentCategory: treatment.category,
          treatmentPlan: treatment.plan,
          treatmentDays: treatment.days,
        })),
        medicines,
      };
  
      // Navigate to the billing page with the data
      const role = localStorage.getItem("designation"); // Assuming role is stored in localStorage

      if (role === "Doctor") {
        navigate("/DoctorBilling", { state: { billingData } });
      } else if (role === "Reception") {
        navigate("/Billing", { state: { billingData } });
      } else {
        console.warn("Unknown designation or no designation found.");
      }  
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Failed to save data");
    }
  };

  useEffect(() => {
    if (isInputFocused && query.length === 0) {
      axios
        .get(`${VINOOTNEW}/api/search-medicines`)
        .then((response) => {
          setSuggestions(response.data);
          setShowSuggestions(true);
        })
        .catch((error) => {
          console.error("Error fetching medicine names:", error);
        });
    }
  }, [isInputFocused, query]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest(".form-item") === null) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const handleInputFocus = () => setIsInputFocused(true);
  const handleInputBlur = () => setIsInputFocused(false);

  const handleInputClick = () => {
    if (query.length === 0 || !suggestions.includes(query)) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
  
    // Check if the entered value matches an existing medicine
    const matchedMedicine = suggestions.find((suggestion) =>
      suggestion.toLowerCase() === value.toLowerCase()
    );
  
    // If there's no matched medicine, enable manual price entry and show suggestions
    if (!matchedMedicine && isListMode) {
      setManualEntry(true); // Allow manual price input only if not in list mode
      setShowSuggestions(true); // Show suggestions when in list mode
    } else {
      setManualEntry(false); // Disable manual price input for matched medicine
      setShowSuggestions(matchedMedicine && isListMode); // Show suggestions only in list mode
    }
  };
  
  
  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setShowSuggestions(false);
  
    const selectedMedicine = medicines.find(
      (medicine) => medicine.plan_name === suggestion
    );
  
    if (selectedMedicine) {
      setPrice(selectedMedicine.price);
      setIsManualMode(false);  // Disable manual mode for selected medicines
    }
  };
  
  
  
  const handleAddMedicineManually = () => {
    setIsManualMode(true);   // Enable manual mode
    setIsListMode(false);    // Disable list mode
    setQuery("");            // Clear any existing query
    setPrice("");            // Clear the price field
    setManualEntry(true);    // Ensure the price input is required
  };
  
  const handleAddMedicineFromList = () => {
    setIsListMode(true);     // Enable list mode
    setIsManualMode(false);  // Disable manual mode
    setQuery("");            // Clear any existing query
    setPrice("");            // Clear the price field
    setManualEntry(false);   // Price input not required for list mode
    setShowSuggestions(false); // Reset suggestions display
  };
  
  
  
  const handleAddMedicine = (e) => {
    e.preventDefault();
  
    const newMedicine = {
      medicineName: query,
      price: price || "Will be Updated in Billing",
      frequency,
      duration,
      whenToTake,
      quantity,
    };
  
    const existingMedicineIndex = medicines.findIndex(
      (medicine) => medicine.medicineName.toLowerCase() === query.toLowerCase()
    );
  
    if (existingMedicineIndex !== -1) {
      const updatedMedicines = medicines.map((medicine, index) =>
        index === existingMedicineIndex ? newMedicine : medicine
      );
      setMedicines(updatedMedicines);
    } else {
      setMedicines([...medicines, newMedicine]);
    }
  
    // Reset form fields and modes after submission
    setQuery("");
    setPrice("");
    setFrequency("");
    setDuration("");
    setWhenToTake("");
    setQuantity("");
    setShowSuggestions(false);
    setIsManualMode(false); 
    setIsListMode(false);  
  };
  
  

  const handleEdit = (index) => {
    const medicine = medicines[index];
    setMedicineName(medicine.medicineName);
    setFrequency(medicine.frequency);
    setDuration(medicine.duration);
    setWhenToTake(medicine.whenToTake);
    setQuantity(medicine.quantity);
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    const filteredMedicines = medicines.filter((_, i) => i !== index);
    setMedicines(filteredMedicines);
  };

  return (
    <div className="app-container">
      <section id="sidebar">
        {userRole === "Doctor" ? <DoctorSidebar /> : <ReceptionSidebar />}
      </section>
      <div className="main-container">
        <div className="patient-profile">
          <h2>Patient Profile</h2>
          <div className="patient-info">
            <div className="patient-detail">
              <label>Patient ID:</label>
              <span>{patientId}</span>
            </div>
            <div className="patient-detail">
              <label>Name:</label>
              <span>{patientDetails.patient_name}</span>
            </div>
            <div className="patient-detail">
              <label>Phone:</label>
              <span>{patientDetails.mobile_number}</span>
            </div>
          </div>
        </div>

        <div>
          <h2>Previous Vitals</h2>
          {vitals.length > 0 ? (
            <div className="vitals-history-table">
              <table className="vital-history1">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Weight</th>
                      <th>Height</th>
                      <th>Blood Pressure</th>
                      <th>Temperature</th>
                      <th>Pulse Rate</th>
                      <th>Notes</th>
                    </tr>
                  </thead>
                <tbody className="vital-history2">
                  {vitals
                    .slice()
                    .reverse()
                    .map((vital) => (
                      <tr key={vital._id}>
                        <td>{new Date(vital.date).toLocaleDateString()}</td>
                        <td>{vital.weight}</td>
                        <td>{vital.height}</td>
                        <td>{vital.bloodPressure}</td>
                        <td>{vital.temperature}</td>
                        <td>{vital.pulseRate}</td>
                        <td>{vital.notes}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No vitals available</p>
          )}

          <h2>Add or Update Vitals</h2>

          <form onSubmit={handleSubmit}>
            <div className="column-wrapper">
              <div className="column">
                <div className="input-wrapper">
                  <label htmlFor="date">
                    Date:
                  </label>

                  <input
                    type="date"
                    name="date"
                    value={newVital.date}
                    onChange={handleInputChange2}
                    required
                  />
                </div>
                <div className="input-wrapper">
                  <label htmlFor="weight">
                    Weight:<span className="mandatory">*</span>
                  </label>

                  <input
                    type="number"
                    name="weight"
                    value={newVital.weight}
                    onChange={handleInputChange2}
                  />
                </div>
                <div className="input-wrapper">
                  <label htmlFor="Height">
                    Height:
                    {/* <span className="mandatory">*</span> */}
                  </label>

                  <input
                    type="number"
                    name="height"
                    value={newVital.height}
                    onChange={handleInputChange2}
                  />
                </div>
                <div className="input-wrapper">
                  <label htmlFor="Blood Pressure">
                    Blood Pressure:
                    {/* <span className="mandatory">*</span> */}
                  </label>
                  <input
                    type="text"
                    name="bloodPressure"
                    value={newVital.bloodPressure}
                    onChange={handleInputChange2}
                  />
                </div>
              </div>
              <div className="column">
                <div className="input-wrapper">
                  <label htmlFor="Temperature">
                    Temperature:
                    {/* <span className="mandatory">*</span> */}
                  </label>
                  <input
                    type="number"
                    name="temperature"
                    value={newVital.temperature}
                    onChange={handleInputChange2}
                  />
                </div>
                <div className="input-wrapper">
                  <label htmlFor="Pulse rate">
                    Pulse Rate:
                    {/* <span className="mandatory">*</span> */}
                  </label>
                  <input
                    type="number"
                    name="pulseRate"
                    value={newVital.pulseRate}
                    onChange={handleInputChange2}
                  />
                </div>
                <div className="input-wrapper">
                  <label htmlFor="Pulse rate">
                    Notes:
                    {/* <span className="mandatory">*</span> */}
                  </label>

                  <textarea
                    name="notes"
                    value={newVital.notes}
                    onChange={handleInputChange2}
                  />
                </div>
                <div className="sub-btnn">
                  <button type="submit">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* {patient treatment history table} */}

        <div className="treatment-history-container">
          <h2>Treatment History</h2>
          {treatmentHistory.length > 0 ? (
            <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Treatment Category</th>
                    <th>Treatment Plan</th>
                    <th>Medicines</th>
                    <th>Notes</th>
                    <th>Prakruthi</th>
                    <th>Vikruthi</th>
                  </tr>
                </thead>
              <tbody>
                {treatmentHistory
                  .slice()
                  .reverse()
                  .map((history, index) => (
                    <tr key={index}>
                      <td>
                        {new Date(history.completedAt).toLocaleDateString()}
                      </td>
                      <td>
                        {history.treatments.map((treatment, i) => (
                          <div key={i}>{treatment.treatmentCategory}</div>
                        ))}
                      </td>
                      <td>
                        {history.treatments.map((treatment, i) => (
                          <div key={i}>{treatment.treatmentPlan}</div>
                        ))}
                      </td>
                      <td>
                        <ul>
                          {history.medicines.map((med, i) => (
                            <li key={i}>
                              {med.medicineName}({med.duration})
                              {/* ({med.frequency}, {med.duration},{" "}
                          {med.whenToTake}) */}
                            </li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        <div>Advice: {history.notes.advice}</div>
                        <div>
                          Diet & Exercise: {history.notes.dietAndExercise}
                        </div>
                        <div>Summary: {history.notes.summary}</div>
                      </td>
                      <td>
                        <span>{history.prakruthi}</span>
                      </td>
                      <td>
                        <span>{history.vikruthi}</span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <p>No treatment history available.</p>
          )}
        </div>
        {/* {-------parakruthi and vikruthi container------} */}
        <div className="prakruthi-vikruthi-container">
          <div className="prakruthi-note">
            <h2>Prakruthi</h2>
            <textarea
              id="prakruthi"
              className="sm-textarea"
              rows="5"
              value={note3}
              onChange={handleNote3Change}
              placeholder="Enter your Prakruthi here"
            ></textarea>
          </div>
          <div className="vikruthi-note">
            <h2>Vikruthi</h2>
            <textarea
              id="vikruthi"
              className="sm-textarea"
              rows="5"
              value={note4}
              onChange={handleNote4Change}
              placeholder="Enter your vikruthi here"
            ></textarea>
          </div>
        </div>

        {/*Treatment*/}
        <div className="treatment-container">
          <h2>Select Treatment</h2>

          <div className="input-field">
            <label>Treatment Category</label>
            <Select
              options={categories.map((category) => ({
                value: category.category_name,
                label: category.category_name,
              }))}
              value={selectedCategory}
              onChange={setSelectedCategory}
              placeholder="Select or type category..."
              menuPortalTarget={document.body}
            />
          </div>

          {selectedCategory && (
            <>
              <div className="input-field">
                <label>Treatment Plan</label>
                <Select
                  options={treatmentPlans.map((plan) => ({
                    value: plan.plan_name,
                    label: plan.plan_name,
                  }))}
                  value={selectedPlan}
                  onChange={setSelectedPlan}
                  placeholder="Select or type plan..."
                  menuPortalTarget={document.body}
                />
              </div>

              <div className="input-field">
                <label>Treatment Days</label>
                <input
                  type="text"
                  value={treatmentDays}
                  onChange={(e) => setTreatmentDays(e.target.value)}
                  placeholder="Enter treatment days"
                />
              </div>

              <button onClick={handleAddTreatment}>Add Treatment</button>
            </>
          )}

          {treatments.length > 0 && (
            <div className="treatment-table">
              <h3>Treatment </h3>
              <table>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Plan</th>
                    <th>Days</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {treatments.map((treatment, index) => (
                    <tr key={index}>
                      <td>{treatment.category}</td>
                      <td>{treatment.plan}</td>
                      <td>{treatment.days}</td>
                      <td>
                        <button onClick={() => handleDeleteTreatment(index)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {/* {----------add medcines form-------} */}
        <div className="medicine-table-container">
        <h2>Prescription</h2>
        <div className="form-item">
            <div className="form-item-buttons">
              <button
                type="button"
                onClick={handleAddMedicineFromList}
              >
                Add Medicine from Medicines List
              </button>
              <button
                type="button"
                onClick={handleAddMedicineManually}
              >
                Add Medicine Manually
              </button>
            </div>
          </div>




   
        <form onSubmit={handleAddMedicine} className="medicine-form">
        <div className="form-item">
              <label htmlFor="medicine-name">Medicine Name:</label>
              {!isManualMode && !isListMode && (
                <input
                  type="text"
                  value=""
                  readOnly
                  placeholder="Click relevant button above to Proceed"
                  className="disabled-input"
                />
                )}

              {isListMode && (
                <input
                  type="text"
                  value={query}
                  onChange={handleInputChange}
                  onClick={handleInputClick}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  placeholder="Enter or select medicine name"
                  required
                />
              )}

              {!isListMode && isManualMode && (
                <input
                  type="text"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="Enter medicine name manually"
                  required
                />
              )}

              {isListMode && showSuggestions && suggestions.length > 0 && (
                <ul className="suggestions-list-treatments">
                  {suggestions
                    .filter((suggestion) =>
                      suggestion.toLowerCase().includes(query.toLowerCase())
                    )
                    .map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSuggestionClick(suggestion);
                        }}
                      >
                        {suggestion}
                      </li>
                    ))}
                </ul>
              )}
            </div>

            {isManualMode && (
              <div className="form-item">
                <label htmlFor="price">Price (For One Quantity): </label>
                <input
                  type="number"
                  id="price"
                  value={price}
                  onChange={handlePriceChange}
                  placeholder="Enter price"
                  required
                />
              </div>
            )}
                      

        <div className="form-item">
          <label htmlFor="frequency">Frequency:</label>
          <select
            id="frequency"
            value={frequency}
            onChange={(e) => setFrequency(e.target.value)}
            required
          >
            <option value="">Select frequency</option>
            <option value="1-1-1">1-1-1</option>
            <option value="1-0-1">1-0-1</option>
            <option value="0-0-1">0-0-1</option>
            <option value="1-0-0">1-0-0</option>
            <option value="0-1-0">0-1-0</option>
          </select>
        </div>

          <div className="form-item">
            <label htmlFor="duration">Duration:</label>
            <select
              id="duration"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              required
            >
              <option value="">Select duration</option>
              <option value="7 days">7 days</option>
              <option value="14 days">14 days</option>
              <option value="30 days">30 days</option>
            </select>
          </div>

          <div className="form-item">
            <label htmlFor="when-to-take">When to take:</label>
            <select
              id="when-to-take"
              value={whenToTake}
              onChange={(e) => setWhenToTake(e.target.value)}
              required
            >
              <option value="">Select time</option>
              <option value="before meal">Before meal</option>
              <option value="after meal">After meal</option>
              <option value="empty stomach">Empty stomach</option>
            </select>
          </div>

          <div className="form-item">
            <label htmlFor="quantity">Quantity:</label>
            <input
              type="number"
              id="quantity"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              placeholder="Enter quantity"
              required
            />
          </div>

          <button className="submit" type="submit">
            {editIndex === -1 ? "Add Medicine" : "Update Medicine"}
          </button>
        </form>

        {/* Conditionally render the table only if there are medicines */}
        <table className="medicine-table">
          {medicines.length > 0 && (
            <thead>
              <tr>
                <th>Medicine Name</th>
                <th>Price(For One Quantity)</th>
                <th>Frequency</th>
                <th>Duration</th>
                <th>When to Take</th>
                <th>Quantity</th>
                <th>Actions</th>
              </tr>
            </thead>
          )}
          <tbody>
            {medicines.map((medicine, index) => (
              <tr key={index}>
                <td>{medicine.medicineName}</td>
                <td>{medicine.price}</td>
                <td>{medicine.frequency}</td>
                <td>{medicine.duration}</td>
                <td>{medicine.whenToTake}</td>
                <td>{medicine.quantity}</td>
                <td>
                  <button className="edit-button" onClick={() => handleEdit(index)}>
                    Edit
                  </button>
                  <button className="delete-button" onClick={() => handleDelete(index)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
              </div>


        {/*{----NotesArea----}*/}
        <div className="notes-area">
          <div className="notes-container">
            <div className="note-section">
              <h2>Advice</h2>
              <textarea
                id="advice"
                className="small-textarea"
                rows="5"
                value={note1}
                onChange={handleNote1Change}
                placeholder="Enter your Advice here"
              ></textarea>
            </div>
            <div className="note-section">
              <h2>Diet and Exercise</h2>
              <textarea
                id="diet-exercise"
                className="small-textarea"
                rows="5"
                value={note2}
                onChange={handleNote2Change}
                placeholder="Enter your Diet and Exercise here"
              ></textarea>
            </div>
          </div>
          <h2>Summary</h2>
          <textarea
            rows="10"
            value={notes}
            onChange={handleNoteChange}
            placeholder="Enter your summary here"
            className="text-area"
          />
        </div>
        {/*{----ActionButton----}*/}
        <ToastContainer />
        <div className="action-buttons">
          <button className="b-s" type="button" onClick={handleSave}>
            Save and Proceed to Billing
          </button>
        </div>
      </div>
    </div>
  );
}

export default Treatment;
