import React, { useState, useEffect } from "react";
import "./EditUserModal.css";
import ReactModal from "react-modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

ReactModal.setAppElement("#root"); // Set for accessibility

const EditUserModal = ({ user, isOpen, onClose, onUpdate }) => {
  const [editedUser, setEditedUser] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (user) {
      setEditedUser(user);
    }
  }, [user]);

  const handleInputChange = (e, key) => {
    const { value } = e.target;
    setEditedUser((prevState) => ({ ...prevState, [key]: value }));
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateForm = () => {
    const errors = {};
    if (!editedUser.fullName) errors.fullName = "Full Name is required";
    if (!editedUser.phoneNumber)
      errors.phoneNumber = "Phone Number is required";
    if (!editedUser.email) errors.email = "Email is required";
    if (!editedUser.password) errors.password = "Password is required";
    if (!editedUser.confirmPassword)
      errors.confirmPassword = "Confirm Password is required";
    if (editedUser.password !== editedUser.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }
    return errors;
  };

  const handleUpdate = () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    onUpdate(editedUser);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="edit_usermodal_popup"
      overlayClassName="edit_usermodal_popup_overlay"
    >
      <div className="edituser_modal_close">
        <h2 className="edit_username">Edit User</h2>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <form
        className="edit_user_formpage"
        onSubmit={(e) => {
          e.preventDefault();
          handleUpdate();
        }}
      >
        <label>
          <input
            type="text"
            value={editedUser.fullName || ""}
            onChange={(e) => handleInputChange(e, "fullName")}
          />
          <span>
            Full Name<span style={{ color: "red" }}>*</span>
          </span>
          {validationErrors.fullName && (
            <p className="error">{validationErrors.fullName}</p>
          )}
        </label>
        <label>
          <input
            type="text"
            value={editedUser.userId || ""}
            onChange={(e) => handleInputChange(e, "userId")}
            disabled
          />
          <span>
            User ID<span style={{ color: "red" }}>*</span>
          </span>
          {validationErrors.userID && (
            <p className="error">{validationErrors.userId}</p>
          )}
        </label>
        <label>
          <input
            type="text"
            value={editedUser.phoneNumber || ""}
            onChange={(e) => handleInputChange(e, "phoneNumber")}
          />
          <span>
            Mobile Number<span style={{ color: "red" }}>*</span>
          </span>
          {validationErrors.phoneNumber && (
            <p className="error">{validationErrors.phoneNumber}</p>
          )}
        </label>
        <label>
          <input
            type="email"
            value={editedUser.email || ""}
            onChange={(e) => handleInputChange(e, "email")}
          />
          <span>Email</span>
          {validationErrors.email && (
            <p className="error">{validationErrors.email}</p>
          )}
        </label>
        <label>
          <div className="inputpassword_eye_container">
            <input
              type={showPassword ? "text" : "password"}
              value={editedUser.password || ""}
              onChange={(e) => handleInputChange(e, "password")}
            />
            <span>
              Password<span style={{ color: "red" }}>*</span>
            </span>
            <IconButton
              aria-label="toggle password visibility"
              onClick={toggleShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </div>
          {validationErrors.password && (
            <p className="error">{validationErrors.password}</p>
          )}
        </label>
        <label>
          <div className="inputpassword_eye_container">
            <input
              type={showConfirmPassword ? "text" : "password"}
              value={editedUser.confirmPassword || ""}
              onChange={(e) => handleInputChange(e, "confirmPassword")}
            />
            <span>
              Confirm Password<span style={{ color: "red" }}>*</span>
            </span>
            <IconButton
              className="eye_icon"
              aria-label="toggle password visibility"
              onClick={toggleShowConfirmPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </div>
          {validationErrors.confirmPassword && (
            <p className="error">{validationErrors.confirmPassword}</p>
          )}
        </label>
        <div className="edit_user_modal_actions">
          <button className="update" onClick={handleUpdate}>
            Update
          </button>
          <button className="cancel" onClick={onClose}>
            Cancel
          </button>
        </div>
      </form>
    </ReactModal>
  );
};

export default EditUserModal;
