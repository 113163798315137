import React, { useState, useEffect } from "react";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import the default styles for React Toastify
import "./Plan.css";
import { VINOOTNEW } from "../../Helper/Helper";
import Sidebar from "../../Masterdata/Sidebar/Sidebar";

// import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
// import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const TreatmentPlan = () => {
  const [categories, setCategories] = useState([]);
  const [plans, setPlans] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [plan, setPlan] = useState("");
  const [GST, setGST] = useState("");
  const [GSTamount, setGSTamount] = useState("");
  const [TotalAmount, setTotalAmount] = useState("");
  const [days, setDays] = useState("");
  const [price, setPrice] = useState("");
  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage] = useState(3);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const validateField = (value, regex, errorMsg) => {
  //   if (!regex.test(value)) {
  //     return errorMsg;
  //   }
  //   return "";
  // };
  
  const filteredPlans = plans.filter((plan) =>
    (plan.category_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
  (plan.plan_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
  (plan.modifiedAt.toLowerCase().includes(searchQuery.toLowerCase())) ||
  String(plan.price).toLowerCase().includes(searchQuery.toLowerCase())

  );

  const [errors, setErrors] = useState({
    plan: "",
    price: "",
    GST: "",
    // gstamount: "",
    // totalamount: "",
    days: "",
  });
  const [editId, setEditId] = useState(""); // Category ID being edited
  const presentTime = new Date().toLocaleString();
  // const navigate = useNavigate();
  const userId = localStorage.getItem("userId");

  // Fetch categories
  const fetchCategory = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${VINOOTNEW}/api/treatment-category`);
      const activeCategories = response.data.filter(
        (category) => category.status === "active"
      );
      setCategories(activeCategories);
    } catch (error) {
      console.error("Error fetching category:", error);
    }finally {
      setIsLoading(false);
    }
  };

  // Fetch plans
  // const fetchPlans = async () => {
  //   try {
  //     const response = await axios.get(`${VINOOTNEW}/api/treatment-plan`);
  //     setPlans(response.data);
  //   } catch (error) {
  //     console.error("Error fetching plans:", error);
  //   }
  // };
  const fetchPlans = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${VINOOTNEW}/api/treatment-plan`);
      setPlans(response.data);
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 200 range
        console.error('Error fetching plans:', error.response.data);
        toast.error(`Error: ${error.response.data.message}`, {
          position: "top-right",
          autoClose: 2500,
        });
      } else if (error.request) {
        // No response was received
        console.error('No response from server:', error.request);
        toast.error('No response from server. Please check your network.', {
          position: "top-right",
          autoClose: 2500,
        });
      } else {
        // Something happened in setting up the request
        console.error('Error setting up the request:', error.message);
        toast.error('Unexpected error occurred. Please try again.', {
          position: "top-right",
          autoClose: 2500,
        });
      }
    }finally {
      setIsLoading(false);
    }
  };
  

  useEffect(() => {
    fetchCategory();
    fetchPlans();
  }, []);

  // Other event handlers
  const handleCategoryChange = (e) => setSelectedCategory(e.target.value);
  const handlePlanChange = (e) => {
    const inputValue = e.target.value;
    setPlan(inputValue);

    if (inputValue.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, plan: "" }));
      return;
    }

    const alphabeticRegex = /[a-zA-Z]{3,}/;
    if (!alphabeticRegex.test(inputValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        plan: "Plan must contain at least 3 alphabetic characters.",
      }));
    } else if (inputValue.length < 5 || inputValue.length > 250) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        plan: "Plan must be between 15 and 250 characters.",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, plan: "" }));
    }
  };

  const handleGSTChange = (e) => {
    const inputValue = e.target.value;
    setGST(inputValue);

    if (inputValue.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, gst: "" }));
      return;
    }

    const numericRegex = /^[1-9][0-9]?$/; // Updated regex for GST
    if (!numericRegex.test(inputValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        gst: "GST must be between 1 and 99.",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, gst: "" }));
    }
    calculateGSTAmount(price, inputValue); // Calculate GST amount
  };

  const handleDaysChange = (e) => {
    const inputValue = e.target.value;
    setDays(inputValue); // Fixed issue: setDays instead of setPrice
    
    if (inputValue.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, days: "" }));
      return;
    }
    
    const numericRegex = /^[1-9][0-9]{0,2}$/; // Updated regex for days
    if (!numericRegex.test(inputValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        days: "Days must be between 1 and 999.",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, days: "" }));
    }
  };

  const handlePriceChange = (e) => {
    const inputValue = e.target.value;
    setPrice(inputValue);

    if (inputValue.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, price: "" }));
      return;
    }

    const numericRegex = /^[1-9][0-9]{1,5}$/;
    if (!numericRegex.test(inputValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        price: "Price must be between 10 and 999999.",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, price: "" }));
    }
    calculateGSTAmount(inputValue, GST); // Calculate GST amount
  };

  // Calculate GST amount based on price and GST percentage
  const calculateGSTAmount = (price, gst) => {
    const gstAmount = (price * gst) / 100;
    setGSTamount(gstAmount);
    setTotalAmount(parseFloat(price) + parseFloat(gstAmount));
  };

  // Submit plan
  const handleSubmitPlan = async (e) => {
    e.preventDefault();
    // console.log("Submitting form...");
    if (errors.plan || errors.price || errors.days || errors.GST) {
      toast.error("Please fix the errors before submitting", {
        position: "top-right",
        autoClose: 1500,
      });
      return;
    }
    // Check if the plan name already exists
    const isPlanExist = plans.some(
      (plana) =>
        plana.plan_name.toLowerCase() === plan.toLowerCase() &&
        plana.plan_id !== editId // Compare with other plan IDs except the one being edited
    );

    if (isPlanExist) {
      toast.error("plan already exists. Please enter a unique plan name.", {
        position: "top-right",
        autoClose: 2500,
      });
      return;
    }
    try {
      
      // const noOfPlans = await axios.get(`${VINOOTNEW}/api/treatment-plan`);
      // const count = noOfPlans.data.length;

      // const plan_id = generateUniqueId(count + 0);
      if (editId) {
        // console.log("Updating plan");
        await axios.put(`${VINOOTNEW}/api/treatment-plan/${editId}`, {
          plan_name: plan,
          GST: GST,
          GSTamount: GSTamount,
          TotalAmount: TotalAmount,
          price: price,
          days: days,
          modifiedAt: presentTime,
          modifiedBy: userId, // Set modifiedBy field
        });
        toast.success("Treatment Plan Updated Successful", {
          position: "top-right",
          autoClose: 1500,
          // onClose: () => {
          //   // navigate("/TreatmentCategory");
          // },
        });
      } else {
        // console.log("Creating new plan...");
        const noOfPlans = await axios.get(`${VINOOTNEW}/api/treatment-plan`);
        const count = noOfPlans.data.length;

        const plan_id = generateUniqueId(count + 0);
        await axios.post(`${VINOOTNEW}/api/treatment-plan`, {
          plan_id: plan_id,
          category_name: selectedCategory,
          plan_name: plan,
          GST: GST,
          GSTamount: GSTamount,
          TotalAmount: TotalAmount,
          price: price,
          days: days,
          createdAt: presentTime,
          createdBy: userId, // Set createdBy field
          modifiedBy: userId, // Set modifiedBy field
          modifiedAt: presentTime, // Set modifiedAt field
          status: "active",
        });
        toast.success("Treatment Plan Added Successful", {
          position: "top-right",
          autoClose: 1500,
          // onClose: () => {
          //   // navigate("/TreatmentCategory");
          // },
        });
      }
      setPlan("");
      setPrice("");
      setSelectedCategory("");
      setEditId("");
      setGST("");
      setDays("");
      setGSTamount("");
      setTotalAmount("");
      fetchPlans();
    } catch (error) {
      console.error("Error submitting plan:", error);
      toast.error("An error occurred while submitting the plan.", {
        position: "top-right",
        autoClose: 2500,
      });
    }
  };

  // Generate unique ID
  const generateUniqueId = (count) => {
    const paddedCount = (count + 1).toString().padStart(3, "0");
    return "PLAN-" + paddedCount;
  };

  // Toggle status
  const toggleStatus = async (plan_id, category_name, currentStatus) => {
    try {
      const newStatus = currentStatus === "active" ? "inactive" : "active";

      await axios.put(`${VINOOTNEW}/api/treatment-plan/${plan_id}`, {
        status: newStatus,
        modifiedAt: presentTime, // Updated time
        modifiedBy: userId, // Updated user ID
      });
      if (currentStatus === "inactive") {
        toast.success(category_name + "Treatment plan Activated sucessfully.", {
          position: "top-right",
          autoClose: 800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(category_name + "Treatment plan Deactivated .", {
          position: "top-right",
          autoClose: 800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      fetchPlans(); // Refresh the plan list after status change
    } catch (error) {
      console.error("Error toggling status:", error);
    }
  };
  // Function to handle category editing
  const handleEdit = (
    plan_id,
    plan_name,
    GST,
    days,
    price,
    GSTamount,
    TotalAmount,
    category_name
  ) => {
    setEditId(plan_id);
    setPlan(plan_name);
    setPrice(price);
    setGST(GST);
    setDays(days);
    setGSTamount(GSTamount);
    setTotalAmount(TotalAmount);
    setSelectedCategory(category_name);
  };
  // Function to cancel category editing
  const handleCancelEdit = () => {
    setEditId("");
    setPlan("");
    setPrice("");
    setGST("");
    setDays("");
    setGSTamount("");
    setTotalAmount("");
    setSelectedCategory("");
  };

  // Pagination handlers
  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  // Get current plans
  // const indexOfLastPlan = currentPage * itemsPerPage;
  // const indexOfFirstPlan = indexOfLastPlan - itemsPerPage;
  // const currentPlans = plans.slice(indexOfFirstPlan, indexOfLastPlan);

  // Calculate total pages
  // const totalPages = Math.ceil(plans.length / itemsPerPage);

  return (
    <div>
      <div className="totalplan">
        <ToastContainer />
        <div>
          <Sidebar />
        </div>
        <div className="tplan-above-table1">
          <div className="tplan-above-table">
            <h1>Treatment Plan Master</h1>
            <div>
              <form className="super-regfrom" onSubmit={handleSubmitPlan}>
                <div className="tplan-total">
                  <div className="tplan-flex med_tplan-flex">
                    <label>
                      <select
                        className="tplan-input"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        placeholder=""
                        required
                      >
                        <option value="">Select </option>
                        {categories.map((item) => (
                          <option key={item._id} value={item.category_name}>
                            {item.category_name}
                          </option>
                        ))}
                      </select>
                      <span>
                        Select Category <span style={{ color: "red" }}>*</span>
                      </span>
                    </label>

                    <label>
                      <input
                        className="tplan-input"
                        type="text"
                        value={plan}
                        onChange={handlePlanChange}
                        placeholder=""
                        required
                      />
                      <span>
                        Treatement Plan <span style={{ color: "red" }}>*</span>
                       
                      </span>
                      {errors.plan && (
                      <div className="price-btn">{errors.plan}</div>
                      )}
                    </label>
                    <div className="tplan-inbetween">
                    <div className="tplan-tamount">
                      <label>
                        <input
                          className="tplan-input"
                          type="number"
                          value={price}
                          pattern="\d{6}"
                          maxLength="6"
                          onKeyDown={(evt) =>
                            (evt.key === "." ||
                              evt.key === "e" ||
                              evt.key === "E") &&
                            evt.preventDefault()
                          }
                          onChange={handlePriceChange}
                          required
                          placeholder=""
                        />
                        <span>
                          Price <span style={{ color: "red" }}>*</span>
                        </span>
                        {errors.price && (
                        <div className="price-btn">{errors.price}</div>
                      )}

                      </label>
                      </div>
                      <label>
                        <input
                          className="tplan-input"
                          type="number"
                          value={days}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onChange={handleDaysChange}
                          placeholder=""
                          required
                        />
                        <span>
                          No of Days <span style={{ color: "red" }}>*</span>
                      
                        </span>
                        {errors.days && (
                        <div className="price-btn">{errors.days}</div>
                      )}

                      </label>
                    </div>
                  
                    <div className="tplan-inbetween">
                      <label>
                        <input
                          className="tplan-input"
                          type="number"
                          value={GST}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onChange={handleGSTChange}
                          placeholder=""
                          required
                        />
                        <span>
                          GST(%) <span style={{ color: "red" }}>*</span>
                        </span>
                        {errors.GST && (
                        <div className="price-btn">{errors.GST}</div>
                      )}

                      </label>

                      <label>
                        <input
                          className="tplan-input"
                          type="number"
                          value={GSTamount}
                          placeholder=""
                          readOnly
                        />
                        <span>GST Amount</span>
                      </label>
                    </div>
                    <div className="tplan-inbetween">
                      <div className="tplan-tamount">
                      <label>
                        <input
                          className="tplan-input"
                          type="number"
                          value={TotalAmount}
                          placeholder=""
                          readOnly
                        />
                        <span>Total Amount</span>
                      </label>
                    </div>
                    </div>
                    {editId ? (
                      <div>
                        <button className="plan_save-btn" type="submit">
                          Save
                        </button>
                        <button
                          className="plan_cancel-btn"
                          type="button"
                          onClick={handleCancelEdit}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <button className="submit_tplan" type="submit">
                        Create
                      </button>
                    )}
                  </div>
                </div>
                {/* <button className="submit_tplan" type="submit">
                  Submit Plan
                </button> */}
                {/* {editId ? (
                  <div>
                    <button className="plan_save-btn" type="submit">
                      Save
                    </button>
                    <button
                      className="plan_cancel-btn"
                      type="button"
                      onClick={handleCancelEdit}>
                      Cancel
                    </button>
                  </div>
                ) : (
                  <button className="submit_tplan" type="submit">
                    Submit
                  </button>
                )} */}
              </form>
            </div>
          </div>

          <div className="tplan-below-table">
            <h2 className="plan_list_heading">Plans List</h2>
            <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-bar"
          />
           {isLoading ? (
              <p>Loading areas...</p>
            ) : (
            <table className="tabp">
              <thead>
                <tr><th>Category</th><th>Plan Name</th><th>Price</th><th>UpdatedTime</th><th>Status</th><th>Action</th><th>Edit</th></tr>
              </thead>
              <tbody>
                {filteredPlans
                  .map((plan) => (
                    <tr key={plan.plan_id}><td>{plan.category_name}</td><td>{plan.plan_name}</td><td>{plan.price}</td><td>
                      {new Date(plan.modifiedAt).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' })}{", "}
                      {new Date(plan.modifiedAt).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
                    </td><td
                        className={
                          plan.status === "active"
                            ? "button1-Active"
                            : "button1-Inactive"
                        }
                      >
                        {plan.status === "active" ? "Active" : "Inactive"}
                      </td><td>
                        <button
                          className={
                            plan.status === "active" ? "Inactive" : "Active"
                          }
                          onClick={() =>
                            toggleStatus(
                              plan.plan_id,
                              plan.category_name,
                              plan.status
                            )
                          }
                        >
                          {plan.status === "active" ? "Inactive" : "Active"}
                        </button>
                      </td><td>
                        <button
                          onClick={() =>
                            handleEdit(
                              plan.plan_id,
                              plan.plan_name,
                              plan.GST,
                              plan.days,
                              plan.price,
                              plan.GSTamount,
                              plan.TotalAmount,
                              plan.category_name
                            )
                          }
                        >
                          Edit
                        </button>
                      </td></tr>
                  ))}
              </tbody>
            </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreatmentPlan;
//             {/* 
// <div className="paginationss">
//   <span
//     onClick={() => handlePageChange(1)}
//     disabled={currentPage === 1}
//   >
//     <KeyboardDoubleArrowLeftIcon />
//   </span>
//   <span
//     onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
//     disabled={currentPage === 1}
//   >
//     <KeyboardArrowLeftIcon />
//   </span>
//   {Array.from({ length: 3 }, (_, index) => {
//     const startPage = Math.max(
//       Math.floor((currentPage - 1) / 3) * 3 + 1,
//       1
//     );
//     const pageNumber = startPage + index;

//     return (
//       pageNumber <= totalPages && (
//         <span
//           key={pageNumber}
//           onClick={() => handlePageChange(pageNumber)}
//           id={currentPage === pageNumber ? "pageactive-page" : ""}
//         >
//           {pageNumber}
//         </span>
//       )
//     );
//   })}
//   <span
//     onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
//     disabled={currentPage === totalPages}
//   >
//     <KeyboardArrowRightIcon />
//   </span>
//   <span
//     onClick={() => handlePageChange(totalPages)}
//     disabled={currentPage === totalPages}
//   >
//     <KeyboardDoubleArrowRightIcon />
//   </span>
// </div>  */}
        


