import React, { useState, useEffect } from "react";
import ReceptionSidebar from "./ReceptionSidebar/ReceptionSidebar";
import axios from "axios";
import "./Recepttion.css";
import { useNavigate } from "react-router-dom";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { FaFileCsv } from "react-icons/fa6";
import { VINOOTNEW } from "../../Helper/Helper";
const Reception = () => {
  const [billingData, setBillingData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(3);
  const [filters, setFilters] = useState({
    fromDate: "",
    toDate: "",
    mobileNumber: "",
    planType: "",
    patientname: "",
    remainingAmount: "",
  });

  const [errors, setErrors] = useState({
    mobileNumber: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBillingData = async () => {
     try {
        const frid = localStorage.getItem("franchiseID");
        if (frid) {
          const response = await axios.get(`${VINOOTNEW}/api/billing${frid}`);
          setBillingData(response.data);
        } else {
          console.error("FranchiseID not found in localStorage");
        }
      } catch (error) {
        console.error("Error fetching billing data:", error);
      }
    };
  
    fetchBillingData();
  }, []);
  

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDetailsClick = async (patientId,billNumber) => {
    // Fetch billing details based on franchise ID from local storage
    const frid = localStorage.getItem("franchiseID");
    if (frid) {
      // Navigate to the details page with franchise ID and patient ID as params
      navigate(`/showPatient/${frid}/${patientId}/${billNumber}`);
    } else {
      console.error("FranchiseID not found in localStorage");
    }
  };
  const filteredData = billingData.filter((billing) => {
    const remainingAmount = parseFloat(billing.remainingAmount);
    const filterRemainingValue = parseFloat(filters.remainingAmount);
    
    const totalAmount = parseFloat(billing.totalAmount); // Parse the totalAmount
    const filterTotalValue = parseFloat(filters.totalAmount); // Assume filters object has a totalAmount field
  
    const lowercaseName = filters.patientname.toLowerCase();
    const lowercaseBillingName = billing.patient_name?.toLowerCase() || ""; // Safeguard against undefined
    const currentDate = new Date(billing.currentDate);
    const fromDate = filters.fromDate ? new Date(filters.fromDate) : null;
    const toDate = filters.toDate ? new Date(filters.toDate) : null;
  
    return (
      (!fromDate || currentDate >= fromDate) &&
      (!toDate || currentDate <= toDate) &&
      (billing.mobile_number?.toString().includes(filters.mobileNumber) || false) && // Safeguard against undefined
      // (billing.plans?.some(plan => plan.plan_name.includes(filters.planType)) || false) && // Adjusted to check within plans array
      (!filters.planType || billing.plans.some(plan => plan.plan_name.toLowerCase().includes(filters.planType.toLowerCase()))) &&

       lowercaseBillingName.includes(lowercaseName) &&
      (isNaN(filterRemainingValue) || remainingAmount >= filterRemainingValue) &&
      (isNaN(filterTotalValue) || totalAmount >= filterTotalValue) // Correct filtering logic for totalAmount
    );
  });
  

  const indexOfLastPlan = currentPage * itemsPerPage;
  const indexOfFirstPlan = indexOfLastPlan - itemsPerPage;
  const currentPlans = filteredData.slice(indexOfFirstPlan, indexOfLastPlan);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });

    if (name === "mobileNumber") {
      const mobileNumberRegex = /^[0-9]{0,10}$/;
      const specialCharRegex = /[^0-9]/; // Regular expression to check for special characters

      if (specialCharRegex.test(value)) {
        setErrors({
          ...errors,
          mobileNumber: "Special characters are not allowed.",
        });
      } else if (!mobileNumberRegex.test(value)) {
        setErrors({
          ...errors,
          mobileNumber: "Invalid mobile number. Only 10 digits are allowed.",
        });
      } else {
        setErrors({ ...errors, mobileNumber: "" });
      }
    }

    if (name === "patientname") {
      const nameRegex = /^[a-zA-Z\s]{1,50}$/; // Regular expression to allow letters and spaces only, between 1 and 50 characters

      if (!nameRegex.test(value)) {
        setErrors({
          ...errors,
          patientname:
            "Invalid name. Only letters and spaces are allowed, up to 50 characters.",
        });
      } else {
        setErrors({ ...errors, patientname: "" });
      }
    }

    if (name === "remainingAmount") {
      const amountRegex = /^\d*\.?\d{0,2}$/; // Regular expression to allow positive numbers with up to two decimal places

      if (!amountRegex.test(value)) {
        setErrors({
          ...errors,
          remainingAmount:
            "Invalid amount. Only positive numbers with up to two decimal places are allowed.",
        });
      } else {
        setErrors({ ...errors, remainingAmount: "" });
      }
    }
  };

  const exportToCSV = () => {
    // Fetch userId (replace with actual method to get userId)
    const userId = localStorage.getItem("userId"); // Replace with actual logic to fetch userId

    // Format current date and time
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours() % 12 || 12;
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';
    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = `${hours}:${minutes} ${ampm}`;
    const dateTimeString = `${formattedDate}@${formattedTime}`;

    // Generate CSV content
    const csvData = currentPlans.map((billing) => ({
        Date: billing.currentDate,
        "Bill Number": billing.bill_number,
        "Patient ID": billing.patient_id,
        "Patient Name": billing.patient_name,
        "Patient Mobile Number": billing.mobile_number,
        Doctor: billing.doctor,
        Therapist: billing.therapist,
        "Plans": billing.plans?.map(plan => `${plan.plan_name} - ${plan.days} days, ${plan.TotalAmount}`).join(" | "),
        "Medicines": billing.medicines?.map(med => `${med.plan_name} - ${med.quantity} units, ${med.totalPrice}`).join(" | "),
        "Amount Paid": billing.amountPaid,
        "Remaining Amount": billing.remainingAmount,
    }));

    const csvContent =
        "data:text/csv;charset=utf-8," +
        Object.keys(csvData[0]).join(",") +
        "\n" +
        csvData.map((row) => Object.values(row).join(",")).join("\n");

    // Create a link element to trigger download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");

    // Set the download attribute with userId and current date/time
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${userId}_${dateTimeString}_billing_data.csv`);

    document.body.appendChild(link);

    // Trigger download
    link.click();
};

  

  return (
    <div className="recp-total">
      <div>
        <ReceptionSidebar />
      </div>
      <div className="recp-dash-table">
        <div className="recep-dash-fi">
          <h1>Patients Billing Details</h1>
          <button onClick={exportToCSV}>
            <FaFileCsv className="xlsiocn2" />
          </button>
          {/* <button  onClick={exportToCSV}><FaFileCsv/></button> */}
        </div>
        <div className="recep-dash-filter">
          <div className="recep-dash-filter-1">
            <label>
              <span>From Date:</span>
              <input
                type="date"
                name="fromDate"
                value={filters.fromDate}
                onChange={handleFilterChange}
              />
            </label>
            <label>
              <span>To Date:</span>
              <input
                type="date"
                name="toDate"
                value={filters.toDate}
                onChange={handleFilterChange}
              />
            </label>
          </div>
          <div className="recep-dash-filter-11">
            <label>
              <span>Mobile Number:</span>
              <input
                type="text"
                name="mobileNumber"
                value={filters.mobileNumber}
                onChange={handleFilterChange}
              />
              {errors.mobileNumber && (
                <span className="error" style={{ color: "red" }}>
                  {errors.mobileNumber}
                </span>
              )}
            </label>
            <label>
              <span>Name</span>
              <input
                type="text"
                name="patientname"
                value={filters.patientname}
                onChange={handleFilterChange}
              />
              {errors.patientname && (
                <span className="error" style={{ color: "red" }}>
                  {errors.patientname}
                </span>
              )}
            </label>
            <label>
              <span>Plan Type:</span>
              <input
                type="text"
                name="planType"
                value={filters.planType}
                onChange={handleFilterChange}
              />
            </label>
            <label>
              <span>Remaining Amount above:</span>
              <input
                type="text"
                name="remainingAmount"
                value={filters.remainingAmount}
                onChange={handleFilterChange}
              />
              {errors.remainingAmount && (
                <span className="error" style={{ color: "red" }}>
                  {errors.remainingAmount}
                </span>
              )}
            </label>
          </div>
        </div>
        <div className="patientBillingTable_pagination">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Bill Number</th>
              <th>Patient ID</th>
              <th>Patient Name</th>
              <th>Mobile No.</th>
              <th>Doctor</th>
              <th>Services</th>
              <th>Medicines</th>
              {/* <th>Total Amount</th> */}
              <th>Amount Paid</th>
              <th>Remaining Amount</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
              {currentPlans.map((billing) => (
                <tr key={billing._id}>
                  <td>{billing.currentDate}</td>
                  <td>{billing.bill_number}</td>
                  <td>{billing.patient_id}</td>
                  <td>{billing.patient_name}</td>
                  <td>{billing.mobile_number}</td>
                  <td>{billing.doctor}</td>
                  <td>
                    {billing.plans && billing.plans.length > 0 && billing.plans.some(plan => plan.plan_name.trim() !== "")
                      ? billing.plans.map(plan => (
                          <div key={plan._id}>
                            {plan.plan_name && plan.plan_name.trim() !== "" ? plan.plan_name : "nil"} - 
                            {plan.days ? `${plan.days} days` : "nil"}, 
                            {plan.TotalAmount ? plan.TotalAmount : "nil"}
                          </div>
                        ))
                      : (
                        <div>nil</div> // Display "nil" if no valid plans are available
                      )}
                  </td>
                  <td>
                  {billing.medicines && billing.medicines.map(med => (
                    <div key={med._id}>
                      {med.plan_name} - {med.quantity ? `${med.quantity} units` : 'nil'}, {med.totalPrice} 
                    </div>
                  ))}

                  </td>
                  {/* <td>{billing.totalPrice}</td> */}
                  <td>{billing.amountPaid}</td>
                  <td>{billing.remainingAmount}</td>
                  <td>
                    <button
                      onClick={() =>
                        handleDetailsClick(
                          billing.patient_id,
                          billing.bill_number
                        )
                      }>
                      Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>

        </table>



        <div className="paginationss">
          <span onClick={() => handlePageChange(1)}>
            <KeyboardDoubleArrowLeftIcon />
          </span>
          <span onClick={() => handlePageChange(currentPage - 1)}>
            <KeyboardArrowLeftIcon />
          </span>
          {[...Array(totalPages)].map((_, index) => (
            <span
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={currentPage === index + 1 ? "pageactive-page" : ""}>
              {index + 1}
            </span>
          ))}
          <span onClick={() => handlePageChange(currentPage + 1)}>
            <KeyboardArrowRightIcon />
          </span>
          <span onClick={() => handlePageChange(totalPages)}>
            <KeyboardDoubleArrowRightIcon />
          </span>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Reception;

