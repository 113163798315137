import React, { useEffect } from "react";
import ReactModal from "react-modal";
import "./MasterUserInfo.css";

const MasterUserInfo = ({ user, isOpen, onClose }) => {
  useEffect(() => {
    if (!user) return null;
  });

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="info_usermodal_popup"
      overlayClassName="info_usermodal_popup_overlay"
    >
      <div className="info_usermodal_container">
        <h2>
          Details for <span>{user.fullName}</span>
        </h2>
        <ol>
          <li>
            <strong>User ID :</strong> {user.userId}
          </li>
          <li>
            <strong>Email :</strong> {user.email}
          </li>
          <li>
            <strong>Mobile No :</strong> {user.phoneNumber}
          </li>
          <li>
            <strong>Password :</strong> {user.password}
          </li>
          <li>
            <strong>Date of Birth :</strong> {user.dateOfBirth}
          </li>
          <li>
            <strong>Gender :</strong> {user.gender}
          </li>
          <li>
            <strong>Status :</strong>{" "}
            <span className={user.isActive ? "" : "inactive"}>
              {user.isActive ? "Active" : "Inactive"}
            </span>
          </li>
        </ol>
      </div>
    </ReactModal>
  );
};

export default MasterUserInfo;
