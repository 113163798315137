
import React, { useState, useEffect } from "react";
import axios from "axios";
// import "./Addpatient.css";
import { VINOOTNEW } from "../../../Helper/Helper";
import { useNavigate } from "react-router-dom";

const Patientdetails1 = () => {
  const [patients, setPatients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fetchPatients();
  }, []);

  const fetchPatients = async () => {
    try {
      const frid = localStorage.getItem("franchiseID");
      if (frid) {
        const response = await axios.get(
          `${VINOOTNEW}/api/patients/franchise/${frid}`
        );
        setPatients(response.data);
      } else {
        console.error("FranchiseID not found in localStorage");
      }
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };

  const formatDate = (dob) => {
    const date = new Date(dob);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleRowClick = (patientId) => {
    navigate(`/treatment/${patientId}`);
  };

  const filteredPatients = patients.filter((patient) =>
    patient.patient_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
    patient.patient_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    patient.mobile_number.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div id="doctorpatientdetails-container">
      <div id="doctorpatientdetails-search">
        <input
          type="text"
          placeholder="Search by Patient ID, Name, or Mobile Number"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          id="doctorpatientdetails-search-input"
        />
      </div>
      <div id="doctorpatientdetails-table-container">
        <table id="doctorpatientdetails-table">
          <thead>
            <tr>
              <th>Patient ID</th>
              <th>Name</th>
              <th>Gender</th>
              <th>Date of Birth</th>
              <th>Email</th>
              <th>Mobile Number</th>
              {/* <th>State</th>
              <th>City</th>
              <th>Area</th> */}
              <th>Address</th>
              <th>Created By</th>
            </tr>
          </thead>
          <tbody>
            {filteredPatients.map((patient) => (
              <tr key={patient._id} onClick={() => handleRowClick(patient.patient_id)}>
                <td>{patient.patient_id}</td>
                <td>{patient.patient_name}</td>
                <td>{patient.gender}</td>
                <td>{formatDate(patient.dob)}</td>
                <td>{patient.email}</td>
                <td>{patient.mobile_number}</td>
                {/* <td>{patient.state}</td>
                <td>{patient.city}</td>
                <td>{patient.area}</td> */}
                <td>{patient.address}</td>
                <td>{patient.createdBy}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Patientdetails1;


