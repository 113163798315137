import React, { useState } from "react";
// import './DoctorSidebar.css';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { RiBillFill } from "react-icons/ri";
import { TbListDetails } from "react-icons/tb";
//import { IoPersonAddSharp } from "react-icons/io5";
//import FranchiseLoginDetailsIcon from "../../Franchiseadmin/FranchiseLoginDetailsIcon";

const DoctorSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const navigate = useNavigate();
  const handleLogOut = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div>
      {/*<FranchiseLoginDetailsIcon />*/}

      <div className={`side-bar-container ${isOpen ? "open" : ""}`}>
        <div className="v-logo">
          <img
            src="https://static.wixstatic.com/media/87b37c_4f087e673951454d870325c82063ae31~mv2.png/v1/fill/w_627,h_600,al_c/87b37c_4f087e673951454d870325c82063ae31~mv2.png"
            alt="logo"
          />
        </div>
        <div className="sidebar-list-items">
          <ul className="first-lists">
          <Link to="/DoctorAppointments" className="sidebar-links">
              {" "}
              <li
                className={activeItem === "DoctorAppointments" ? "active" : ""}
                onClick={() => handleItemClick("DoctorAppointments")}>
                <RiBillFill className="icon" />
                Book Appointments
              </li>{" "}
            </Link>
            
            <Link to="/DoctorTogglepatients" className="sidebar-links">
              <li
                className={activeItem === "/DoctorTogglepatients" ? "active" : ""}
                onClick={() => handleItemClick("/DoctorTogglepatients")}
              >
                <TbListDetails className="icon" />
                Add Patient{" "}
              </li>
            </Link>
            <Link to="/DoctorDashboard" className="sidebar-links">
              <li
                className={activeItem === "dashboard" ? "active" : ""}
                onClick={() => handleItemClick("dashboard")}
              >
                <TbListDetails className="icon" />
                Today's Appointments
              </li>
            </Link>
            {/* <Link to="/DoctorBilling"  className="sidebar-links">
              <li
                className={activeItem === "/DoctorBilling" ? "active" : ""}
                onClick={() => handleItemClick("/DoctorBilling")}
              >
                <TbListDetails className="icon" />
                Billing
              </li>
            </Link> */}
            <Link to="/Billing"  className="sidebar-links">
              <li
                className={activeItem === "/Billing" ? "active" : ""}
                onClick={() => handleItemClick("/Billing")}
              >
                <TbListDetails className="icon" />
                Billing 
              </li>
            </Link>
            
            <Link to="/DoctorReception" className="sidebar-links">
              <li
                className={activeItem === "/DoctorReception" ? "active" : ""}
                onClick={() => handleItemClick("/DoctorReception")}
              >
                <TbListDetails className="icon" />
                Billing Details
              </li>
            </Link>
            <Link to="/ViewHistory" className="sidebar-links">
              <li
                className={activeItem === "/ViewHistory" ? "active" : ""}
                onClick={() => handleItemClick("/ViewHistory")}
              >
                <TbListDetails className="icon" />
                View History
              </li>
            </Link>

          
            {/*<Link to="/AppointmentDetails" className="sidebar-links">
              {" "}
              <li
                className={activeItem === "Appointments" ? "active" : ""}
                onClick={() => handleItemClick("Appointments")}>
                <RiBillFill className="icon" />
                Appointments
              </li>{" "}
            </Link>*/}
            {/* <Link to="/TreatmentCategory" className='sidebar-links' > <li className={activeItem === 'treatmentcat' ? 'active' : ''} onClick={() => handleItemClick('treatmentcat')}><IoIosPeople className='icon' />Treatment Category</li></Link>
                       <Link to="/TreatmentPlan" className='sidebar-links' > <li className={activeItem === 'treatmentplan' ? 'active' : ''} onClick={() => handleItemClick('treatmentplan')}><IoIosPeople className='icon' />Treatment Plan</li></Link>
                       <Link to="/States" className='sidebar-links' > <li className={activeItem === 'states' ? 'active' : ''} onClick={() => handleItemClick('states')}><IoIosPeople className='icon' />States</li></Link>
                       <Link to="/Cities" className='sidebar-links' > <li className={activeItem === 'cities' ? 'active' : ''} onClick={() => handleItemClick('cities')}><IoIosPeople className='icon' />Cities</li></Link>
                       <Link to="/Area" className='sidebar-links' ><li className={activeItem === 'areas' ? 'active' : ''} onClick={() => handleItemClick('areas')}><IoIosPeople className='icon' />Areas</li></Link> */}
            <Link to="/" className="sidebar-links">
              {" "}
              <li
                className={activeItem === "logout" ? "active" : ""}
                onClick={() => handleLogOut() || handleItemClick("logout")}
              >
                <MdLogout className="icon" />
                Logout
              </li>
            </Link>
          </ul>
          {/* <div className='side-bar-second-lists'>
                        <h5>Accounts Pages</h5>
                        <ul className='second-lists'>
                            <li className={activeItem === 'notification' ? 'active' : ''} onClick={() => handleItemClick('notification')}><FaBell className='icon' />Notification</li>
                            <li className={activeItem === 'profile' ? 'active' : ''} onClick={() => handleItemClick('profile')}><IoPerson className='icon' />Profile</li>
                        </ul>
                    </div>
                    <div className='documentaion-container'>
                        <div className='question-box'>
                            <FaQuestionCircle className='question-icon' />
                        </div>
                        <div className='need-help'>
                            <span>Need Help ?</span>
                        </div>
                        <div className='docs'>
                            <span>Please Check our docs</span>
                        </div>
                        <div className='documentaion-btn'>
                            <button>DOCUMENTATION </button>
                        </div>
                    </div> */}
        </div>
      </div>
      <div className="hamburger-menu" onClick={toggleSidebar}>
        <div className={`line ${isOpen ? "open" : ""}`}></div>
        <div className={`line ${isOpen ? "open" : ""}`}></div>
        <div className={`line ${isOpen ? "open" : ""}`}></div>
      </div>
    </div>
  );
};

export default DoctorSidebar;
