// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.allfr-pattotal{
    display: flex;
}

.allfr-right{
    margin-left: 18.2%;
    margin-top: 2%;
    table{
        border-collapse: collapse;
        text-align: center;
    }
    th{
        text-align: center;
        padding: 8px;
        font-size: 0.9rem;
        color: white;
        background-color: #0AA506;
      }
       td{
        text-align: center;
        padding: 8px;
        font-size: 0.9rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/MasterLogin/Franchisepatients/Franchisepatients.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd;QACI,yBAAyB;QACzB,kBAAkB;IACtB;IACA;QACI,kBAAkB;QAClB,YAAY;QACZ,iBAAiB;QACjB,YAAY;QACZ,yBAAyB;MAC3B;OACC;QACC,kBAAkB;QAClB,YAAY;QACZ,iBAAiB;IACrB;AACJ","sourcesContent":[".allfr-pattotal{\n    display: flex;\n}\n\n.allfr-right{\n    margin-left: 18.2%;\n    margin-top: 2%;\n    table{\n        border-collapse: collapse;\n        text-align: center;\n    }\n    th{\n        text-align: center;\n        padding: 8px;\n        font-size: 0.9rem;\n        color: white;\n        background-color: #0AA506;\n      }\n       td{\n        text-align: center;\n        padding: 8px;\n        font-size: 0.9rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
