// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FranchiseAdmintable.css */

/* Table styles */
.franchise-details{
  display: flex;
}
.franchise-admin-rights{
  margin-left: 18.5%;
  margin-top: 5%;
}
.tabf {
    border-collapse: collapse;
    width:80vw ;
    border: 1px solid gray;

    
  }
  
  .tabf th {
    text-align: center;
    padding: 7px;
    font-size: 0.7rem;
    color: white;
    background-color: #0AA506;
  }
  thead{
    position: sticky;
    top:0;
    /* z-index: 1; */
  }

    
  tbody {
    display: block;
    max-height: 300px; /* Adjust this height as needed */
    overflow-y: scroll;
    width: 100%;
  }

  thead, tbody tr{
    display: table;
width: 100%;
table-layout: fixed;
  }

  .tabf td{
    text-align: center;
    padding: 7px;
    font-size: 0.7rem;
  }
  
  .farnchiseadmin-activebtn {
    border: none;
    outline: none;
    padding: 10px 7px 10px 7px;
    border-radius: 10px;
    color: #fff;
    font-size: 0.7rem;
    transform: .3s ease;
    background-color: #0AA506;
  }
  
  .farnchiseadmin-activebtn:hover {
    background-color: #0d390c;
  }
  /* Sidebar styles */
  /* Assuming you have styling for the Sidebar component already */
  `, "",{"version":3,"sources":["webpack://./src/MasterLogin/Franchisedetails/FranchiseAdmintable.css"],"names":[],"mappings":"AAAA,4BAA4B;;AAE5B,iBAAiB;AACjB;EACE,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,cAAc;AAChB;AACA;IACI,yBAAyB;IACzB,WAAW;IACX,sBAAsB;;;EAGxB;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,yBAAyB;EAC3B;EACA;IACE,gBAAgB;IAChB,KAAK;IACL,gBAAgB;EAClB;;;EAGA;IACE,cAAc;IACd,iBAAiB,EAAE,iCAAiC;IACpD,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,cAAc;AAClB,WAAW;AACX,mBAAmB;EACjB;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;EACnB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,0BAA0B;IAC1B,mBAAmB;IACnB,WAAW;IACX,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;EACA,mBAAmB;EACnB,gEAAgE","sourcesContent":["/* FranchiseAdmintable.css */\n\n/* Table styles */\n.franchise-details{\n  display: flex;\n}\n.franchise-admin-rights{\n  margin-left: 18.5%;\n  margin-top: 5%;\n}\n.tabf {\n    border-collapse: collapse;\n    width:80vw ;\n    border: 1px solid gray;\n\n    \n  }\n  \n  .tabf th {\n    text-align: center;\n    padding: 7px;\n    font-size: 0.7rem;\n    color: white;\n    background-color: #0AA506;\n  }\n  thead{\n    position: sticky;\n    top:0;\n    /* z-index: 1; */\n  }\n\n    \n  tbody {\n    display: block;\n    max-height: 300px; /* Adjust this height as needed */\n    overflow-y: scroll;\n    width: 100%;\n  }\n\n  thead, tbody tr{\n    display: table;\nwidth: 100%;\ntable-layout: fixed;\n  }\n\n  .tabf td{\n    text-align: center;\n    padding: 7px;\n    font-size: 0.7rem;\n  }\n  \n  .farnchiseadmin-activebtn {\n    border: none;\n    outline: none;\n    padding: 10px 7px 10px 7px;\n    border-radius: 10px;\n    color: #fff;\n    font-size: 0.7rem;\n    transform: .3s ease;\n    background-color: #0AA506;\n  }\n  \n  .farnchiseadmin-activebtn:hover {\n    background-color: #0d390c;\n  }\n  /* Sidebar styles */\n  /* Assuming you have styling for the Sidebar component already */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
