import { useState } from "react";
import React from 'react'
import './Loginicon.css'

import { FaRegCircleUser } from "react-icons/fa6";

const FranchiseLoginDetailsIcon = () => {

    const [showDropdown, setShowDropdown] = useState(false);
    // const createdBy = localStorage.getItem("userId");
    const franchiseName = localStorage.getItem("franchisename");
    const franchiseiD = localStorage.getItem("franchiseID");
    const userId = localStorage.getItem("userId");
    const doctorName= localStorage.getItem("fullname");

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
      };
    
  return (
    <div className="container_vik">
    <div onClick={toggleDropdown} className="container-vik">
        <FaRegCircleUser />
    </div>
    {showDropdown && (
        <div className="vik">
          <p>Branch Name: {franchiseName}</p>
          <p>Branch ID: {franchiseiD}</p>
          <p>Doctor ID: {userId}</p>
          <p>Doctor Name: {doctorName}</p>
        </div>
      )}
    </div>
  )
}

export default FranchiseLoginDetailsIcon
