import React, { useEffect, useState } from "react";
import axios from "axios";
import "./MasterUserT.css";
// import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
// import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { VINOOTNEW } from "../../Helper/Helper";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditUserModal from "./EditUserModal.js";
import MasterUserInfo from "./MasterUserInfo.jsx"

const MasterUserT = () => {
  const [users, setUsers] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage] = useState(3);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isInfo, setIsInfo] = useState(false); // Control info state
  const [infoUser, setInfoUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);




  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      // console.log("Fetching users...");
      const response = await axios.get(`${VINOOTNEW}/api/users`);
      setUsers(response.data);
      // console.log("Users fetched successfully:", response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }finally {
      setIsLoading(false);
    }

  };

  const toggleActiveState = async (id, fullname, isActive) => {
    try {
      // console.log(`Toggling active state for user: ${fullname} (ID: ${id})`);
      const updatedBy = localStorage.getItem("userId");
      await axios.patch(`${VINOOTNEW}/api/users/${id}`, {
        isActive: !isActive,
        updatedBy,
      });
      toast[isActive ? "error" : "success"](
        `${fullname} ${isActive ? "Deactivated" : "Activated"} successfully.`,
        {
          position: "top-right",
          autoClose: 900,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        }
      );
      fetchUsers();
    } catch (error) {
      console.error("Error updating active state:", error);
    }
  };

  // const handlePageChange = (pageNumber) => {
  //   console.log(`Changing page to: ${pageNumber}`);
  //   if (pageNumber >= 1 && pageNumber <= totalPages) {
  //     setCurrentPage(pageNumber);
  //   }
  // };

  
  const handleRowClick = (event, user) => {
    if (event.target.tagName === 'BUTTON') {
      return; // Do nothing if a button was clicked
    }

    const excludedCells = ['no-click-cell', 'another-no-click-cell'];
    const clickedElement = event.target;

    if (excludedCells.some(className => clickedElement.classList.contains(className))) {
      return;
    }

    handleInfo(user);
  };

  
  const handleInfo = (user) => {
    setSelectedUser(null);
    setInfoUser(user);
    setSelectedUser(false);
    setIsInfo(true);
  };

  const handleCancelInfo = () => {
    setModalOpen(false);
    setInfoUser(null);
  };

  const handleEdit = (user) => {
    // console.log("Edit button clicked for user:", user);
    setInfoUser(null);
    setSelectedUser(user);
    setIsInfo(false);
    setModalOpen(true);
    // console.log("Selected user set, modal open state set to true.");
  };

  const handleUpdate = async (updatedUser) => {
    const updatedBy = localStorage.getItem("userId");

    try {
      const response = await axios.patch(
        `${VINOOTNEW}/api/users/${updatedUser._id}`,
        {
          fullName: updatedUser.fullName,
          phoneNumber: updatedUser.phoneNumber,
          email: updatedUser.email,
          password: updatedUser.password,
          updatedBy,
        }
      );
      console.log("Update response:", response.data); // Log the respons
      setModalOpen(false);
      toast.success("SuperAdmin details updated successfully.", {
        position: "top-right",
        autoClose: 900,
        theme: "light",
      });

      // console.log("Modal closed after update");
      fetchUsers(); // Ensure this fetches the latest data
    } catch (error) {
      console.error("Error updating user details:", error);
      toast.error("Failed to update SuperAdmin details.", {
        position: "top-right",
        autoClose: 900,
        theme: "light",
      });
    }
  };

  const handleCancel = () => {
    setModalOpen(false);
    setSelectedUser(null);
  };

  // const indexOfLastUser = currentPage * itemsPerPage;
  // const indexOfFirstUser = indexOfLastUser - itemsPerPage;

  // const filteredUsers = users.filter(user =>
  //   (user.fullName.toLowerCase().includes(searchQuery.toLowerCase())) ||
  //   ( user.userId.toLowerCase().includes(searchQuery.toLowerCase())) || 
  //   (user.gender.toLowerCase().includes(searchQuery.toLowerCase())) ||
  //    (user.email.toLowerCase().includes(searchQuery.toLowerCase())) || 
  //   (user.phoneNumber.toLowerCase().includes(searchQuery.toLowerCase())) ||
  //   (user.dateOfBirth.toLowerCase().includes(searchQuery.toLowerCase()))
  // );
  const filteredUsers = users.filter(user => 
    (user.fullName?.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (user.userId?.toLowerCase().includes(searchQuery.toLowerCase())) || 
    (user.gender?.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (user.email?.toLowerCase().includes(searchQuery.toLowerCase())) || 
    (user.phoneNumber?.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (user.dateOfBirth?.toLowerCase().includes(searchQuery.toLowerCase()))
  );
  // const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  // const totalPages = Math.ceil(users.length / itemsPerPage);

  return (
    <>
      <div>
        <ToastContainer />
        <div className="mastertable_pagination_parent">

        <div >
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="search-baaa"
            />
          </div>
        <div  className="masterusers-table">
        {isLoading ? (
              <p>Loading areas...</p>
            ) : (
        <table>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>User ID</th>
              <th>Email</th>
              <th>Mobile No.</th>
              <th>Password</th>
              {/* <th>DOB</th>
              <th>Gender</th> */}
              <th>Status</th>
              <th>Action</th>
              <th>Changed By</th>
              <th>Edit/Update</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr onClick={(event) => handleRowClick(event, user)} key={user._id}>
              <td>{user.fullName}</td>
                <td>{user.userId}</td>
                <td>{user.email}</td>
                <td>{user.phoneNumber}</td>
                <td>{user.password}</td>
                {/* <td>{user.dateOfBirth}</td>
                <td>{user.gender}</td> */}
                {/* <td>{user.isActive ? "Active" : "Inactive"}</td> */}
                <td className={user.isActive ? "Activate1" : "Deactivate1"}>
                  {user.isActive ? "Active" : "Inactive"}
                </td>
                <td className="master-user-action no-click-cell">
                  <button className={user.isActive  ? "Deactivate3" : "Activate3"}
                    onClick={() =>
                      toggleActiveState(user._id, user.fullName, user.isActive)
                    }
                  >
                    {user.isActive ? "Inactive" : "Active"}
                  </button>
                </td>
                <td>{user.modifiedBy}</td>
                <td className="master-user-edit another-no-click-cell">
                  <button onClick={() => handleEdit(user)}>Edit</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
            )}
            </div>

          {/* <div className="paginationss">
            <span
              onClick={() => handlePageChange(1)}
              className={currentPage === 1 ? "disabled" : ""}
            >
              <KeyboardDoubleArrowLeftIcon />
            </span>
            <span
              onClick={() => handlePageChange(currentPage - 1)}
              className={currentPage === 1 ? "disabled" : ""}
            >
              <KeyboardArrowLeftIcon />
            </span>
            {[...Array(totalPages)].map((_, index) => (
              <span
                key={index}
                onClick={() => handlePageChange(index + 1)}
                id={currentPage === index + 1 ? "pageactive-page" : ""}
              >
                {index + 1}
              </span>
            ))}
            <span
              onClick={() => handlePageChange(currentPage + 1)}
              className={currentPage === totalPages ? "disabled" : ""}
            >
              <KeyboardArrowRightIcon />
            </span>
            <span
              onClick={() => handlePageChange(totalPages)}
              className={currentPage === totalPages ? "disabled" : ""}
            >
              <KeyboardDoubleArrowRightIcon />
            </span>
          </div> */}

          {isInfo && infoUser && (
            <MasterUserInfo
              user={infoUser}
              isOpen={isInfo}
              onClose={handleCancelInfo }
            />
          )}

          {modalOpen && selectedUser && (
            <EditUserModal
              user={selectedUser}
              isOpen={modalOpen}
              onClose={handleCancel}
              onUpdate={handleUpdate}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MasterUserT;
