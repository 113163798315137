// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fra-adm-togg-total {
  display: flex;
}
.fra-adm-togg-right {
  margin-left: 19%;
  margin-top: 1.7vh;
  display: flex;
  flex-direction: column;
}
.fra-1234{
  margin-right: 2%;
  
  /* margin-top: 2px; */
}
.fra-adm-togg-r1 {
  display: flex;
  /* padding: 2px; */
 /* text-align: center; */
 /* margin-bottom: 2px; */
 
}
.admin-toggle-button123 {
  margin-top: 4.7vh;
  margin-bottom: 2px;
  height: 35px;
  width: 18vw;
  margin-left: 40vw;
  padding: 2px;
  background-color: #0aa506;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  /* align-items: center; */
  justify-content: center;
  text-align: center;
}
.admintog-icon {
  margin-left: 10px;
  font-size: 20px;
  align-items: center;
  margin-bottom: 1.2vh;
}
`, "",{"version":3,"sources":["webpack://./src/MasterLogin/FranchiseAdmin-s/AdminTogglebutton.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,gBAAgB;;EAEhB,qBAAqB;AACvB;AACA;EACE,aAAa;EACb,kBAAkB;CACnB,wBAAwB;CACxB,wBAAwB;;AAEzB;AACA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,yBAAyB;EACzB,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".fra-adm-togg-total {\n  display: flex;\n}\n.fra-adm-togg-right {\n  margin-left: 19%;\n  margin-top: 1.7vh;\n  display: flex;\n  flex-direction: column;\n}\n.fra-1234{\n  margin-right: 2%;\n  \n  /* margin-top: 2px; */\n}\n.fra-adm-togg-r1 {\n  display: flex;\n  /* padding: 2px; */\n /* text-align: center; */\n /* margin-bottom: 2px; */\n \n}\n.admin-toggle-button123 {\n  margin-top: 4.7vh;\n  margin-bottom: 2px;\n  height: 35px;\n  width: 18vw;\n  margin-left: 40vw;\n  padding: 2px;\n  background-color: #0aa506;\n  border: none;\n  border-radius: 5px;\n  color: white;\n  font-weight: 600;\n  /* align-items: center; */\n  justify-content: center;\n  text-align: center;\n}\n.admintog-icon {\n  margin-left: 10px;\n  font-size: 20px;\n  align-items: center;\n  margin-bottom: 1.2vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
