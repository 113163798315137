import React, { useState, useEffect } from "react";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import the default styles for React Toastify
import "./Plan.css";
import { VINOOTNEW } from "../../Helper/Helper";
import Sidebar from "../../Masterdata/Sidebar/Sidebar";

// import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
// import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const ManageMedicine = () => {
  const [categories, setCategories] = useState([]);
  const [plans, setPlans] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [plan, setPlan] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  // const [GST, setGST] = useState("");
  // const [GSTamount, setGSTamount] = useState("");
  // const [TotalAmount, setTotalAmount] = useState("");
  // const [days, setDays] = useState("");
  const [price, setPrice] = useState("");
  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage] = useState(3);

  const [errors, setErrors] = useState({  
    plan: "",
    price: "",
    // GST: "",
    // gstamount: "",
    // totalamount: "",
    // days: "",
  });
  const [editId, setEditId] = useState(""); // Category ID being edited
  const presentTime = new Date().toLocaleString();
  // const navigate = useNavigate();
  const userId = localStorage.getItem("userId");

  // Fetch categories
  const fetchCategory = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${VINOOTNEW}/api/medicine-category`);
      const activeCategories = response.data.filter(
        (category) => category.status === "active"
      );
      setCategories(activeCategories);
    } catch (error) {
      console.error("Error fetching category:", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  // Fetch plans
  const fetchPlans = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${VINOOTNEW}/api/manage-medicine`);
      setPlans(response.data);
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCategory();
    fetchPlans();
  }, []);

  // Other event handlers
  const handleCategoryChange = (e) => setSelectedCategory(e.target.value);
  const handlePlanChange = (e) => {
    const inputValue = e.target.value;
    setPlan(inputValue);

    if (inputValue.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, plan: "" }));
      return;
    }

    const alphabeticRegex = /[a-zA-Z]{3,}/;
    if (!alphabeticRegex.test(inputValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        plan: "Medicine must contain at least 3 alphabetic characters.",
      }));
    } else if (inputValue.length < 3 || inputValue.length > 250) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        plan: "medicine must be between 15 and 250 characters.",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, plan: "" }));
    }
  };

  const handlePriceChange = (e) => {
    const inputValue = e.target.value;
    setPrice(inputValue);

    if (inputValue.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, price: "" }));
      return;
    }

    const numericRegex = /^[1-9][0-9]{1,5}$/;
    if (!numericRegex.test(inputValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        price: "Price must be between 10 and 999999.",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, price: "" }));
    }
    // calculateGSTAmount(inputValue, GST); // Calculate GST amount
  };

  // Calculate GST amount based on price and GST percentage
  // const calculateGSTAmount = (price, gst) => {
  //   const gstAmount = (price * gst) / 100;
  //   setGSTamount(gstAmount);
  //   setTotalAmount(parseFloat(price) + parseFloat(gstAmount));
  // };

  // Submit plan
  const handleSubmitPlan = async (e) => {
    e.preventDefault();
    if (errors.plan || errors.price || errors.days || errors.GST) {
      toast.error("Please fix the errors before submitting", {
        position: "top-right",
        autoClose: 1500,
      });
      return;
    }
    // Check if the plan name already exists
    const isPlanExist = plans.some(
      (plana) =>
        plana.plan_name.toLowerCase() === plan.toLowerCase() &&
        plana.plan_id !== editId // Compare with other plan IDs except the one being edited
    );

    if (isPlanExist) {
      toast.error("plan already exists. Please enter a unique plan name.", {
        position: "top-right",
        autoClose: 2500,
      });
      return;
    }
    try {
      if (editId) {
        await axios.put(`${VINOOTNEW}/api/manage-medicine/${editId}`, {
          plan_name: plan,
          // GST: GST,
          // GSTamount: GSTamount,
          // TotalAmount: TotalAmount,
          price: price,
          // days: days,
          modifiedAt: presentTime,
          modifiedBy: userId, // Set modifiedBy field
        });
        toast.success("Medicine details Updated Successful", {
          position: "top-right",
          autoClose: 1500,
          // onClose: () => {
          //   // navigate("/TreatmentCategory");
          // },
        });
      } else {
        const noOfPlans = await axios.get(`${VINOOTNEW}/api/manage-medicine`);
        const count = noOfPlans.data.length;
        const plan_id = generateUniqueId(count + 0);

        await axios.post(`${VINOOTNEW}/api/manage-medicine`, {
          plan_id: plan_id,
          category_name: selectedCategory,
          plan_name: plan,
          // GST: GST,
          // GSTamount: GSTamount,
          // TotalAmount: TotalAmount,
          price: price,
          // days: days,
          createdAt: presentTime,
          createdBy: userId, // Set createdBy field
          modifiedBy: userId, // Set modifiedBy field
          modifiedAt: presentTime, // Set modifiedAt field
          status: "active",
        });
        toast.success("Medicine Added Successful", {
          position: "top-right",
          autoClose: 1500,
          // onClose: () => {
          //   // navigate("/TreatmentCategory");
          // },
        });
      }
      setPlan("");
      setPrice("");
      setSelectedCategory("");
      setEditId("");
      fetchPlans();
    } catch (error) {
      console.error(error);
    }
  };

  // Generate unique ID
  const generateUniqueId = (count) => {
    const paddedCount = (count + 1).toString().padStart(3, "0");
    return "MED-" + paddedCount;
  };

  // Toggle status
  const toggleStatus = async (plan_id, category_name, currentStatus) => {
    try {
      const newStatus = currentStatus === "active" ? "inactive" : "active";

      await axios.put(`${VINOOTNEW}/api/manage-medicine/${plan_id}`, {
        status: newStatus,
        modifiedAt: presentTime, // Updated time
        modifiedBy: userId, // Updated user ID
      });
      if (currentStatus === "inactive") {
        toast.success(category_name + "Medicine Activated sucessfully.", {
          position: "top-right",
          autoClose: 800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(category_name + "Medicine Deactivated .", {
          position: "top-right",
          autoClose: 800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      fetchPlans(); // Refresh the plan list after status change
    } catch (error) {
      console.error("Error toggling status:", error);
    }
  };
  // Function to handle category editing
  const handleEdit = (
    plan_id,
    plan_name,
    // GST,
    // days,
    price,
    // GSTamount,
    // TotalAmount,
    category_name
  ) => {
    setEditId(plan_id);
    setPlan(plan_name);
    setPrice(price);
    // setGST(GST);
    // setDays(days);
    // setGSTamount(GSTamount);
    // setTotalAmount(TotalAmount);
    setSelectedCategory(category_name);
  };
  // Function to cancel category editing
  const handleCancelEdit = () => {
    setEditId("");
    setPlan("");
    setPrice("");
    // setGST("");
    // setDays("");
    // setGSTamount("");
    // setTotalAmount("");
    setSelectedCategory("");
  };

  // Pagination handlers
  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  // Get current plans
  // const indexOfLastPlan = currentPage * itemsPerPage;
  // const indexOfFirstPlan = indexOfLastPlan - itemsPerPage;
  // const currentPlans = filteredPlans.slice(indexOfFirstPlan, indexOfLastPlan);

  // Calculate total pages
  // const totalPages = Math.ceil(plans.length / itemsPerPage);
 const filteredPlans = plans.filter((plan) =>
    plan.category_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    plan.plan_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    plan.price.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    plan.modifiedAt.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <div>
      <div className="totalmanage">
        <ToastContainer />
        <div>
          <Sidebar />
        </div>
        <div className="manage-above-table1">
          <div className="manage-above-table">
            <h1>Manage Medicine Master</h1>
            <div>
              <form className="super-regfrom" onSubmit={handleSubmitPlan}>
                <div className="manage-total">
                  <div className="manage-flex med_manage-flex">
                    <label>
                      <select
                        className="manage-input"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        placeholder=""
                        required
                      >
                        <option value="">Select </option>
                        {categories.map((item) => (
                          <option key={item._id} value={item.category_name}>
                            {item.category_name}
                          </option>
                        ))}
                      </select>
                      <span>
                        Select Medicine Category{" "}
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    </label>

                    <label>
                      <input
                        className="manage-input"
                        type="text"
                        value={plan}
                        onChange={handlePlanChange}
                        placeholder=""
                        required
                      />

                      <span>
                        Medicine Name <span style={{ color: "red" }}>*</span>
                  
                      </span>
                      {errors.plan && (
                      <div className="price-btn">{errors.plan}</div>
                    )}
                    </label>

                    <div className="manage-inbetween">
                      <label className="manage_price">
                        <input
                          className="manage-input"
                          type="number"
                          value={price}
                          pattern="\d{6}"
                          maxLength="6"
                          onKeyDown={(evt) =>
                            (evt.key === "." ||
                              evt.key === "e" ||
                              evt.key === "E") &&
                            evt.preventDefault()
                          }
                          onChange={handlePriceChange}
                          required
                          placeholder=""
                        />
                        <span>
                          Price <span  style={{ color: "red" }}>*</span>
                          
                        </span>
                        {errors.price && (
                        <div  className="price-btn">{errors.price}</div>
                      )}
                      </label>
                    </div>

                    {editId ? (
                      <div>
                        <button className="manage_save-btn" type="submit">
                          Save
                        </button>
                        <button
                          className="manage_cancel-btn"
                          type="button"
                          onClick={handleCancelEdit}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <button className="submit_manage" type="submit">
                        Create
                      </button>
                    )}
                  </div>
                </div>

                {/* <button className="submit_tplan" type="submit">
                  Submit Plan
                </button> */}
              </form>
            </div>
          </div>
          <div className="tplan-below-table">
            <h2 className="plan_list_heading">Medicines List</h2>
                    <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-bar"
        />
              {isLoading ? (
              <p>Loading areas...</p>
            ) : (
            <table className="tabp">
              <thead>
                <tr><th>Category</th><th>Medicine Name</th><th>Price</th><th>UpdatedTime</th><th>Status</th><th>Action</th><th>Edit</th></tr>
              </thead>
              <tbody>
                {filteredPlans.map((plan) => (
                  <tr key={plan.plan_id}><td>{plan.category_name}</td><td>{plan.plan_name}</td><td>{plan.price}</td><td>
                      {new Date(plan.modifiedAt).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' })}{", "}
                      {new Date(plan.modifiedAt).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
                    </td><td
                      className={
                        plan.status === "active"
                          ? "button1-Active"
                          : "button1-Inactive"
                      }
                    >
                      {plan.status === "active" ? "Active" : "Inactive"}
                    </td><td>
                      <button
                        className={
                          plan.status === "active" ? "Inactive" : "Active"
                        }
                        onClick={() =>
                          toggleStatus(
                            plan.plan_id,
                            plan.category_name,
                            plan.status
                          )
                        }
                      >
                        {plan.status === "active" ? "Inactive" : "Active"}
                      </button>
                    </td><td>
                      <button
                        onClick={() =>
                          handleEdit(
                            plan.plan_id,
                            plan.plan_name,
                            // plan.GST,
                            // plan.days,
                            plan.price,
                            // plan.GSTamount,
                            // plan.TotalAmount,
                            plan.category_name
                          )
                        }
                      >
                        Edit
                      </button>
                    </td></tr>
                ))}
              </tbody>
            </table>
            )}
        </div>
        </div>
      </div>
    </div>
  );
};

export default ManageMedicine;
//             {/* <div className="paginationss">
//   <span
//     onClick={() => handlePageChange(1)}
//     disabled={currentPage === 1}
//   >
//     <KeyboardDoubleArrowLeftIcon />
//   </span>
//   <span
//     onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
//     disabled={currentPage === 1}
//   >
//     <KeyboardArrowLeftIcon />
//   </span>
//   {Array.from({ length: 3 }, (_, index) => {
//     const startPage = Math.max(
//       Math.floor((currentPage - 1) / 3) * 3 + 1,
//       1
//     );
//     const pageNumber = startPage + index;

//     return (
//       pageNumber <= totalPages && (
//         <span
//           key={pageNumber}
//           onClick={() => handlePageChange(pageNumber)}
//           id={currentPage === pageNumber ? "pageactive-page" : ""}
//         >
//           {pageNumber}
//         </span>
//       )
//     );
//   })}
//   <span
//     onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
//     disabled={currentPage === totalPages}
//   >
//     <KeyboardArrowRightIcon />
//   </span>
//   <span
//     onClick={() => handlePageChange(totalPages)}
//     disabled={currentPage === totalPages}
//   >
//     <KeyboardDoubleArrowRightIcon />
//   </span>
// </div> */}
        
