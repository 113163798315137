import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import the default styles for React Toastify
// import { useNavigate } from "react-router-dom";
import { VINOOTNEW } from "../../Helper/Helper";
import "./Category.css";
import Sidebar from "../../Masterdata/Sidebar/Sidebar";

// import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
// import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const MedicineCategory = () => {
  // const navigate = useNavigate();

  const [value, setValue] = useState("");
  const [categories, setCategories] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [categoriesPerPage] = useState(2); // Number of categories per page
  const presentTime = new Date().toLocaleString();
  const [error, setError] = useState("");
  const [editId, setEditId] = useState(""); // Category ID being edited
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);



  const filteredCategories = categories.filter(category =>
    (category.category_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (category.modifiedAt.toLowerCase().includes(searchTerm.toLowerCase())) 
    // || (category.status.toLowerCase().includes(searchTerm.toLowerCase())) 


  );

  const userId = localStorage.getItem("userId");

  // Function to fetch existing categories
  const fetchCategories = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${VINOOTNEW}/api/medicine-category`);
      setCategories(response.data); // Assuming the response contains an array of categories
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories(); // Fetch categories when the component mounts
  }, []); // Empty dependency array ensures this effect runs once on mount

  const handleSelect = (event) => {
    const inputValue = event.target.value.trim();
    setValue(inputValue);
    
    if (inputValue === "") {
      setError(""); // Clear the error if the input is empty
    } else {
    const alphabeticRegex = /[a-zA-Z]{3,}/;
    if (!alphabeticRegex.test(inputValue)) {
      setError("Category must contain at least 3 alphabetic characters.");
    } else if (inputValue.length < 3 || inputValue.length > 100) {
      setError("Text length must be between 3 and 100 characters.");
    } else {
      setError("");
    }
  }
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (error) {
      toast.error("Please fix the errors before submitting", {
        position: "top-right",
        autoClose: 1500,
      });
      return;
    }
    // Check if the category name already exists
    const isCategoryExist = categories.some(
      (category) => category.category_name.toLowerCase() === value.toLowerCase()
    );

    if (isCategoryExist) {
      toast.error(
        "Category already exists. Please enter a unique category name.",
        {
          position: "top-right",
          autoClose: 2500,
        }
      );
      return;
    }
    try {
      const noOfCategories = await axios.get(
        `${VINOOTNEW}/api/treatment-category`
      );
      const count = noOfCategories.data.length;

      const category_id = generateUniqueId(value, count + 1);
      if (editId) {
        await axios.put(`${VINOOTNEW}/api/medicine-category/${editId}`, {
          // category_id: category_id,
          category_name: value,
          modifiedAt: presentTime,
          modifiedBy: userId,
          // status: "active", // Default status is active
        });
        toast.success("Category Updated Successful", {
          position: "top-right",
          autoClose: 1500,
          onClose: () => {
            // navigate("/Managemedicine");
          },
        });
      } else {
        await axios.post(`${VINOOTNEW}/api/medicine-category`, {
          category_id: category_id,
          category_name: value,
          createdAt: presentTime,
          status: "active", // Default status is active
          createdBy: userId, // Set createdBy field

          modifiedBy: userId, // Set modifiedBy field
          modifiedAt: presentTime, // Set modifiedAt field
        });
        toast.success("Category Added Successful", {
          position: "top-right",
          autoClose: 1500,
          onClose: () => {
            // navigate("/Managemedicine");
          },
        });
      }

      setValue("");
      setEditId("");
      fetchCategories(); // Refresh the category list
    } catch (error) {
      console.error("Error saving option:", error);
    }
  };

  // Function to generate unique ID
  const generateUniqueId = (name, count) => {
    const abbreviation = name.substring(0, 3).toUpperCase();
    const paddedCount = (count + 1).toString().padStart(3, "0");
    const id = "MCAT-" + abbreviation + "-" + paddedCount;
    return id;
  };

  // Function to toggle status and update the category
  const toggleStatus = async (category_id, category_name, currentStatus) => {
    try {
      const newStatus = currentStatus === "active" ? "inactive" : "active";

      await axios.put(`${VINOOTNEW}/api/medicine-category/${category_id}`, {
        status: newStatus,
        modifiedAt: presentTime, // Updated time
        modifiedBy: userId, // Set modifiedBy field
      });
      if (currentStatus === "inactive") {
        toast.success(category_name + "medicine Activated sucessfully.", {
          position: "top-right",
          autoClose: 800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(category_name + "medicine Deactivated .", {
          position: "top-right",
          autoClose: 800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      fetchCategories(); // Refresh the category list after status change
    } catch (error) {
      console.error("Error toggling status:", error);
    }
  };
  // Function to handle category editing
  const handleEdit = (category_id, category_name) => {
    setEditId(category_id);
    setValue(category_name);
  };
  // Function to cancel category editing
  const handleCancelEdit = () => {
    setEditId("");
    setValue("");
  };

  // Pagination handlers
  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  // Get current categories
  // const indexOfLastCategory = currentPage * categoriesPerPage;
  // const indexOfFirstCategory = indexOfLastCategory - categoriesPerPage;
  // const currentCategories = filteredCategories.slice(
  //   indexOfFirstCategory,
  //   indexOfLastCategory
  // );

  // Calculate total pages
  // const totalPages = Math.ceil(categories.length / categoriesPerPage);

  return (
    <div className="total-tcategory">
      <ToastContainer />{" "}
      <div>
        <Sidebar />
      </div>
      <div className="treat-cat-right">
        <h1 className="h1111">Medicine Category Master</h1>
        <form action="" onSubmit={handleSubmit} className="cat-form">
         {/* <div> */}
          <label htmlFor="tar">
            Medicine Category <span style={{ color: "red" }}>*</span>
          </label>
          <label>
          <input
            type="text"
            id="tar"
            value={value}
            onChange={handleSelect}
            required
          />
          {error &&( <div className="price-btn">{error}</div>)}
          </label>
          {editId ? (
            // <div className="save_cancel">
            <label>
              <button className="medicinecategory_save-btn" type="submit">
                Save
              </button>
              <button
                className="medicinecategory_cancel-btn"
                type="button"
                onClick={handleCancelEdit}
              >
                Cancel
              </button>
            {/* // </div> */}
            </label>
          ) : (
            <button className="medicinecategory_submit-btn" type="submit">
              Create
            </button>
          )}
        </form>
        <div className="categorytable_pagination_parent">
          <h2 className="category_list_heading">Categories List</h2>
          <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="searcch-input"
            />

          <div className="category_table">
          {isLoading ? (
              <p>Loading areas...</p>
            ) : (
            <table>
              <thead>
                <tr>
                  <th>Category Name</th>
                  <th>UpdatedTime</th>
                  <th>Status</th>
                  <th>Action</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {filteredCategories.map((category) => (
                  <tr key={category.category_id}><td>{category.category_name}</td><td>
                      {new Date(category.modifiedAt).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' })}{", "}
                      {new Date(category.modifiedAt).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
                    </td><td
                      className={
                        category.status === "active"
                          ? "button1_Active"
                          : "button1_Inactive"
                      }
                    >
                      {category.status === "active" ? "Active" : "Inactive"}
                    </td><td>
                      <button
                        className={
                          category.status === "active"
                            ? "button_Inactive"
                            : "button_Active"
                        }
                        onClick={() =>
                          toggleStatus(
                            category.category_id,
                            category.category_name,
                            category.status
                          )
                        }
                      >
                        {category.status === "active" ? "Inactive" : "Active"}
                      </button>
                    </td><td>
                      <button
                        className="treatcat-btn1"
                        onClick={() =>
                          handleEdit(
                            category.category_id,
                            category.category_name
                          )
                        }
                      >
                        Edit
                      </button>
                    </td></tr>
                ))}
              </tbody>
            </table>
            )}
          </div>
          </div>
      </div>
    </div>
  );
};

export default MedicineCategory;

          // {/* <div className="paginationss">
          //   <span
          //     onClick={() => handlePageChange(1)}
          //     disabled={currentPage === 1}
          //   >
          //     <KeyboardDoubleArrowLeftIcon />
          //   </span>
          //   <span
          //     onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
          //     disabled={currentPage === 1}
          //   >
          //     <KeyboardArrowLeftIcon />
          //   </span>
          //   {Array.from({ length: 3 }, (_, index) => {
          //     const startPage = Math.max(
          //       Math.floor((currentPage - 1) / 3) * 3 + 1,
          //       1
          //     );
          //     const pageNumber = startPage + index;

          //     return (
          //       pageNumber <= totalPages && (
          //         <span
          //           key={pageNumber}
          //           onClick={() => handlePageChange(pageNumber)}
          //           id={
          //             currentPage === pageNumber ? "pageactive-page" : ""
          //           }
          //         >
          //           {pageNumber}
          //         </span>
          //       )
          //     );
          //   })}
          //   <span
          //     onClick={() =>
          //       handlePageChange(Math.min(currentPage + 1, totalPages))
          //     }
          //     disabled={currentPage === totalPages}
          //   >
          //     <KeyboardArrowRightIcon />
          //   </span>
          //   <span
          //     onClick={() => handlePageChange(totalPages)}
          //     disabled={currentPage === totalPages}
          //   >
          //     <KeyboardDoubleArrowRightIcon />
          //   </span>
          // </div> */}
       
