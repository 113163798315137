// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useLocation, useNavigate } from "react-router-dom";
// import "./AppointmentDetails.css";
// import { VINOOTNEW } from "../../Helper/Helper";
// import Loginicon from "./LoginiconDash/Loginicon";

// const AppointmentDetails = () => {
//   const [appointments, setAppointments] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const appointmentsPerPage = 10;
//   const [searchTerm, setSearchTerm] = useState("");
//   const [error, setError] = useState(null); // Add error state
//   const location = useLocation();

//   useEffect(() => {
//     fetchAppointments();
//   }, [location]);

//   const fetchAppointments = async () => {
//     try {
//       const userId = localStorage.getItem("userId");
//       const response = await axios.get(`${VINOOTNEW}/api/appointments`);

//       const today = new Date();
//       const todayYear = today.getFullYear();
//       const todayMonth = today.getMonth() + 1;
//       const todayDate = today.getDate();

//       // Filter appointments by doctorId and status and also today's date
//       const appointmentList = response.data.filter((app) => {
//         const appointmentDate = new Date(app.appointmentDate);
//         const appointmentYear = appointmentDate.getFullYear();
//         const appointmentMonth = appointmentDate.getMonth() + 1;
//         const appointmentDay = appointmentDate.getDate();

//         return (
//           app.doctorId === userId &&
//           app.status === "scheduled" &&
//           appointmentYear === todayYear &&
//           appointmentMonth === todayMonth &&
//           appointmentDay === todayDate
//         );
//       });

//       setAppointments(appointmentList);
//     } catch (error) {
//       setError(error.message);
//       console.error("Error fetching appointments:", error);
//     }
//   };

//   const formatDate = (appointment) => {
//     const appointmentDate = new Date(appointment.appointmentDate);
//     const day = String(appointmentDate.getDate()).padStart(2, "0");
//     const month = String(appointmentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
//     const year = String(appointmentDate.getFullYear());
//     return `${day}-${month}-${year}`;
//   };

//   const navigate = useNavigate();

//   const handleProceedTreatment = (appointment) => {
//     const { patientId } = appointment;
//     navigate(`/treatment/${patientId}`);
//   };

//   const totalPages = Math.ceil(appointments.length / appointmentsPerPage);

//   const handleClick = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   const getPaginationRange = () => {
//     const visiblePages = 10;
//     const startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
//     const endPage = Math.min(totalPages, startPage + visiblePages - 1);

//     const pages = [];

//     if (startPage > 1) {
//       pages.push(1);
//       if (startPage > 2) {
//         pages.push("...");
//       }
//     }

//     for (let i = startPage; i <= endPage; i++) {
//       pages.push(i);
//     }

//     if (endPage < totalPages) {
//       if (endPage < totalPages - 1) {
//         pages.push("...");
//       }
//       pages.push(totalPages);
//     }

//     return pages;
//   };

//   const handleSearch = (e) => {
//     setSearchTerm(e.target.value);
//     setCurrentPage(1);
//   };

//   const filteredAppointments = appointments.filter(
//     (app) =>
//       (app.patientName &&
//         app.patientName.toLowerCase().includes(searchTerm.toLowerCase())) ||
//       (app.mobileNumber && app.mobileNumber.includes(searchTerm)) ||
//       (app.patientId && app.patientId.includes(searchTerm))
//   );

//   const paginatedAppointments = filteredAppointments.slice(
//     (currentPage - 1) * appointmentsPerPage,
//     currentPage * appointmentsPerPage
//   );

//   return (
//     <div>
//       <div className="app-head">
//         <div className="search-container">
//           <input
//             type="text"
//             className="search-input"
//             placeholder="Search"
//             value={searchTerm}
//             onChange={handleSearch}
//           />
//           <button className="search-button">Search</button>
//         </div>
//         <div className="icon">
//           <Loginicon />
//         </div>
//       </div>
//       <div className="app-body">
//         <h2 className="h2">Today's Appointments</h2>
//         <div>
//           <h6>Sheduled Appointments : {appointments.length}</h6>
//         </div>
//         {error && <div className="error">{error}</div>}
//         <table className="app-table">
//           <thead>
//             <tr>
//               <th>Patient Name</th>
//               <th>Patient ID</th>
//               <th>Mobile Number</th>
//               <th>Treatment</th>
//               <th>Date</th>
//               <th>Time</th>
//               <th>Proceed</th>
//             </tr>
//           </thead>
//           <tbody>
//             {paginatedAppointments.map((appointment, index) => (
//               <tr key={index}>
//                 <td>{appointment.patientName}</td>
//                 <td>{appointment.patientId}</td>
//                 <td>{appointment.mobileNumber}</td>
//                 <td>{appointment.treatmentTypes}</td>
//                 <td>{formatDate(appointment)}</td>{" "}
//                 <td>{appointment.timeSlot}</td>
//                 <td>
//                   <button
//                     className="pro-btn"
//                     onClick={() => handleProceedTreatment(appointment)}
//                   >
//                     Proceed Treatment
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//       <div className="pagination">
//         <button
//           onClick={() => handleClick(currentPage - 1)}
//           disabled={currentPage === 1}
//         >
//           &lt;
//         </button>
//         {getPaginationRange().map((page, index) => (
//           <button
//             key={index}
//             onClick={() => page !== "..." && handleClick(page)}
//             className={currentPage === page ? "active" : ""}
//             disabled={page === "..."}
//           >
//             {page}
//           </button>
//         ))}
//         <button
//           onClick={() => handleClick(currentPage + 1)}
//           disabled={currentPage === totalPages}
//         >
//           &gt;
//         </button>
//       </div>
//     </div>
//   );
// };

// export default AppointmentDetails;






import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "./AppointmentDetails.css";
import { VINOOTNEW } from "../../Helper/Helper";
import Loginicon from "./LoginiconDash/Loginicon";

const AppointmentDetails = () => {
  const [appointments, setAppointments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const appointmentsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    fetchAppointments();
  }, [location]);

  const fetchAppointments = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.get(`${VINOOTNEW}/api/appointments`);

      const today = new Date();
      const todayYear = today.getFullYear();
      const todayMonth = today.getMonth() + 1;
      const todayDate = today.getDate();

      // Filter appointments by doctorId, status, and today's date
      const appointmentList = response.data.filter((app) => {
        const appointmentDate = new Date(app.appointmentDate);
        return (
          app.doctorId === userId &&
          app.status === "scheduled" &&
          appointmentDate.getFullYear() === todayYear &&
          appointmentDate.getMonth() + 1 === todayMonth &&
          appointmentDate.getDate() === todayDate
        );
      });

      setAppointments(appointmentList);
    } catch (error) {
      setError(error.message);
      console.error("Error fetching appointments:", error);
    }
  };

  const formatDate = (appointment) => {
    const appointmentDate = new Date(appointment.appointmentDate);
    const day = String(appointmentDate.getDate()).padStart(2, "0");
    const month = String(appointmentDate.getMonth() + 1).padStart(2, "0");
    const year = String(appointmentDate.getFullYear());
    return `${day}-${month}-${year}`;
  };

  const navigate = useNavigate();

  const handleProceedTreatment = (appointment) => {
    const { patientId } = appointment;
    navigate(`/treatment/${patientId}`);
  };

  const totalPages = Math.ceil(appointments.length / appointmentsPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPaginationRange = () => {
    const visiblePages = 10;
    const startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
    const endPage = Math.min(totalPages, startPage + visiblePages - 1);

    const pages = [];
    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) pages.push("...");
    }
    for (let i = startPage; i <= endPage; i++) pages.push(i);
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) pages.push("...");
      pages.push(totalPages);
    }
    return pages;
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredAppointments = appointments.filter(
    (app) =>
      (app.patientName &&
        app.patientName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (app.mobileNumber && app.mobileNumber.includes(searchTerm)) ||
      (app.patientId && app.patientId.includes(searchTerm))
  );

  const paginatedAppointments = filteredAppointments.slice(
    (currentPage - 1) * appointmentsPerPage,
    currentPage * appointmentsPerPage
  );

  return (
    <div>
      <div className="app-head">
        <div className="search-container">
          <input
            type="text"
            className="search-input"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div className="icon">
          <Loginicon />
        </div>
      </div>
      <div className="app-body">
        <h2 className="h2">Today's Appointments</h2>
        <div>
          <h6>Scheduled Appointments: {appointments.length}</h6>
        </div>
        {error && <div className="error">{error}</div>}
        {appointments.length === 0 && <p>No appointments found for today.</p>}
        <table className="app-table">
          <thead>
            <tr>
              <th>Patient Name</th>
              <th>Patient ID</th>
              <th>Mobile Number</th>
              <th>Treatment</th>
              <th>Date</th>
              <th>Time</th>
              <th>Proceed</th>
            </tr>
          </thead>
          <tbody>
            {paginatedAppointments.map((appointment, index) => (
              <tr key={index}>
                <td>{appointment.patientName}</td>
                <td>{appointment.patientId}</td>
                <td>{appointment.mobileNumber}</td>
                <td>{appointment.treatmentTypes}</td>
                <td>{formatDate(appointment)}</td>
                <td>{appointment.timeSlot}</td>
                <td>
                  <button
                    className="pro-btn"
                    onClick={() => handleProceedTreatment(appointment)}
                  >
                    Proceed Treatment
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {filteredAppointments.length > 0 && (
        <div className="pagination">
          <button
            onClick={() => handleClick(currentPage - 1)}
            disabled={currentPage === 1}
          >
            &lt;
          </button>
          {getPaginationRange().map((page, index) => (
            <button
              key={index}
              onClick={() => page !== "..." && handleClick(page)}
              className={currentPage === page ? "active" : ""}
              disabled={page === "..."}
            >
              {page}
            </button>
          ))}
          <button
            onClick={() => handleClick(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            &gt;
          </button>
        </div>
      )}
    </div>
  );
};

export default AppointmentDetails;
