// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container_vik {
  position: fixed;
  top: 20px; /* Adjust top position as needed */
  right: 100px; /* Adjust right position as needed */
  z-index: 999; /* Ensure it's above other content */
  font-size: 26px;
  color:#3e3d3d;

.container-vik {
  cursor: pointer;
}

.vik {
  position: absolute;
  top: 30px; /* Adjust dropdown position as needed */
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure dropdown is above other content */
  width:max-content; 
  font-size: 16px;
}

}`, "",{"version":3,"sources":["webpack://./src/Franchisemanagement/Doctor/LoginiconDash/Loginicon.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS,EAAE,kCAAkC;EAC7C,YAAY,EAAE,oCAAoC;EAClD,YAAY,EAAE,oCAAoC;EAClD,eAAe;EACf,aAAa;;AAEf;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,SAAS,EAAE,uCAAuC;EAClD,QAAQ;EACR,sBAAsB;EACtB,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,wCAAwC;EACxC,aAAa,EAAE,2CAA2C;EAC1D,iBAAiB;EACjB,eAAe;AACjB;;AAEA","sourcesContent":[".container_vik {\n  position: fixed;\n  top: 20px; /* Adjust top position as needed */\n  right: 100px; /* Adjust right position as needed */\n  z-index: 999; /* Ensure it's above other content */\n  font-size: 26px;\n  color:#3e3d3d;\n\n.container-vik {\n  cursor: pointer;\n}\n\n.vik {\n  position: absolute;\n  top: 30px; /* Adjust dropdown position as needed */\n  right: 0;\n  background-color: #fff;\n  border: 1px solid #ccc;\n  padding: 10px;\n  border-radius: 5px;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n  z-index: 1000; /* Ensure dropdown is above other content */\n  width:max-content; \n  font-size: 16px;\n}\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
