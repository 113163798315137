
import React, { useState, useEffect } from "react";
import "./ViewHistory.css";
import DoctorSidebar from "../DoctorSB/DoctorSidebar";
import { VINOOTNEW } from "../../../Helper/Helper";
import Loginicon from "../LoginiconDash/Loginicon";

function ViewHistory() {
  const [searchTerm, setSearchTerm] = useState("");
  const [patients, setPatients] = useState([]);
  const [, setCurrentPage] = useState(1);
  //const [selectedPatient, setSelectedPatient] = useState(null);

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const response = await fetch(`${VINOOTNEW}/api/PatientHistory?userId=${userId}`);
        const data = await response.json();
  
        // console.log("Fetched data:", data);
  
        if (Array.isArray(data)) {
          // Filter only today's completed appointments
          const today = new Date();
          const startOfDay = new Date(today.setHours(0, 0, 0, 0)).getTime();
          const endOfDay = new Date(today.setHours(23, 59, 59, 999)).getTime();

          const todayCompleted = data.filter((patient) => {
            const completedAt = new Date(patient.completedAt).getTime();
            return completedAt >= startOfDay && completedAt <= endOfDay;
          });

          setPatients(todayCompleted);
        } else {
          console.error("Expected an array, but got:", data);
          setPatients([]);
        }
      } catch (error) {
        console.error("Error fetching patient data:", error);
        setPatients([]);
      }
    };
  
    fetchPatients();
  }, []);
  

  const filteredPatients = patients.filter(
    (patient) =>
      patient.patientId.toLowerCase().includes(searchTerm.toLowerCase()) ||
      patient.patientName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      patient.mobileNumber.includes(searchTerm)
  );

  //const handleRowClick = (patient) => {
  //  setSelectedPatient(patient);
  //};

  //const closeModal = () => {
  //  setSelectedPatient(null);
  //};

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  return (
    <div>
      <DoctorSidebar />
      <div className="container">
        <div className="app-head">
          <div className="search-container">
            <input
              type="text"
              className="search-input"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
            <button className="search-button">Search</button>
          </div>
          <div className="icon">
            <Loginicon />
          </div>
        </div>
        <div>
        <h2 className="h2">Patient Details</h2>
          <table className="patient-history-table">
            <thead>
              <tr>
                <th>Patient ID</th>
                <th>Name</th>
                <th>Mobile Number</th>
                <th>Status</th>
                <th>Doctor ID</th>
              </tr>
            </thead>
            <tbody>
              {filteredPatients.map((patient) => (
                <tr
                  key={patient.patientId}
                  //onClick={() => handleRowClick(patient)}
                >
                  <td>{patient.patientId}</td>
                  <td>{patient.patientName}</td>
                  <td>{patient.mobileNumber}</td>
                  <td>{patient.status}</td>
                  <td>{patient.userId}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/*{selectedPatient && (
            <div className="patient-details-modal">
              <div className="modal-content">
                <h2>Patient Details</h2>
                <p>
                  <strong>Patient ID:</strong> {selectedPatient.patientId}
                </p>
                <p>
                  <strong>Patient Name:</strong> {selectedPatient.patientName}
                </p>
                <p>
                  <strong>Mobile Number:</strong> {selectedPatient.mobileNumber}
                </p>
                <p>
                  <strong>Doctor ID:</strong> {selectedPatient.userId}
                </p> 
                
                <h3>Vitals</h3>
                {selectedPatient.vitals ? (
                  <>
                    <p>
                      <strong>SPO2:</strong>{" "}
                      {selectedPatient.vitals.spo2 || "N/A"}
                    </p>
                    <p>
                      <strong>Blood Pressure:</strong>{" "}
                      {selectedPatient.vitals.bp || "N/A"}
                    </p>
                    <p>
                      <strong>Sugar:</strong>{" "}
                      {selectedPatient.vitals.sugar || "N/A"}
                    </p>
                    <p>
                      <strong>Allergies:</strong>{" "}
                      {selectedPatient.vitals.allergies || "N/A"}
                    </p>
                    <p>
                      <strong>Temperature:</strong>{" "}
                      {selectedPatient.vitals.temperature || "N/A"}
                    </p>
                    <p>
                      <strong>Weight:</strong>{" "}
                      {selectedPatient.vitals.weight || "N/A"}
                    </p>
                    <p>
                      <strong>Oxygen Saturation:</strong>{" "}
                      {selectedPatient.vitals.oxygenSaturation || "N/A"}
                    </p>
                  </>
                ) : (
                  <p>Vitals information not available</p>
                )}

                <h3>Treatment</h3>
                <p>
                  <strong>Category:</strong>{" "}
                  {selectedPatient.treatmentCategory || "N/A"}
                </p>
                <p>
                  <strong>Plan:</strong>{" "}
                  {selectedPatient.treatmentPlan || "N/A"}
                </p>
                <p>
                  <strong>Days:</strong>{" "}
                  {selectedPatient.treatmentDays || "N/A"}
                </p>

                <h3>Medicines</h3>
                {selectedPatient.medicines &&
                selectedPatient.medicines.length > 0 ? (
                  selectedPatient.medicines.map((medicine, index) => (
                    <div key={index}>
                      <p>
                        <strong>Medicine Name:</strong>{" "}
                        {medicine.medicineName || "N/A"}
                      </p>
                      <p>
                        <strong>Frequency:</strong>{" "}
                        {medicine.frequency || "N/A"}
                      </p>
                      <p>
                        <strong>Duration:</strong>{" "}
                        {medicine.duration || "N/A"}
                      </p>
                      <p>
                        <strong>When to Take:</strong>{" "}
                        {medicine.whenToTake || "N/A"}
                      </p>
                    </div>
                  ))
                ) : (
                  <p>No medicines prescribed</p>
                )}

                <h3>Notes</h3>
                <p>
                  <strong>Advice:</strong>{" "}
                  {selectedPatient.notes?.advice || "N/A"}
                </p>
                <p>
                  <strong>Diet and Exercise:</strong>{" "}
                  {selectedPatient.notes?.dietAndExercise || "N/A"}
                </p>
                <p>
                  <strong>Summary:</strong>{" "}
                  {selectedPatient.notes?.summary || "N/A"}
                </p>

                <h3>Date of Visit</h3>
                <p>
                  <strong>Completed At:</strong> {selectedPatient.completedAt || "N/A"}
                </p>

                <button onClick={closeModal} className="close-button">
                  Close
                </button>
              </div>
            </div>
          )}*/}
        </div>
      </div>
    </div>
  );
}

export default ViewHistory;
