import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SuperSidebar from "../../Masterdata/Sidebar/Sidebar";
import "./Franchisedetails.css";
import { VINOOTNEW } from "../../Helper/Helper";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditFranchiseModal from "./EditFranchiseModal";
import FranchiseDetailsInfo from "./FranchiseDetailsInfo";

const FranchiseDetails = () => {
  const [franchises, setFranchises] = useState([]);
  const [selectedFranchise, setSelectedFranchise] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // Control editing state
  const [searchQuery, setSearchQuery] = useState("");
  const [infoFranchise, setInfoFranchise] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(""); // Track selected branch
  const [franchiseCount, setFranchiseCount] = useState(0); // Track count of filtered franchises
  const [isInfo, setIsInfo] = useState(false); // Control info state
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchFranchises();
  }, []);

  const fetchFranchises = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${VINOOTNEW}/api/franchise`);
      setFranchises(response.data.franchises);
      // console.log("Franchises fetched successfully:", response.data.franchises);
    } catch (error) {
      console.error("Error fetching franchises:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleStatus = async (franchiseId, franchisename, isActive) => {
    try {
      await axios.put(`${VINOOTNEW}/api/franchise/${franchiseId}/toggle`);
      setFranchises(
        franchises.map((franchise) =>
          franchise._id === franchiseId
            ? { ...franchise, isActive: !franchise.isActive }
            : franchise
        )
      );
      toast[isActive ? "error" : "success"](
        `${franchisename} ${
          isActive ? "Deactivated" : "Activated"
        } successfully.`,
        { position: "top-right", autoClose: 800, theme: "light" }
      );
      // console.log(`${franchisename} status toggled successfully.`);
    } catch (error) {
      console.error("Error toggling status:", error);
    }
  };

  const usersFranchiseAdmin = (franchiseID) => {
    navigate(`/franchise/${franchiseID}/users`);
  };

  const handleRowClick = (event, franchises) => {
    if (event.target.tagName === "BUTTON") {
      return; // Do nothing if a button was clicked
    }

    const excludedCells = ["no-click-cell", "another-no-click-cell"];
    const clickedElement = event.target;

    if (
      excludedCells.some((className) =>
        clickedElement.classList.contains(className)
      )
    ) {
      return;
    }

    handleInfo(franchises);
  };

  const handleInfo = (franchises) => {
    setSelectedFranchise(null);
    setInfoFranchise(franchises);
    setIsEditing(false);
    setIsInfo(true);
  };

  const handleCancelInfo = () => {
    setIsInfo(false);
    setInfoFranchise(null);
  };

  const handleEdit = (franchise) => {
    // console.log("Editing franchise:", franchise);
    setInfoFranchise(null);
    setSelectedFranchise(franchise);
    setIsInfo(false);
    setIsEditing(true);
  };

  const handleUpdate = async (updatedFranchise) => {
    try {
      // console.log("Updating franchise:", updatedFranchise);
      const response = await axios.patch(
        `${VINOOTNEW}/api/franchisestateupdatereg/${updatedFranchise._id}`,
        {
          franchisename: updatedFranchise.franchisename,
          franchiseID: updatedFranchise.franchiseID,
          mobileNumber: updatedFranchise.mobileNumber,
          state: updatedFranchise.state,
          city: updatedFranchise.city,
          area: updatedFranchise.area,
          address: updatedFranchise.address,
          pincode: updatedFranchise.pincode,
        }
      );
      // console.log("Update response:", response.data); // Log the response
      setFranchises((prevFranchises) =>
        prevFranchises.map((franchise) =>
          franchise._id === updatedFranchise._id ? response.data : franchise
        )
      );
      setIsEditing(false); // Close editing section
      toast.success("Franchise details updated successfully.", {
        position: "top-right",
        autoClose: 900,
        theme: "light",
      });
      fetchFranchises();
    } catch (error) {
      console.error("Error updating franchise details:", error);
      toast.error("Failed to update franchise details.", {
        position: "top-right",
        autoClose: 900,
        theme: "light",
      });
    }
  };

  const handleCancel = () => {
    // console.log("Edit canceled.");
    setIsEditing(false); // Close editing section
    setSelectedFranchise(null);
  };

  const filteredFranchises = franchises.filter((franchise) => {
    const matchesBranch =
      selectedBranch === "" || franchise.franchisename === selectedBranch;
    const matchesSearchQuery =
      (franchise.franchisename &&
        franchise.franchisename
          .toLowerCase()
          .includes(searchQuery.toLowerCase())) ||
      (franchise.franchiseID &&
        franchise.franchiseID
          .toLowerCase()
          .includes(searchQuery.toLowerCase())) ||
      (franchise.mobileNumber &&
        franchise.mobileNumber
          .toLowerCase()
          .includes(searchQuery.toLowerCase())) ||
      (franchise.state &&
        franchise.state.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (franchise.city &&
        franchise.city.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (franchise.area &&
        franchise.area.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (franchise.address &&
        franchise.address.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (franchise.pincode &&
        franchise.pincode.toLowerCase().includes(searchQuery.toLowerCase()));

    return matchesBranch && matchesSearchQuery;
  });

  useEffect(() => {
    setFranchiseCount(filteredFranchises.length);
  }, [filteredFranchises]);

  return (
    <div className="fradetail-total">
      <ToastContainer />
      <div>
        <SuperSidebar />
      </div>
      <div className="frtable_pagination_parent">
        <h1>Branches</h1>
        <input
          type="text"
          placeholder="Search...."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="frdetail-searchbar"
        />
        <span className="filterr">Select Branch:</span>
        <select
          className="frdetail-filter"
          onChange={(e) => setSelectedBranch(e.target.value)}
          value={selectedBranch}
        >
          <option value="">All Branches</option>
          {franchises.map((franchise) => (
            <option key={franchise._id} value={franchise.franchisename}>
              {franchise.franchisename}
            </option>
          ))}
        </select>
        <label className="branch-one">
          <span>
            Total {franchiseCount === 1 ? "Branch :" : "Branches :"}{" "}
            {franchiseCount}
          </span>
        </label>
        <div className="fradetail-table">
        {isLoading ? (
              <p>Loading areas...</p>
            ) : (
          <table>
            <thead>
              <tr>
                <th>Branch Name</th>
                <th>Branch ID</th>
                <th>Mobile No</th>
                {/* <th>State</th>
                <th>City</th>
                <th>Area</th> */}
                <th>Address</th>
                <th>Status</th>
                <th>Action</th>
                <th>Branch Admins</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {filteredFranchises.map((franchise) => (
                <tr
                  onClick={(event) => handleRowClick(event, franchise)}
                  key={franchise._id}
                >
                  <td>{franchise.franchisename}</td>
                  <td>{franchise.franchiseID}</td>
                  <td>{franchise.mobileNumber}</td>
                  <td>{franchise.address}</td>
                  
                  {/* <td>{franchise.state}</td>
                  <td>{franchise.city}</td>
                  <td>{franchise.area}</td> */}
                  <td
                    className={franchise.isActive ? "Activate1" : "Deactivate1"}
                  >
                    {franchise.isActive ? "Active" : "Inactive"}
                  </td>
                  <td className="no-click-cell">
                    <button
                      className={franchise.isActive ? "Deactivate" : "Activate"}
                      onClick={() =>
                        toggleStatus(
                          franchise._id,
                          franchise.franchisename,
                          franchise.isActive
                        )
                      }
                    >
                      {franchise.isActive ? "Inactive" : "Active"}
                    </button>
                  </td>
                  <td className="no-click-cell">
                    <button
                      onClick={() =>
                        usersFranchiseAdmin(
                          franchise.franchiseID,
                          franchise.franchisename,
                          franchise.isActive
                        )
                      }
                    >
                      Branch Admins
                    </button>
                  </td>
                  <td className="another-no-click-cell">
                    <button
                      className="edit_button"
                      onClick={() => handleEdit(franchise)}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
            )}
        </div>

        {isInfo && infoFranchise && (
          <FranchiseDetailsInfo
            franchise={infoFranchise}
            isOpen={isInfo}
            onClose={handleCancelInfo}
          />
        )}

        {isEditing && selectedFranchise && (
          <EditFranchiseModal
            franchise={selectedFranchise}
            isOpen={isEditing}
            onClose={handleCancel}
            onUpdate={handleUpdate}
          />
        )}
      </div>
    </div>
  );
};

export default FranchiseDetails;
